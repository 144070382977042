import { ThunkAction } from 'redux-thunk';

import { GOOGLE_ADS_ACCOUNT_LIST } from 'Modules/Marketing/Constants/WebservicePaths';
import WebService  from 'Services/WebService/WebService';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import { RootReducerState } from 'Redux/Reducers/types';
import { IError } from 'Redux/Reducers/types';
import {
  GOOGLE_ACCOUNT_LIST_LOADING,
  GOOGLE_ACCOUNT_LIST_SUCCESS,
  GOOGLE_ACCOUNT_LIST_FAIL,
  GoogleAccountListAction,
} from 'Modules/Marketing/Redux/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, GoogleAccountListAction>;
type GoogleAccountListResponseType = {
  id: string,
  displayName: string,
};

export const getGoogleAccountList = (success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: GOOGLE_ACCOUNT_LIST_LOADING,
  });
  
  try {
    const option = new WebServiceOption('GET', GOOGLE_ADS_ACCOUNT_LIST);
    const response = await WebService.shared().callWebService(option) as GoogleAccountListResponseType[];
    dispatch({
      type: GOOGLE_ACCOUNT_LIST_SUCCESS,
      payload: response,
    });
    success();
  } catch (error) {
    dispatch({
      type: GOOGLE_ACCOUNT_LIST_FAIL,
    });
    fail(error as IError);
  };
};
