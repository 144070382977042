import { combineReducers } from "redux";
import selectedContactDataSoruceReducer from "./SelectedContactDataSoruceReducer";
import { integratedPlatformsReducer } from "./IntegratedPlatformsReducer";


const rootReducer = combineReducers({
  selectedContactDataSoruce: selectedContactDataSoruceReducer,
  integratedPlatforms: integratedPlatformsReducer,
});

export default rootReducer;