import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  AvailableComponentsAction,
  AVAILABLE_COMPONENTS_LOADING,
  AVAILABLE_COMPONENTS_SUCCESS,
  AVAILABLE_COMPONENTS_FAIL,
} from 'Redux/types';
import { AvailableComponents } from './types';

const initialState = {
  status: DEFAULT,
  data: [],
};

const availableComponentsTypeReducer = (state = initialState, action: AvailableComponentsAction) => {
  const { type, payload } = action;
  switch (type) {
    case AVAILABLE_COMPONENTS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case AVAILABLE_COMPONENTS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as AvailableComponents[],
      };
    case AVAILABLE_COMPONENTS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default availableComponentsTypeReducer;
