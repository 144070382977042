export const CONTACT_LIST = '/contact';
export const CONTACT_LIST_COUNT = '/contact/count';
export const SEGMENTS_LIST_COUNT = '/segment/count';
export const SEGMENT_LIST_COUNT = '/segment/:id/count';
export const CONTACT_HISTORY = '/contact/:id/history';
export const COUNTRY_LIST = '/country';
export const DATA = '/data';
export const STATUS = '/status';
export const STATUS_BULK = '/status_bulk';
export const BIZMAIL_SYNC = '/bizmail_sync';
export const BULK_BIZMAIL_SYNC = '/bizmail_bulk_sync';
export const BULK_UPLOAD = '/bulk_upload';
export const BIZMAIL_LIST = '/bizmail-account/:id/group';
export const BIZMAIL_ACCOUNTS = '/bizmail-account';
export const DOWNLOAD_XLS = '/export';

export const MAILCHIMP_SYNC = '/segment/bulk';
export const MAILCHIMP_SYNC_PATCH = '/segment/bulk_partial_update';
