import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  MailchimpAccountsAction,
  MAILCHIMP_SYNC_LOADING,
  MAILCHIMP_SYNC_SUCCESS,
  MAILCHIMP_SYNC_FAIL,
  MAILCHIMP_SYNC_PATCH_LOADING,
  MAILCHIMP_SYNC_PATCH_SUCCESS,
  MAILCHIMP_SYNC_PATCH_FAIL,
} from 'Modules/EmailManagement/Redux/types';
import { IMailchimpAccountsState, MailchimpAccountElement } from './types';

const initialState: IMailchimpAccountsState = {
  status: DEFAULT,
  data: [],
};

const mailchimpAccountsReducer = (state = initialState, action: MailchimpAccountsAction): IMailchimpAccountsState => {
  const { type, payload } = action;
  switch (type) {
    case MAILCHIMP_SYNC_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case MAILCHIMP_SYNC_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as MailchimpAccountElement[],
      };
    case MAILCHIMP_SYNC_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case MAILCHIMP_SYNC_PATCH_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case MAILCHIMP_SYNC_PATCH_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as MailchimpAccountElement[],
      };
    case MAILCHIMP_SYNC_PATCH_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default mailchimpAccountsReducer;
