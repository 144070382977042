import { ICampaignState, ICampaignFilter, ICampaign } from 'Modules/Reports/Redux/Reducers/CampaignsReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  CampaignListActionType,
  CAMPAIGN_LIST_LOADING,
  CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_LIST_FAIL,
  SET_CAMPAIGN_LIST_FILTER,
  SET_CAMPAIGN_LIST_COMPLEX_FILTER,
  RESET_CAMPAIGN_LIST_COMPLEX_FILTER,
  SET_CAMPAIGN_LIST_SORTING_CRITERIA,
  SET_CAMPAIGN_LIST_PAGINATION_DATA,
  SET_CAMPAIGN,
  SET_CAMPAIGN_ITEM_COUNT,
} from 'Modules/Reports/Redux/types';
import SortingService from 'Services/SortingService/SortingService';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { CampaignPaginationInfo } from 'CustomHooks/usePagination/types';
import CampaignService from 'Modules/Reports/Services/CampaignService';

const initialState: ICampaignState = {
  status: DEFAULT,
  data: [],
  sortingCriteria: {
    key: 'isActive',
    order: 'DESCENDING',
    dataType: 'STRING',
  },
  campaingCount: 0,
  filter: {
    predefinedFilterId: {
      value: '',
      appliedOn: ['predefinedFilterId'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
  },
  complexFilter: {
    name: '',
    filterSet: [],
  },
  paginationData: {
    previous: null,
    maxItems: '25',
    page: '1',
    next: null,
  },
};

const getPaginationInfo = (oldPaginationInfo: CampaignPaginationInfo, newPaginationInfo: CampaignPaginationInfo): CampaignPaginationInfo => {
  if ( newPaginationInfo.previous === null && oldPaginationInfo.page === '2' ) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      previous: '1',
    }
  }
  return {
    ...oldPaginationInfo,
    ...newPaginationInfo,
  }
};

const campaignReducer = (state = initialState, action: CampaignListActionType): ICampaignState => {
  const { type, payload } = action;
  switch (type) {
    case SET_CAMPAIGN_LIST_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload as ICampaignFilter,
        },
        selectedAll: false,
      };
    case SET_CAMPAIGN_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: payload as ComplexFilterSet,
      };
    case RESET_CAMPAIGN_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: {
          name: '',
          filterSet: [],
        },
      }
    case SET_CAMPAIGN_LIST_SORTING_CRITERIA:
      return {
        ...state,
        sortingCriteria: payload as SortingCriteria,
      };
      case SET_CAMPAIGN_LIST_PAGINATION_DATA:
        return {
          ...state,
          paginationData: {
            ...state.paginationData,
            ...payload as CampaignPaginationInfo,
          },
        };
    case CAMPAIGN_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CAMPAIGN_LIST_SUCCESS:
      const sortedData = SortingService.getSortedArray((payload as Partial<ICampaignState>).data!, state.sortingCriteria);
      return {
        ...state,
        data: sortedData,
        paginationData: getPaginationInfo(state.paginationData, (payload as Partial<ICampaignState>).paginationData!),
        status: SUCCESS,
      };
    case CAMPAIGN_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case SET_CAMPAIGN_ITEM_COUNT:
      return {
        ...state,
        campaingCount: payload as number,
      };
    case SET_CAMPAIGN:
      return {
        ...state,
        data: CampaignService.getMergedCampaigns(state.data, [payload as ICampaign]),
      }
    default:
      return state;
  }
};

export default campaignReducer;
