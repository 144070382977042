export const FUNNEL_REPORT = '/report/funnel';

export const FUNNEL_REPORT_PREDEFINED_COMPLEX_FILTER = '/report/funnel/predefined_filter';
export const FUNNEL_REPORT_PREDEFINED_COMPLEX_FILTERS = '/report/funnel/predefined_filters';
export const FUNNEL_REPORT_COMPLEX_FILTER_OPTIONS = '/report/advanced_filter';
export const FUNNEL_TABLE_VIEW = '/report/funnel/user_settings';
export const FUNNEL_ADD_TABLE_VIEW = '/report/funnel/create_user_settings'
export const FUNNEL_UPDATE_TABLE_VIEW = '/report/funnel/update_user_settings'

export const FUNNEL_REPORT_CHANNEL = '/report/channel';
export const FUNNEL_REPORT_CHANNEL_TYPE = '/report/channel_type';
export const FUNNEL_REPORT_SITE = '/report/site';

export const CAMPAIGN_LIST = '/report/campaign';
export const CAMPAIGN_LIST_BULK_KPI_UPDATE = '/report/campaign/::campaign-id::/channel_report/bulk';
export const CAMPAIGN_LIST_COUNT = '/report/campaign/count';
export const CAMPAIGN_GROUP_LIST = '/report/campaign/campaigns_to_group';
export const CAMPAIGN_GROUP = '/report/campaign/campaign_group';

export const CAMPAIGNS_PREDEFINED_COMPLEX_FILTER = '/report/campaign/predefined_filter';
export const CAMPAIGNS_PREDEFINED_COMPLEX_FILTERS = '/report/campaign/predefined_filters';
export const CAMPAIGNS_COMPLEX_FILTER_OPTIONS = '/report/campaign/advanced_filter';

export const AUDIENCE_REPORT = '/report/audience';
export const AUDIENCE_REPORT_PREDEFINED_COMPLEX_FILTER = '/report/audience/predefined_filter'; 
export const AUDIENCE_REPORT_PREDEFINED_COMPLEX_FILTERS = '/report/audience/predefined_filters';
export const AUDIENCE_REPORT_COMPLEX_FILTER_OPTIONS = '/report/audience/advanced_filter';

export const SOURCE_PATH_LIST = '/report/contact/source_path_journey';
export const SOURCE_PATH_COUNT = '/contact/count';
export const SOURCE_PATH_REPORT_PREDEFINED_COMPLEX_FILTER = '/contact/predefined_filter';
export const SOURCE_PATH_REPORT_PREDEFINED_COMPLEX_FILTERS = '/contact/predefined_filters';
export const SOURCE_PATH_REPORT_COMPLEX_FILTER_OPTIONS = '/contact/advanced_filter';
export const SOURCE_PATH_SUMMARY_LIST = '/report/contact/source_path_summary';
