import {
    useEffect,
    useContext,
    useState,
    useRef,
    useCallback,
  } from 'react';
  import { useDispatch } from 'react-redux';
  
  import { IError, IGenericStateWithCampaingPagination } from 'Redux/Reducers/types';
  import { IFilterBase, IFilterable } from 'CustomHooks/useFilter/types';
  import { DEFAULT } from 'Redux/types';
  import { ToastMessageContext } from 'CustomHooks/useToastMessage/useToastMessage';
  import { FILTER_TIMEOUT } from 'Constants/Options';
  import UtilityService from 'Services/UtilityService/UtilityService';
  import { SortingCriteria, ASCENDING } from 'CustomHooks/useSort/types';
  
  const useGetDataWithFilterAndSortingCriteriaCampaigns = <T extends IGenericStateWithCampaingPagination<any> & IFilterable<IFilterBase>>(state: T, getAction?: (params: string, success: () => void, fail: (error: IError) => void) => void, sortingCriteria?: SortingCriteria): [() => void] => {
  
    // This required to avoid sending a request to the backend to each user action
    const timeoutRef = useRef<NodeJS.Timeout>();
    const [params, setParams] = useState<string>("");
    const [appliedParams, setAppliedParams] = useState<string>("");
  
    const forceRefresh = useCallback(() => {
      setAppliedParams("");
    }, []);
  
    const applyChange = useCallback(() => {
      const params = new URLSearchParams();
  
      if (state.paginationData.page) {
        params.append('page', `${state.paginationData.page}`);
      }
  
      if (state.paginationData.maxItems !== null) {
        params.append('maxItems', `${state.paginationData.maxItems}`);
      }
  
      // Assigning filter params
      for (const key in state.filter) {
        if (Object.prototype.hasOwnProperty.call(state.filter, key)) {
          const { appliedOn, value, id, isUsingIdToFetch } = state.filter[key];
          if (value !== '' && value !== null) {
            if (Array.isArray(value) && value.length > 0) {
              const searchParamValue = value.map(element => element.id && isUsingIdToFetch ? element.id : element.value);
              appliedOn.forEach(key => params.append(UtilityService.getFirstPartOfDottedText(key), `${searchParamValue.join(',')}`));
            } else if (!Array.isArray(value)) {
              appliedOn.forEach(key => params.append(UtilityService.getFirstPartOfDottedText(key), `${id && isUsingIdToFetch ? id : value}`));
            }
          }
        }
      }
  
      if (sortingCriteria) {
        params.append('sortBy', sortingCriteria.order === ASCENDING ? 'asc' : 'desc');
        params.append('orderBy', sortingCriteria.key);
      }
      
      setParams(params.toString());
    }, [sortingCriteria, state.filter, state.paginationData.page, state.paginationData.maxItems]);

  
    useEffect(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        applyChange();
      }, FILTER_TIMEOUT);
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, sortingCriteria]);
  
    const dispatch = useDispatch();
    const { presentErrorToastByCode } = useContext(ToastMessageContext);
    useEffect(() => {
      if (params === "") {
        return;
      }
      if ((state.status === DEFAULT || params !== appliedParams) && getAction) {
        dispatch(getAction(params, () => {
  
        }, (error) => {
          presentErrorToastByCode(error.code);
        }));
        setAppliedParams(params);
      }
    }, [appliedParams, dispatch, getAction, params, presentErrorToastByCode, state.status]);
  
    return [forceRefresh];
  };
  
  export default useGetDataWithFilterAndSortingCriteriaCampaigns;
  