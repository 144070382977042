import { IPlatformData } from "../types";

export const SET_META_ADS = 'SET_META_ADS';
interface ISetMetaAction {
  type: typeof SET_META_ADS,
  payload: boolean,
}
export const SET_GOOGLE_ADS = 'SET_GOOGLE_ADS';
interface ISetGoogleAds {
  type: typeof SET_GOOGLE_ADS,
  payload: boolean,
}
export const SET_GOOGLE_ANALYTICS = 'SET_GOOGLE_ANALYTICS';
interface ISetGoogleAnalytics {
  type: typeof SET_GOOGLE_ANALYTICS,
  payload: boolean,
}

export type SelectedContactDataSoruceAction = ISetMetaAction | ISetGoogleAds | ISetGoogleAnalytics;

export const INTEGRATED_PLATFORMS_LOADING = 'INTEGRATED_PLATFORMS_LOADING';
interface IIntegratedPlatformsLoading {
  type: typeof INTEGRATED_PLATFORMS_LOADING,
  payload?: never,
}

export const INTEGRATED_PLATFORMS_SUCCESS = 'INTEGRATED_PLATFORMS_SUCCESS';
interface ITagListSuccess {
  type: typeof INTEGRATED_PLATFORMS_SUCCESS,
  payload: IPlatformData[],
}

export const INTEGRATED_PLATFORMS_FAIL = 'INTEGRATED_PLATFORMS_FAIL';
interface ITagListFail {
  type: typeof INTEGRATED_PLATFORMS_FAIL,
  payload?: never,
}

export const PATCH_INTEGRATED_PLATFORMS = 'PATCH_INTEGRATED_PLATFORMS';

export type IntegratedPlatformsAction = IIntegratedPlatformsLoading | ITagListSuccess | ITagListFail;