import {
  IContactFilter,
  IContactState,
  Contact,
  IHistoryState,
  Data,
  DataElement,
} from 'Modules/EmailManagement/Redux/Reducers/ContactReducer/types';
import { Country } from 'Modules/EmailManagement/Redux/Reducers/CountryReducer/types';
import { BizmailListElement } from 'Modules/EmailManagement/Redux/Reducers/BizmailListReducer/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { SelectionActionObject } from 'CustomHooks/useSelection/types';
import { DeepPartial } from 'Services/UtilityService/UtilityTypes';

export type ContactHistoryById = {
  contactId: string,
  history: DeepPartial<IHistoryState>,
};

export type EditDataSuccess = {
  contactId: string,
  data: Data,
}

// Contact action types
export const CONTACT_LIST_LOADING = 'CONTACT_LIST_LOADING';
interface IContactListLoading {
  type: typeof CONTACT_LIST_LOADING,
  payload?: never,
}

export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
interface IContactListSuccess {
  type: typeof CONTACT_LIST_SUCCESS,
  payload: DeepPartial<IContactState>,
}

export const CONTACT_LIST_FAIL = 'CONTACT_LIST_FAIL';
interface IContactListFail {
  type: typeof CONTACT_LIST_FAIL,
  payload?: never,
}

export const CREATE_CONTACT_LOADING = 'CREATE_CONTACT_LOADING';
interface ICreateContactLoading {
  type: typeof CREATE_CONTACT_LOADING,
  payload?: never,
}

export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
interface ICreateContactSuccess {
  type: typeof CREATE_CONTACT_SUCCESS,
  payload: DeepPartial<Contact>,
}

export const CREATE_CONTACT_FAIL = 'CREATE_CONTACT_FAIL';
interface ICreateContactFail {
  type: typeof CREATE_CONTACT_FAIL,
  payload?: never,
}

export const UPLOAD_CONTACTS_LOADING = 'UPLOAD_CONTACTS_LOADING';
interface IUploadContactsLoading {
  type: typeof UPLOAD_CONTACTS_LOADING,
  payload?: never,
}

export const UPLOAD_CONTACTS_SUCCESS = 'UPLOAD_CONTACTS_SUCCESS';
interface IUploadContactsSuccess {
  type: typeof UPLOAD_CONTACTS_SUCCESS,
  payload?: never,
}

export const UPLOAD_CONTACTS_FAIL = 'UPLOAD_CONTACTS_FAIL';
interface IUploadContactsFail {
  type: typeof UPLOAD_CONTACTS_FAIL,
  payload?: never,
}

export const EDIT_TAGS_ON_CONTACTS_LOADING = 'EDIT_TAGS_ON_CONTACTS_LOADING';
interface IEditTagsOnContactsLoading {
  type: typeof EDIT_TAGS_ON_CONTACTS_LOADING,
  payload?: never,
}

export const EDIT_TAGS_ON_CONTACTS_SUCCESS = 'EDIT_TAGS_ON_CONTACTS_SUCCESS';
interface IEditTagsOnContactsSuccess {
  type: typeof EDIT_TAGS_ON_CONTACTS_SUCCESS,
  payload: Contact[],
}

export const EDIT_TAGS_ON_CONTACTS_FAIL = 'EDIT_TAGS_ON_CONTACTS_FAIL';
interface IEditTagsOnContactsFail {
  type: typeof EDIT_TAGS_ON_CONTACTS_FAIL,
  payload?: never,
}

export const EDIT_CONTACT_LOADING = 'EDIT_CONTACT_LOADING';
interface IEditContactLoading {
  type: typeof EDIT_CONTACT_LOADING,
  payload?: never,
}

export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
interface IEditContactSuccess {
  type: typeof EDIT_CONTACT_SUCCESS,
  payload: DeepPartial<Contact>,
}

export const EDIT_CONTACT_DATA_SUCCESS = 'EDIT_CONTACT_DATA_SUCCESS';
interface IEditContactDataSuccess {
  type: typeof EDIT_CONTACT_DATA_SUCCESS,
  payload: EditDataSuccess,
}

export const EDIT_CONTACT_FAIL = 'EDIT_CONTACT_FAIL';
interface IEditContactFail {
  type: typeof EDIT_CONTACT_FAIL,
  payload?: never,
}

export const DELETE_CONTACT_LOADING = 'DELETE_CONTACT_LOADING';
interface IDeleteContactLoading {
  type: typeof DELETE_CONTACT_LOADING,
  payload?: never,
}

export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS';
interface IDeleteContactSuccess {
  type: typeof DELETE_CONTACT_SUCCESS,
  payload: string[],
}

export const DELETE_CONTACT_FAIL = 'DELETE_CONTACT_FAIL';
interface IDeleteContactFail {
  type: typeof DELETE_CONTACT_FAIL,
  payload?: never,
}

export const CONTACT_BY_ID_LOADING = 'CONTACT_BY_ID_LOADING';
interface IContactByIdLoading {
  type: typeof CONTACT_BY_ID_LOADING,
  payload?: never,
}

export const CONTACT_BY_ID_SUCCESS = 'CONTACT_BY_ID_SUCCESS';
interface IContactByIdSuccess {
  type: typeof CONTACT_BY_ID_SUCCESS,
  payload: Contact,
}

export const CONTACT_BY_ID_FAIL = 'CONTACT_BY_ID_FAIL';
interface IContactByIdFail {
  type: typeof CONTACT_BY_ID_FAIL,
  payload?: never,
}

export const CONTACT_HISTORY_BY_ID_LOADING = 'CONTACT_HISTORY_BY_ID_LOADING';
interface IContactHistoryByIdLoading {
  type: typeof CONTACT_HISTORY_BY_ID_LOADING,
  payload?: ContactHistoryById,
}

export const CONTACT_HISTORY_BY_ID_SUCCESS = 'CONTACT_HISTORY_BY_ID_SUCCESS';
interface IContactHistoryByIdSuccess {
  type: typeof CONTACT_HISTORY_BY_ID_SUCCESS,
  payload: ContactHistoryById,
}

export const CONTACT_HISTORY_BY_ID_FAIL = 'CONTACT_HISTORY_BY_ID_FAIL';
interface IContactHistoryByIdFail {
  type: typeof CONTACT_HISTORY_BY_ID_FAIL,
  payload?: ContactHistoryById,
}

export const SYNC_WITH_SOURCE_LOADING = 'SYNC_WITH_SOURCE_LOADING';
interface ISyncWithSourceLoading {
  type: typeof SYNC_WITH_SOURCE_LOADING,
  payload?: never,
}

export const SYNC_WITH_SOURCE_SUCCESS = 'SYNC_WITH_SOURCE_SUCCESS';
interface ISyncWithSourceSuccess {
  type: typeof SYNC_WITH_SOURCE_SUCCESS,
  payload?: never,
}

export const SYNC_WITH_SOURCE_FAIL = 'SYNC_WITH_SOURCE_FAIL';
interface ISyncWithSourceFail {
  type: typeof SYNC_WITH_SOURCE_FAIL,
  payload?: never,
}

export const SET_CONTACT_HISTORY_BY_ID = 'SET_CONTACT_HISTORY_BY_ID';
interface ISetContactHistoryById {
  type: typeof SET_CONTACT_HISTORY_BY_ID,
  payload?: ContactHistoryById,
}

export const SET_CONTACT_FILTER = 'SET_CONTACT_FILTER';
interface ISetContactFilter {
  type: typeof SET_CONTACT_FILTER,
  payload: Partial<IContactFilter>,
};

export const SET_CONTACT_COMPLEX_FILTER = 'SET_CONTACT_COMPLEX_FILTER';
interface ISetContactComplexFilter {
  type: typeof SET_CONTACT_COMPLEX_FILTER,
  payload: ComplexFilterSet,
};

export const RESET_CONTACT_COMPLEX_FILTER = 'RESET_CONTACT_COMPLEX_FILTER';
interface IResetContactComplexFilter {
  type: typeof RESET_CONTACT_COMPLEX_FILTER,
  payload?: never,
};

export const SET_CONTACT_SORTING_CRITERIA = 'SET_CONTACT_SORTING_CRITERIA';
interface ISetContactSortingCriteria {
  type: typeof SET_CONTACT_SORTING_CRITERIA,
  payload: SortingCriteria,
};

export const SET_SELECTED_CONTACTS = 'SET_SELECTED_CONTACTS';
interface ISetSelectedContacts {
  type: typeof SET_SELECTED_CONTACTS,
  payload: SelectionActionObject,
};

export const SET_TAGGED_CONTACTS = 'SET_TAGGED_CONTACTS';
interface ISetTaggedContacts {
  type: typeof SET_TAGGED_CONTACTS,
  payload: string[],
};

export const SET_CONTACT_PAGINATION_DATA = 'SET_CONTACT_PAGINATION_DATA';
interface ISetContactPaginationData {
  type: typeof SET_CONTACT_PAGINATION_DATA,
  payload: Partial<PaginationInfo>,
};

export const SET_CONTACT_ITEM_COUNT = 'SET_CONTACT_ITEM_COUNT';
interface ISetContactItemCount {
  type: typeof SET_CONTACT_ITEM_COUNT,
  payload: number | null,
};

export type ContactListAction = 
  ISetContactFilter |
  ISetContactComplexFilter |
  IResetContactComplexFilter |
  ISetContactSortingCriteria |
  IContactListLoading |
  IContactListSuccess |
  IContactListFail |
  ISetSelectedContacts |
  ISetContactPaginationData |
  ISetTaggedContacts |
  IContactByIdLoading |
  IContactByIdSuccess |
  IContactByIdFail |
  IContactHistoryByIdLoading |
  IContactHistoryByIdSuccess |
  IContactHistoryByIdFail |
  ISetContactHistoryById |
  ICreateContactLoading |
  ICreateContactSuccess |
  ICreateContactFail |
  IUploadContactsLoading |
  IUploadContactsSuccess |
  IUploadContactsFail |
  IEditContactLoading |
  IEditContactSuccess |
  IEditContactDataSuccess |
  IEditContactFail |
  IDeleteContactLoading |
  IDeleteContactSuccess |
  IDeleteContactFail |
  IEditTagsOnContactsLoading |
  IEditTagsOnContactsSuccess |
  IEditTagsOnContactsFail |
  ISyncWithSourceLoading |
  ISyncWithSourceSuccess |
  ISyncWithSourceFail |
  ISetContactItemCount;

// Contact detail action types
export const SET_CONTACT_DETAIL = 'SET_CONTACT_DETAIL';
interface ISetContactDetail {
  type: typeof SET_CONTACT_DETAIL,
  payload: Partial<Contact>,
};

export const RESET_CONTACT_DETAIL = 'RESET_CONTACT_DETAIL';
interface IResetContactDetail {
  type: typeof RESET_CONTACT_DETAIL,
  payload?: never,
};

export const SET_EDITED_MANUAL_DATA = 'SET_EDITED_MANUAL_DATA';
interface ISetEditedManualData {
  type: typeof SET_EDITED_MANUAL_DATA,
  payload: DataElement,
};

export const REMOVE_TAG_FROM_CONTACT_DETAIL = 'REMOVE_TAG_FROM_CONTACT_DETAIL';
interface IRemoveTagFromContactDetail {
  type: typeof REMOVE_TAG_FROM_CONTACT_DETAIL,
  payload: string,
};

export type ContactDetailAction = ISetContactDetail | IResetContactDetail | IRemoveTagFromContactDetail | ISetEditedManualData;

// Country action types
export const COUNTRY_LIST_LOADING = 'COUNTRY_LIST_LOADING';
interface ICountryListLoading {
  type: typeof COUNTRY_LIST_LOADING,
  payload?: never,
}

export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
interface ICountryListSuccess {
  type: typeof COUNTRY_LIST_SUCCESS,
  payload: Country[],
}

export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL';
interface ICountryListFail {
  type: typeof COUNTRY_LIST_FAIL,
  payload?: never,
}

export type CountryListAction = ICountryListLoading | ICountryListSuccess | ICountryListFail;

// Bizmail list action types
export const BIZMAIL_LIST_LOADING = 'BIZMAIL_LIST_LOADING';
interface IBizmailListLoading {
  type: typeof BIZMAIL_LIST_LOADING,
  payload?: never,
}

export const BIZMAIL_LIST_SUCCESS = 'BIZMAIL_LIST_SUCCESS';
interface IBizmailListSuccess {
  type: typeof BIZMAIL_LIST_SUCCESS,
  payload: BizmailListElement[],
}

export const BIZMAIL_LIST_FAIL = 'BIZMAIL_LIST_FAIL';
interface IBizmailListFail {
  type: typeof BIZMAIL_LIST_FAIL,
  payload?: never,
}

export type BizmailListAction = IBizmailListLoading | IBizmailListSuccess | IBizmailListFail;

// Bizmail accounts action types
export const BIZMAIL_ACCOUNTS_LOADING = 'BIZMAIL_ACCOUNTS_LOADING';
interface IBizmailAccountsLoading {
  type: typeof BIZMAIL_ACCOUNTS_LOADING,
  payload?: never,
}

export const BIZMAIL_ACCOUNTS_SUCCESS = 'BIZMAIL_ACCOUNTS_SUCCESS';
interface IBizmailAccountsSuccess {
  type: typeof BIZMAIL_ACCOUNTS_SUCCESS,
  payload: BizmailListElement[],
}

export const BIZMAIL_ACCOUNTS_FAIL = 'BIZMAIL_ACCOUNTS_FAIL';
interface IBizmailAccountsFail {
  type: typeof BIZMAIL_ACCOUNTS_FAIL,
  payload?: never,
}

export type BizmailAccountsAction = IBizmailAccountsLoading | IBizmailAccountsSuccess | IBizmailAccountsFail;

// Mailchimp accounts action types

export const MAILCHIMP_SYNC_LOADING = 'MAILCHIMP_SYNC_LOADING';
interface IMailchimpAccountsLoading {
  type: typeof MAILCHIMP_SYNC_LOADING,
  payload?: never,
}

export const MAILCHIMP_SYNC_SUCCESS = 'MAILCHIMP_SYNC_SUCCESS';
interface IMailchimpAccountsSuccess {
  type: typeof MAILCHIMP_SYNC_SUCCESS,
  payload: {},
}

export const MAILCHIMP_SYNC_FAIL = 'MAILCHIMP_SYNC_FAIL';
interface IMailchimpAccountsFail {
  type: typeof MAILCHIMP_SYNC_FAIL,
  payload?: never,
}

export const MAILCHIMP_SYNC_PATCH_LOADING = 'MAILCHIMP_SYNC_PATCH_LOADING';
interface IMailchimpSyncPatchLoading {
  type: typeof MAILCHIMP_SYNC_PATCH_LOADING,
  payload?: never,
}

export const MAILCHIMP_SYNC_PATCH_SUCCESS = 'MAILCHIMP_SYNC_PATCH_SUCCESS';
interface IMailchimpSyncPatchSuccess {
  type: typeof MAILCHIMP_SYNC_PATCH_SUCCESS,
  payload: {},
}

export const MAILCHIMP_SYNC_PATCH_FAIL = 'MAILCHIMP_SYNC_PATCH_FAIL';
interface IMailchimpSyncPatchFail {
  type: typeof MAILCHIMP_SYNC_PATCH_FAIL,
  payload?: never,
}

export type MailchimpAccountsAction = 
IMailchimpAccountsLoading | 
IMailchimpAccountsSuccess |
IMailchimpAccountsFail | 
IMailchimpSyncPatchLoading |
IMailchimpSyncPatchSuccess |
IMailchimpSyncPatchFail;
