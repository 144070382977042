import {
  FC,
  lazy,
  Suspense,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ProtectedRoute from 'Components/Routes/ProtectedRoute';
import ForwardedRoute from 'Components/Routes/ForwardedRoute';
import SuspenseFallFallback from 'Components/SuspenseFallback/SuspenseFallback'
import {
  digitalPersonasPath,
  loginPath,
  signUpPath,
  marketingPath,
  performancePath,
  resetPasswordPath,
  userOnboardingPath,
} from 'Constants/NavigationConstants';
import { BASE_MODULE_PATH } from 'Constants/Options';

const EmailManagement = lazy(() => import('Modules/EmailManagement'));
const Login = lazy(() => import('Modules/Login/Login'));
const SignUp = lazy(() => import('Modules/SignUp/SignUp'));
const Marketing = lazy(() => import('Modules/Marketing'));
const Reports = lazy(() => import('Modules/Reports'));
const ResetPassword = lazy(() => import('Modules/ResetPassword/ResetPassword'));
const UserOnboarding = lazy(() => import('Modules/UserOnboarding/UserOnboarding'));

const NavigationPaths: FC = () => {

  // User is redirected to the base module
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (history.location.pathname === '/') {
      history.replace(BASE_MODULE_PATH);
    }
  }, [history, location]);

  return (
    <Suspense fallback={<SuspenseFallFallback />}>
      <ForwardedRoute path={loginPath} component={Login} />
      <ForwardedRoute path={signUpPath} component={SignUp} />
      <ForwardedRoute path={resetPasswordPath} component={ResetPassword} />
      <ProtectedRoute path={userOnboardingPath} component={UserOnboarding} />
      <ProtectedRoute path={digitalPersonasPath} component={EmailManagement} />
      <ProtectedRoute path={marketingPath} component={Marketing} />
      <ProtectedRoute path={performancePath} component={Reports} />
    </Suspense>
  );
};

export default NavigationPaths;
