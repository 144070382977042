import { FC, useEffect } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { BrowserRouter, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Navigation from 'Blocks/Navigation/Navigation';
import NavigationPaths from 'Blocks/NavigationPaths/NavigationPaths';
import DialogPresenter from 'Blocks/DialogPresenter/DialogPresenter';
import ToastMessagePresenter from 'Blocks/ToastMessagePresenter/ToastMessagePresenter';
import useDialog, { DialogContext } from 'CustomHooks/useDialog/useDialog';
import useToastMessage, { ToastMessageContext } from 'CustomHooks/useToastMessage/useToastMessage';
import { loginPath, signUpPath, resetPasswordPath, userOnboardingPath } from 'Constants/NavigationConstants';
import { UserState } from 'Redux/Reducers/UserReducer/types';
import WebService from 'Services/WebService/WebService';
import { RootReducerState } from 'Redux/Reducers/types';
import theme from 'Styles/MUITheme';
import 'main.scss';

const App: FC = () => {
  const [dialogues, presentDialogue, closeDialogue, setPreventGoingAwayMessage, presentAlertDialog, presentPopUpDialog, presentSyncDialog, presentActivateDialog, presentGroupDialog] = useDialog();
  const [toastMessages, presentToastMessage, removeToastMessage, presentErrorToastByCode] = useToastMessage();

  const userState = useSelector<RootReducerState, UserState>(state => state.user);
  useEffect(() => {
    if (userState.credentials && userState.credentials.accessToken) {
      WebService.shared().setToken(userState.credentials.accessToken);
    } else {
      WebService.shared().removeToken();
    }
  }, [userState.credentials]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DialogContext.Provider value={{ presentDialogue, dialogues, closeDialogue, setPreventGoingAwayMessage, presentAlertDialog, presentPopUpDialog, presentSyncDialog, presentActivateDialog, presentGroupDialog}}>
        <ToastMessageContext.Provider value={{ messages: toastMessages, presentToastMessage, removeToastMessage, presentErrorToastByCode }}>
        <BrowserRouter>
          <Route render={({ location }) => {
            const isAuthPath = [loginPath, signUpPath, resetPasswordPath, userOnboardingPath].includes(location.pathname);
            return !isAuthPath ? <Navigation /> : null;
          }} />
          <main className="main-content-container">
            <NavigationPaths />
          </main>
          <DialogPresenter />
          <ToastMessagePresenter />
        </BrowserRouter>
        </ToastMessageContext.Provider>
        </DialogContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
