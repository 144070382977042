import { ThunkAction } from 'redux-thunk';

import { FUNNEL_REPORT_SITE } from 'Modules/Reports/Constants/WebservicePaths';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import WebService  from 'Services/WebService/WebService';
import { IError } from 'Redux/Reducers/types';
import {
  SITE_LIST_LOADING,
  SITE_LIST_SUCCESS,
  SITE_LIST_FAIL,
  SiteListAction,
} from 'Modules/Reports/Redux/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { Site } from 'Modules/Reports/Redux/Reducers/SiteReducer/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, SiteListAction>;

export const getSiteList = (success: (sites: Site[]) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: SITE_LIST_LOADING,
  });
  try {
    const option = new WebServiceOption('GET', FUNNEL_REPORT_SITE);
    const response = await WebService.shared().callWebService(option) as Site[];
    dispatch({
      type: SITE_LIST_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: SITE_LIST_FAIL,
    });
    fail(error as IError);
  };
};
