import { ITagState, Tag } from 'Redux/Reducers/TagReducer/types';
import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  CREATE_LOADING,
  CREATE_SUCCESS,
  CREATE_FAIL,
  EDIT_LOADING,
  EDIT_SUCCESS,
  EDIT_FAIL,
  DELETE_LOADING,
  DELETE_SUCCESS,
  DELETE_FAIL,
} from 'Redux/types';
import {
  TAG_LIST_LOADING,
  TAG_LIST_SUCCESS,
  TAG_LIST_FAIL,
  NEW_TAG_LOADING,
  NEW_TAG_SUCCESS,
  NEW_TAG_FAIL,
  EDIT_TAG_LOADING,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAIL,
  DELETE_TAG_LOADING,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  RESET_LAST_ADDED_TAG,
  TagListAction,
} from 'Redux/types';
import TagService from 'Services/TagService/TagService';

const initialState: ITagState = {
  status: DEFAULT,
  data: [],
  lastAddedTag: undefined,
};

const tagReducer = (state = initialState, action: TagListAction): ITagState => {
  const { type, payload } = action;
  switch (type) {
    case TAG_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case TAG_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as Tag[],
      };
    case TAG_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case NEW_TAG_LOADING:
      return {
        ...state,
        status: CREATE_LOADING,
      }
    case NEW_TAG_SUCCESS:
      return {
        ...state,
        status: CREATE_SUCCESS,
        data: (new TagService()).mergeTags(state.data, [payload as Tag]),
        lastAddedTag: payload as Tag,
      }
    case NEW_TAG_FAIL:
      return {
        ...state,
        status: CREATE_FAIL,
      }
    case EDIT_TAG_LOADING:
      return {
        ...state,
        status: EDIT_LOADING,
      }
    case EDIT_TAG_SUCCESS:
      return {
        ...state,
        status: EDIT_SUCCESS,
        data: (new TagService()).mergeTags(state.data, [payload as Tag]),
      }
    case EDIT_TAG_FAIL:
      return {
        ...state,
        status: EDIT_FAIL,
      }
    case DELETE_TAG_LOADING:
      return {
        ...state,
        status: DELETE_LOADING,
      }
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        status: DELETE_SUCCESS,
        data: (new TagService()).removeTag(payload as string, state.data),
      }
    case DELETE_TAG_FAIL:
      return {
        ...state,
        status: DELETE_FAIL,
      }
    case RESET_LAST_ADDED_TAG:
      return {
        ...state,
        lastAddedTag: undefined,
      }
    default:
      return state;
  }
};

export default tagReducer;
