import { FilterValueType } from "CustomHooks/useComplexFilter/types";
export const HALF_PAGE_DIALOGUE = 'HALF_PAGE_DIALOGUE';
export const ALERT_DIALOGUE = 'ALERT_DIALOGUE';
export const POP_UP_DIALOGUE = 'POP_UP_DIALOGUE';
export const SYNC_DIALOGUE = 'SYNC_DIALOGUE';
export const ACTIVATE_DIALOGUE = 'ACTIVATE_DIALOGUE';
export const GROUP_DIALOGUE = 'GROUP_DIALOGUE';
export type DialogueType = typeof HALF_PAGE_DIALOGUE | typeof ALERT_DIALOGUE | typeof POP_UP_DIALOGUE | typeof SYNC_DIALOGUE | typeof ACTIVATE_DIALOGUE | typeof GROUP_DIALOGUE;

export type ButtonClose = {
  name: string,
  callToAction?: () => void,
}

export type ButtonSend = {
  name: string,
  callToAction?: (data: string ) => void,
  callToActionWithData?: (data: GoogleListItem[]) => void,
}

export enum MailchimAudienceStatus {
  Default,
  ExistingAudience,
  NotExistingAudience,
}

export type DialogueAction = {
  name: string,
  action: () => void,
};

export type DialogueActionWithValue = {
  name: string,
  action: (data: string) => void,
};

export type AlertDialogProps = {
  title?: string,
  description?: string,
  actions?: DialogueAction[],
};

export type PopUpDialogProps = {
  image?: string,
  title?: string,
  description?: string,
  secondDescription?: string,
  element?: JSX.Element,
  action?: ButtonClose,
  closeDisabled?: boolean,
  inputName?: string,
  sendAction?: ButtonSend,
  showCloseButon? : boolean,
};

export type ListItem = {
  id: string;
  name: string;
};

export type GoogleListItem = {
  id: string;
  name: string;
  subAccounts: ListItem[],
};

export type ActivateDialogProps = {
  image?: string,
  title?: string,
  description?: string,
  list?: ListItem[] | GoogleListItem[],
  element?: JSX.Element,
  action?: ButtonClose,
  closeDisabled?: boolean,
  inputName?: string,
  sendAction?: ButtonSend,
  sendActionWithData?: ButtonSend,
}

export type GroupDialogProps = {
  title?: string,
}

export type SyncDialogProps = {
  image?: string,
  title?: string,
  audienceSize?: number,
  predefinedFilterId: FilterValueType,
}

export type DialogueProps = {
  index?: number,
  type: DialogueType,
  modalBodyId?: string,
  width?: string,
  preventFromGoingAwayMessage?: string, // Used to warn the user not to close the modal
  alertDialogProps?: AlertDialogProps,
  popUpDialogProps?: PopUpDialogProps,
  syncDialogProps?: SyncDialogProps,
  activateDialogProps?: ActivateDialogProps,
  groupDialogProps?: GroupDialogProps,
};

export type DialogueContext = {
  presentDialogue: (dialogue: DialogueProps) => DialogueProps,
  closeDialogue: () => void,
  dialogues: DialogueProps[],
  setPreventGoingAwayMessage: (modalId: string, message: string | undefined) => void,
  presentAlertDialog: (alertDialog: AlertDialogProps) => void,
  presentPopUpDialog: (popUpDialog: PopUpDialogProps) => void,
  presentSyncDialog: (syncDialogProps: SyncDialogProps) => void,
  presentActivateDialog: (activateDialog: ActivateDialogProps) => void,
  presentGroupDialog: (groupDialog: GroupDialogProps) => void,
};
