import { SEGMENT_LIST_COUNT, SEGMENTS_LIST_COUNT } from 'Modules/EmailManagement/Constants/WebservicePaths';
import WebService  from 'Services/WebService/WebService';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import { IError } from 'Redux/Reducers/types';
import { SET_SEGMENT_ITEM_COUNT, SET_SEGMENT_LIST_ITEM_COUNT, SegmentContactCountAction } from '../types';

type PredefinedComplexFilerItemCountResponseType = {
  count: number,
};


export const setSegmentItemCount = (count: number): SegmentContactCountAction => ({
  type: SET_SEGMENT_ITEM_COUNT,
  payload: count,
});

export const setSegmentListItemCount = (count: number): SegmentContactCountAction => ({
  type: SET_SEGMENT_LIST_ITEM_COUNT,
  payload: count,
});

export const getComplexFilterListItemCount = async (id: string, params: string | null, success: (count: number) => void, faile: (error: IError) => void) => {
  try {
    let path = SEGMENTS_LIST_COUNT;
    if (params) {
      path += `?${params}`;
    }
    const option = new WebServiceOption('GET', path);
    const response = await WebService.shared().callWebService(option) as PredefinedComplexFilerItemCountResponseType;
    success(response.count);
  } catch (error) {
    fail(error as IError);
  }
};

export const getComplexFilterItemCount = async (id: string, params: string | null, success: (count: number) => void, faile: (error: IError) => void) => {
  try {
    let path = SEGMENT_LIST_COUNT;
    if (params) {
      path += `?${params}`;
    }
    const option = new WebServiceOption('GET', path.replace(':id', id));
    const response = await WebService.shared().callWebService(option) as PredefinedComplexFilerItemCountResponseType;
    success(response.count);
  } catch (error) {
    fail(error as IError);
  }
};
