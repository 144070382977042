import { INTEGRATED_PLATFORMS } from "Constants/WebservicePaths";
import { INTEGRATED_PLATFORMS_FAIL, INTEGRATED_PLATFORMS_LOADING, INTEGRATED_PLATFORMS_SUCCESS, IntegratedPlatformsAction } from "../types";
import WebServiceOption from "Services/WebService/WebServiceOption";
import WebService from "Services/WebService/WebService";
import { IError, RootReducerState } from "Redux/Reducers/types";
import { ThunkAction } from "redux-thunk";
import { IIntegrationPlatforms } from "Modules/UserOnboarding/types";


type ThunkActionType = ThunkAction<void, RootReducerState, unknown, IntegratedPlatformsAction>;

export const getintegratedPlatformsList = (success: (sources: IIntegrationPlatforms[]) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: INTEGRATED_PLATFORMS_LOADING,
  });
  try {
    const option = new WebServiceOption('GET', INTEGRATED_PLATFORMS);
    const response = await WebService.shared().callWebService(option);
    dispatch({
      type: INTEGRATED_PLATFORMS_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: INTEGRATED_PLATFORMS_FAIL,
    });
    fail(error as IError);
  };
};
