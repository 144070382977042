import { IChannelState, Channel } from 'Modules/Reports/Redux/Reducers/ChannelReducer/types';
import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/types';
import {
  CHANNEL_LIST_LOADING,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_LIST_FAIL,
  ChannelListAction,
} from 'Modules/Reports/Redux/types';

const initialState: IChannelState = {
  status: DEFAULT,
  data: [],
};

const channelReducer = (state = initialState, action: ChannelListAction): IChannelState => {
  const { type, payload } = action;
  switch (type) {
    case CHANNEL_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as Channel[],
      };
    case CHANNEL_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default channelReducer;
