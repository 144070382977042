import { ThunkAction } from 'redux-thunk';

import { TAG_LIST } from 'Constants/WebservicePaths';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import WebService  from 'Services/WebService/WebService';
import { IError } from 'Redux/Reducers/types';
import { RootReducerState } from 'Redux/Reducers/types';
import {
  TagListAction,
  TAG_LIST_LOADING,
  TAG_LIST_SUCCESS,
  TAG_LIST_FAIL,
  NEW_TAG_LOADING,
  NEW_TAG_SUCCESS,
  NEW_TAG_FAIL,
  EDIT_TAG_LOADING,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAIL,
  DELETE_TAG_LOADING,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  RESET_LAST_ADDED_TAG,
} from 'Redux/types';
import { Tag } from 'Redux/Reducers/TagReducer/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, TagListAction>;

export const getTagList = (success: (tags: Tag[]) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: TAG_LIST_LOADING,
  });
  try {
    const option = new WebServiceOption('GET', TAG_LIST);
    const response = await WebService.shared().callWebService(option) as Tag[];
    dispatch({
      type: TAG_LIST_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: TAG_LIST_FAIL,
    });
    fail(error as IError);
  };
};

export const createNewTag = (tag: Tag, success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: NEW_TAG_LOADING,
  });
  try {
    const option = new WebServiceOption('POST', TAG_LIST, tag);
    const response = await WebService.shared().callWebService(option) as Tag;
    dispatch({
      type: NEW_TAG_SUCCESS,
      payload: response,
    });
    success();
  } catch (error) {
    dispatch({
      type: NEW_TAG_FAIL,
    });
    fail(error as IError);
  };
};

export const editTag = (tag: Tag, success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: EDIT_TAG_LOADING,
  });
  try {
    const option = new WebServiceOption('PATCH', `${TAG_LIST}/${tag.id}`, tag);
    const response = await WebService.shared().callWebService(option) as Tag;
    dispatch({
      type: EDIT_TAG_SUCCESS,
      payload: response,
    });
    success();
  } catch (error) {
    dispatch({
      type: EDIT_TAG_FAIL,
    });
    fail(error as IError);
  };
};

export const deleteTag = (tagId: string, success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: DELETE_TAG_LOADING,
  });
  try {
    const option = new WebServiceOption('DELETE', `${TAG_LIST}/${tagId}`);
    await WebService.shared().callWebService(option);
    dispatch({
      type: DELETE_TAG_SUCCESS,
      payload: tagId,
    });
    success();
  } catch (error) {
    dispatch({
      type: DELETE_TAG_FAIL,
    });
    fail(error as IError);
  };
};

export const resetLastAddedTag = ():TagListAction => ({
  type: RESET_LAST_ADDED_TAG,
});
