import { FC, useCallback } from 'react';
import { Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import NavigationItem from 'Blocks/Navigation/Components/NavigationItem/NavigationItem';
import SimpleMainNavIcon from 'Blocks/Navigation/Components/MainNavIcon/SimpleMainNavIcon';
import { navigationItems } from 'Constants/NavigationConstants';
import { closeSecondaryMenu, openSecondaryMenu } from 'Redux/Actions';
import { RootReducerState } from 'Redux/Reducers/types';
import { UIStateType } from 'Redux/Reducers/UIReducer/types';

const Navigation: FC = () => {
  const { isSecondaryMenuOpen } = useSelector<RootReducerState, UIStateType>(state => state.ui);
  const dispatch = useDispatch();
  const handleSecondaryMenuOToggleing = useCallback(() => {
    if (isSecondaryMenuOpen) {
      dispatch(closeSecondaryMenu());
    } else {
      dispatch(openSecondaryMenu());
    }
  }, [dispatch, isSecondaryMenuOpen]);

  const handleCloseSecondaryMenu = useCallback(() => {
    dispatch(closeSecondaryMenu());
  }, [dispatch]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{
        elevation: 4,
      }}
    >
      <SimpleMainNavIcon />
      {navigationItems.map(navigationItem => {
        if (!navigationItem.shoudldOpenSecondaryMenu) {
          return (
            <NavigationItem
              key={navigationItem.title}
              item={navigationItem}
              onClick={handleCloseSecondaryMenu}
            />
          );
        }
        return (
          <NavigationItem
            key={navigationItem.title}
            item={navigationItem}
            onClick={handleSecondaryMenuOToggleing}
          />
        );
      })}
    </Drawer>
  );
};

export default Navigation;
