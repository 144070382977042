import { NavigationPath } from 'Blocks/Navigation/Components/NavigationItem/types';
import { performancePath } from 'Constants/NavigationConstants';
import {
  BASE_URL,
  ENVIRONMENT,
} from 'Constants/Config';

export const BACKEND_BASE_URL = `${BASE_URL}/api/v1`;

export const LOCAL = 'local';
export const DEV = 'dev';
export const TEST = 'test';
export const PRODUCTION = 'production';
const environments = [LOCAL, DEV, TEST, PRODUCTION] as const;
type EnvironmentType = typeof environments[number];

export const isInDevMode = () => ENVIRONMENT as EnvironmentType === 'local';

// User is redirected here when the app is opened without a specific path
export const BASE_MODULE_PATH: NavigationPath = performancePath;

export const DATE_FORMAT: string = "DD/MM/YYYY";
export const DETAILED_DATE_FORMAT: string = "HH:mm - DD/MM/YYYY";
export const ISO_DATE_FORMAT: string = "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]";

// The filter will be applied after this time to avoid unnecessary renders
export const FILTER_TIMEOUT: number = 500;

// Selected complex filter highlight time
export const COMPLEX_FILTER_HIGHLIGHT_TIMEOUT = 2000;

// Time until a toast message stays on screen
export const TOAST_MESSAGE_TIMEOUT: number = 5000;

export const PAGINATION_DEFAULT_ROW_NUMBER: number = 25;

// Limits the number of tags in filter to prevent exceeding the 2000 characters URL limit
export const MAX_TAGS_IN_FILTER = 15;

// Limits the number of selected and unselected list
export const MAX_SAVED_ELEMENTS_IN_LIST = 50;
