import { ThunkAction } from 'redux-thunk';

import { AVAILABLE_COMPONENTS_LIST } from 'Constants/WebservicePaths';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import WebService  from 'Services/WebService/WebService';
import { IError } from 'Redux/Reducers/types';
import {
  AvailableComponentsAction,
  AVAILABLE_COMPONENTS_LOADING,
  AVAILABLE_COMPONENTS_SUCCESS,
  AVAILABLE_COMPONENTS_FAIL,
} from 'Redux/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { AvailableComponents } from 'Redux/Reducers/AvailableComponentsReducer/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, AvailableComponentsAction>;

export const getAvailableComponentsList = (success: (sources: AvailableComponents[]) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: AVAILABLE_COMPONENTS_LOADING,
  });
  try {
    const option = new WebServiceOption('GET', AVAILABLE_COMPONENTS_LIST);
    const response = await WebService.shared().callWebService(option);
    dispatch({
      type: AVAILABLE_COMPONENTS_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: AVAILABLE_COMPONENTS_FAIL,
    });
    fail(error as IError);
  };
};
