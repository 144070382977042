import {
  FC,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Checkbox,
} from '@mui/material';

import { ISyncDialogue } from 'Components/Dialogs/type';
import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import * as translationConstants from 'Constants/TranslationConstants';
import { DialogContext } from 'CustomHooks/useDialog/useDialog';
import { Close } from '@mui/icons-material';
import { MailchimAudienceStatus } from 'CustomHooks/useDialog/types';
import { syncWithMailchimp, syncWithExistingMailchimp } from 'Modules/EmailManagement/Redux/Actions/MailchimpAccountActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import meta_ads from 'Assets/Images/meta_ads.svg'
import google_ads from 'Assets/Images/google_ads.svg'
import mailchimp_ads from 'Assets/Images/mc_ads.svg'
import useGetData from 'CustomHooks/useGetData/useGetData';
import { RootReducerState } from 'Redux/Reducers/types';
import { ISegmentSourceState } from 'Redux/Reducers/SegmentSourceReducer/types';
import { getSegmentSourceList, openSecondaryMenu } from 'Redux/Actions';
import { ISegmentCollectionState } from 'Modules/Marketing/Redux/Reducers/SegmentsReducer/types';
import { resetSegmentList } from 'Modules/Marketing/Redux/Actions';

type MailchimpStateType = {
  dispalyName: string
  segmentId: string,
  sourceId: string,
};

const SyncDialog: FC<ISyncDialogue> = ({
  dialogueProps: {
    syncDialogProps,
  },
}) => {
  const { closeDialogue } = useContext(DialogContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [existingMailchimpAudience, setExistingMailchimpAudience] = useState<MailchimAudienceStatus>(MailchimAudienceStatus.Default);
  const segmentManagementState = useSelector<RootReducerState, ISegmentCollectionState>(state => state.segmentManagement.segments);
  const segmentSourceState = useSelector<RootReducerState, ISegmentSourceState>(state => state.segmentSources);
  useGetData(segmentSourceState, getSegmentSourceList);
  const [sucessfullOverWrite, setSucessfullOverWrite] = useState(false);

  // This logic is required to have the smooth closing animation provided by material UI
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  
  const audience_size_text = useLocalized(translationConstants.audience_size);
  const audience_name_text = useLocalized(translationConstants.audience_name);
  const create_text = useLocalized(translationConstants.create);
  const cancel_text = useLocalized(translationConstants.cancel);
  const use_this_audience_text = useLocalized(translationConstants.use_this_audience);
  const sucessfull_audience_text = useLocalized(translationConstants.sucessfull_audience);
  const sucessfull_overWrite_audience_text = useLocalized(translationConstants.sucessfull_overwrite_audience);
  const error_audience_text = useLocalized(translationConstants.error_audience);
  const use_existing_list_text = useLocalized(translationConstants.use_existing_list);
  const use_text = useLocalized(translationConstants.use);
  const overwrite_list = useLocalized(translationConstants.overwrite_list);
  const [inputFieldValue, setInputFiledValue] = useState('');
  const [mailchimpState, setMailchimpState] = useState<MailchimpStateType[]>([]);
  

  const inputFieldChange = useCallback((e) => {
    setInputFiledValue(e.target.value)
  }, []);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isChecked, setIsChecked] = useState({
    meta_ads: false,
    google_ads: false,
    mailchimp_ads: false,
  });

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        closeDialogue();
      }, 200);
    }
  }, [closeDialogue, open]);

  function findIdByName(name: string) {
    const item = segmentSourceState.data.find(item => item.platform === name);
    return item ? item.id : null;
  }

  const handleCheckBoxChecks = useCallback((data: string | null) => {
    if (data === null) {
      return
    }
    const index = selectedIds.indexOf(data);
    if (index === -1) {
      setSelectedIds([...selectedIds, data]);
    } else {
      const newSelectedIds = [...selectedIds];
      newSelectedIds.splice(index, 1);
      setSelectedIds(newSelectedIds);
    }
  }, [selectedIds]);

  const handleUseExistingMailchimpAudience = useCallback(() => {
    history.replace('/marketing');
    dispatch(openSecondaryMenu());
    closeDialogue()
  }, [closeDialogue, history, dispatch]);

  const handleSaveNewMailchimpAudience = useCallback(() => {
    dispatch(syncWithMailchimp(inputFieldValue, selectedIds, syncDialogProps!.predefinedFilterId, (response) => {
      setSucessfullOverWrite(false)
      setExistingMailchimpAudience(MailchimAudienceStatus.NotExistingAudience)
      for (const key in segmentManagementState) {
        dispatch(resetSegmentList(key));
      }
    }, (error: any) => {
        setExistingMailchimpAudience(MailchimAudienceStatus.ExistingAudience)
        console.log(error)
        if (error.body.errorDetails !== undefined) {
          setMailchimpState(error.body.errorDetails.segmentsWithSameDisplayName)
        }
        for (const key in segmentManagementState) {
          dispatch(resetSegmentList(key));
        }
    }));
  }, [inputFieldValue, dispatch, syncDialogProps, selectedIds, segmentManagementState]);

  const handleOverWriteMailchimpAudience = useCallback(() => {
    const segmentIds = mailchimpState.map(obj => obj.segmentId);
    dispatch(syncWithExistingMailchimp(inputFieldValue, segmentIds, syncDialogProps!.predefinedFilterId, (response) => {
      setSucessfullOverWrite(true)
      setExistingMailchimpAudience(MailchimAudienceStatus.NotExistingAudience)
    }, (error: any) => {
        setExistingMailchimpAudience(MailchimAudienceStatus.ExistingAudience)
    }));
  },[inputFieldValue, dispatch, syncDialogProps, mailchimpState]);


  return (
    <Dialog 
    PaperProps={{
      style: { minWidth: 400 },
    }} 
    open={open} 
    onClose={handleClose}>
      <div className='sync-dialog-head'>
        {
        existingMailchimpAudience === MailchimAudienceStatus.Default 
        && 
          <>
            <div className='sync-dialog-head-text'>
              <div className='sync-dialog-head-text-position'><b>Create new audience</b> for</div>
            </div>
            <div className="sync-dialog-head-button">
              <IconButton onClick={() => handleClose()} >
                <Close />
              </IconButton>
            </div>
          </>
        } 
        {
        inputFieldValue !== '' && existingMailchimpAudience !== MailchimAudienceStatus.Default && 
        (
            <div className='sync-dialog-head-title'>
              <DialogTitle color= { existingMailchimpAudience !== MailchimAudienceStatus.ExistingAudience ? 'secondary' : 'error' } sx={{fontSize: 16, padding: 0 }}>
                <b>{ existingMailchimpAudience !== MailchimAudienceStatus.ExistingAudience ? sucessfullOverWrite ? sucessfull_overWrite_audience_text : sucessfull_audience_text : error_audience_text }</b>
              </DialogTitle>
            </div>
          )}
      </div>
      
      <div className='sync-dialog-top'>
      <div className={`sync-dialog-top-card ${isChecked.meta_ads ? existingMailchimpAudience !== MailchimAudienceStatus.Default ? 'sucess' : 'selected' : ''}`}>
      <div className={`sync-dialog-top__icon ${!isChecked.meta_ads && existingMailchimpAudience !== MailchimAudienceStatus.Default ? 'disabled' : '' }`}>
            {syncDialogProps?.image && (
              
              <img alt='icon-img' src={meta_ads}/>
            )}
          </div>
          {existingMailchimpAudience === MailchimAudienceStatus.Default && 
            <div> 
              <Checkbox
                color="primary"
                onClick={() => {setIsChecked({ ...isChecked, meta_ads: !isChecked.meta_ads }); handleCheckBoxChecks(findIdByName('facebook'))}}
                checked={isChecked.meta_ads}
              />
            </div>
          }
        </div>
        <div className={`sync-dialog-top-card ${isChecked.google_ads ? existingMailchimpAudience !== MailchimAudienceStatus.Default ? 'sucess' : 'selected' : ''}`}>
          <div className={`sync-dialog-top__icon ${!isChecked.google_ads && existingMailchimpAudience !== MailchimAudienceStatus.Default ? 'disabled' : '' }`}>
            {syncDialogProps?.image && (
              
              <img alt='icon-img' src={google_ads}/>
            )}
          </div>
          {existingMailchimpAudience === MailchimAudienceStatus.Default && 
            <div> 
              <Checkbox
                color="primary"
                onClick={() => { setIsChecked({ ...isChecked, google_ads: !isChecked.google_ads }); handleCheckBoxChecks(findIdByName('google_ads'))}}
                checked={isChecked.google_ads}
              />
            </div>
          }
        </div>
        <div className={`sync-dialog-top-card ${isChecked.mailchimp_ads ? existingMailchimpAudience !== MailchimAudienceStatus.Default ? 'sucess' : 'selected' : ''}`}>
        <div className={`sync-dialog-top__icon ${!isChecked.mailchimp_ads && existingMailchimpAudience !== MailchimAudienceStatus.Default ? 'disabled' : '' }`}>
            {syncDialogProps?.image && (
              <img alt='icon-img' src={mailchimp_ads}/>
            )}
          </div>
          {existingMailchimpAudience === MailchimAudienceStatus.Default && 
            <div className='sync-dialog-top__checkbox'> 
              <Checkbox
                color="primary"
                onClick={() => { setIsChecked({ ...isChecked, mailchimp_ads: !isChecked.mailchimp_ads }); handleCheckBoxChecks(findIdByName('mailchimp'))}}
                checked={isChecked.mailchimp_ads}
              />
            </div>
          }
        </div>
  
      </div>
      {syncDialogProps?.audienceSize && (
        <DialogContent className="sync-dialog-content">
          
          { existingMailchimpAudience !== MailchimAudienceStatus.Default && (
            <div className='sync-dialog-title'>
              <DialogTitle sx={{fontSize: 16, padding: 1 }}>
                <b>{inputFieldValue}</b>
              </DialogTitle>
            </div>
          )}
          <div className='sync-dialog-content-top_text'>
            {audience_size_text} 
          </div>
          <div className='sync-dialog-content-top_value'>
            <b>{syncDialogProps.audienceSize}</b>
          </div>
          {existingMailchimpAudience === MailchimAudienceStatus.Default && 
            <TextField id="string" type="string" size='small' placeholder={audience_name_text} sx={{width: 300}} value={inputFieldValue} onChange={inputFieldChange}/>
          }
          {
            existingMailchimpAudience !== MailchimAudienceStatus.Default &&
            (<div className='sync-dialog-content-top_text'>
              {use_this_audience_text}
            </div>)
          }
        </DialogContent>
      )}
      <div className='sync-dialog-bottom'>
        <DialogActions>
        {existingMailchimpAudience === MailchimAudienceStatus.NotExistingAudience && 
          <Button
            key={cancel_text}
            onClick={closeDialogue}
            variant="outlined"
            color="error"
            disabled={inputFieldValue === ''}
          >
            {cancel_text}
          </Button>
        }
          {existingMailchimpAudience === MailchimAudienceStatus.ExistingAudience && 
            <Button
              key={use_existing_list_text}
              onClick={handleUseExistingMailchimpAudience}
              variant="outlined"
              color="error"
              disabled={inputFieldValue === ''}
            >
              {use_existing_list_text}
            </Button>
          }
          {existingMailchimpAudience === MailchimAudienceStatus.Default && 
            <Button
              key={create_text}
              onClick={handleSaveNewMailchimpAudience}
              variant="contained"
              color="primary"
              disabled={inputFieldValue === ''}
            >
              {create_text}
            </Button>  
          }
          {existingMailchimpAudience === MailchimAudienceStatus.NotExistingAudience && 
            <Button
              key={use_text}
              onClick={handleUseExistingMailchimpAudience}
              variant="contained"
              color="primary"
            >
              {use_text}
            </Button>  
          }
          {existingMailchimpAudience === MailchimAudienceStatus.ExistingAudience && 
            <Button
              key={overwrite_list}
              onClick={handleOverWriteMailchimpAudience}
              variant="contained"
              color="primary"
            >
              {overwrite_list}
            </Button>  
          }
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default SyncDialog;
