import { FC } from 'react';

import { IIcon } from 'Components/Icons/types';

const PersonaEngagerIcon: FC<IIcon> = ({
  customcolor,
}) => (
  <svg id="Persona_Engager_icon" data-name="Persona Engager icon" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 150 150">
    <path id="Path_63" data-name="Path 63" d="M91.33,39.54A16.331,16.331,0,1,1,75,23.21,16.331,16.331,0,0,1,91.33,39.54" fill={customcolor ? customcolor : "#707070"}/>
    <path id="Path_64" data-name="Path 64" d="M75,69.543c-17.832,0-18.9,57.248-18.9,57.248H93.9S92.832,69.543,75,69.543Z" fill="none" stroke={customcolor ? customcolor : "#707070"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/>
    <path id="Path_65" data-name="Path 65" d="M41.863,70.887c-13.439,0-14.241,43.144-14.241,43.144H56.1S55.3,70.887,41.863,70.887Z" fill="none" stroke={customcolor ? customcolor : "#707070"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/>
    <path id="Path_66" data-name="Path 66" d="M108.137,70.887C94.7,70.887,93.9,114.031,93.9,114.031h28.482S121.576,70.887,108.137,70.887Z" fill="none" stroke={customcolor ? customcolor : "#707070"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/>
    <line id="Line_1" data-name="Line 1" x1="7.733" y2="7.733" transform="translate(33.492 46.329)" fill="none" stroke={customcolor ? customcolor : "#707070"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
    <path id="Path_67" data-name="Path 67" d="M32.11,41.341l19.264-5.16L46.213,55.444Z" fill={customcolor ? customcolor : "#707070"}/>
    <line id="Line_2" data-name="Line 2" x2="7.733" y2="7.733" transform="translate(108.738 46.329)" fill="none" stroke={customcolor ? customcolor : "#707070"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="10"/>
    <path id="Path_68" data-name="Path 68" d="M103.75,55.444,98.59,36.18l19.263,5.161Z" fill={customcolor ? customcolor : "#707070"}/>
    <rect id="Rectangle_46" data-name="Rectangle 46" width="150" height="150" fill="none"/>
  </svg>
);

export default PersonaEngagerIcon;
