import { SET_SEGMENT_ITEM_COUNT, SET_SEGMENT_LIST_ITEM_COUNT, SegmentContactCountAction } from '../../types';


type SegmentNumber = {
  contactCount: number | null
  contactListCount: number | null
};

const initialState: SegmentNumber = {
  contactCount: null,
  contactListCount: null,
};

const SegmentCountReducer = (state = initialState, action: SegmentContactCountAction): SegmentNumber => {
  const { type, payload } = action;
  switch (type) {
    case SET_SEGMENT_ITEM_COUNT:
      return {
        ...state,
        contactCount: payload as number,
      }
    case SET_SEGMENT_LIST_ITEM_COUNT:
      return {
        ...state,
        contactListCount: payload as number,
      }
    default:
      return state;
  }
};

export default SegmentCountReducer;
