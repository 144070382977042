//Todo import paths when the backend responde to it.
import {
  AUDIENCE_REPORT_PREDEFINED_COMPLEX_FILTER,
  AUDIENCE_REPORT_PREDEFINED_COMPLEX_FILTERS,
  AUDIENCE_REPORT_COMPLEX_FILTER_OPTIONS,
} from 'Modules/Reports/Constants/WebservicePaths';

import createComplexFilterOptionActions from 'Builders/ComplexFilterActionsBuilder';

const {
  getComplexFilterOptions: getAudiencesPerformanceComplexFilterOptions,
  getPredefinedComplexFilter: getAudiencesPerformancePredefinedComplexFilters,
  postPredefinedComplexFilter: postAudiencesPerformancePredefinedComplexFilter,
  patchPredefinedComplexFilter: patchAudiencesPerformancePredefinedComplexFilter,
} = createComplexFilterOptionActions(
  'audiencesPerformance',
  AUDIENCE_REPORT_COMPLEX_FILTER_OPTIONS,
  AUDIENCE_REPORT_PREDEFINED_COMPLEX_FILTERS,
  AUDIENCE_REPORT_PREDEFINED_COMPLEX_FILTER,
);

export {
  getAudiencesPerformanceComplexFilterOptions,
  getAudiencesPerformancePredefinedComplexFilters,
  postAudiencesPerformancePredefinedComplexFilter,
  patchAudiencesPerformancePredefinedComplexFilter,
};
