import { FC, useMemo } from 'react';
import { ListItem } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';

import { INavigationItem } from 'Blocks/Navigation/Components/NavigationItem/types';
import { black, gray } from 'Styles/MUITheme';
import useLocalized from 'CustomHooks/useLocalized/useLocalized';

const NavigationItem: FC<INavigationItem> = ({
  item: {
    title,
    path,
    icon: Icon,
  },
  onClick,
}) => {
  const match = useLocation();
  let shorterUrl = match.pathname.indexOf("/", 2) < 0 ? match.pathname : match.pathname.slice(0, match.pathname.indexOf("/", 2));
  const isActive = useMemo<boolean>(() => path === shorterUrl, [shorterUrl, path]);

  return (
    <NavLink
      to={path}
      className="nav-link"
      activeClassName="nav-link--active"
      onClick={(e) => {
        // Navigation is prevented if user is on the same base path
        const navBase = `/${match.pathname.split('/')[1]}`;
        if (navBase === path) {
          e.preventDefault();
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      <ListItem
        button
        className="nav-link__list-item"
        alignItems="center"
      >
        <Icon customcolor={ isActive ? black : gray } fontSize="large" />
        <div className="nav-link__list-item--text">
          {useLocalized(title)}
        </div>
      </ListItem>
    </NavLink>
  );
};

export default NavigationItem;
