import {
  FC,
  useState,
  useCallback,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button
} from '@mui/material';

import { IActivateDialog } from 'Components/Dialogs/type';
import SearchField from 'Components/SearchField/SearchField';
import { GoogleListItem } from 'CustomHooks/useDialog/types';

const ActivateDialog: FC<IActivateDialog> = ({
  dialogueProps: {
    activateDialogProps,
  },
}) => {
  const [open, setOpen] = useState(true);
  const disableOutsideClickClose = activateDialogProps?.action?.callToAction === undefined;
  const [inputValue, setInputValue] = useState('');
  const acountList = activateDialogProps?.list;
  const handleClose = useCallback(() => {
    if (disableOutsideClickClose && !activateDialogProps?.closeDisabled) {
      setOpen(false);
    }
  }, [disableOutsideClickClose, activateDialogProps?.closeDisabled]);

  const [selectedOption, setSelectedOption] = useState<string>('');
  const [selectedOpitonObject, setSelectedOpitonObject] = useState<GoogleListItem[]>([]);

  const handleRadioChange = (id: string) => {
    setSelectedOption(id);
    acountList && setSelectedOpitonObject(getSubAccountsById(acountList, id));
  };

  const getSubAccountsById = (accounts: any[], id: string) => {
    const account = accounts.find(account => account.id === id);
    return account ? account.subAccounts : [];
  };

  const handleFilterChange = (event: any) => {
    setInputValue(event.value)
  }

  const filteredItems = acountList ? acountList.filter(item =>
    item.name.toLowerCase().includes(inputValue.toLowerCase())
  ): null;

  return (
    <Dialog open={open}>
      <div className="activate-dialog">
        <div className="activate-dialog-body">
          {activateDialogProps?.title && (
            <DialogTitle className="pop-up-dialog-title"
              dangerouslySetInnerHTML={{__html: activateDialogProps.title}}>
            </DialogTitle>
          )}
          <div className="search-field-container">
            <SearchField
              value={inputValue}
              onChange={(e) => handleFilterChange(e)}
              name="searchText"
              clearable
              className="activate-search-field"
            />
          </div>
          <div className="radio-list-container">
            <ul className="radio-list">
             {filteredItems && filteredItems.map((item) => (
                <li key={item.id}>
                  <label>
                    <input
                      type="radio"
                      value={item.id}
                      checked={selectedOption === item.id}
                      onChange={() => handleRadioChange(item.id)}
                    />
                    {item.name}
                  </label>
                </li>
              ))}
            </ul>
          </div> 
          <div className="pop-up-button">
            <DialogActions>
              {activateDialogProps?.sendAction && (
                <Button
                  onClick={() => {
                    activateDialogProps.sendAction!.callToAction?.(selectedOption);
                    handleClose();
                  }}
                  variant="contained"
                  color="success"
                  className="action-button"
                >
                  {activateDialogProps?.sendAction.name}
                </Button>  
              )}
              {activateDialogProps?.sendActionWithData && (
                <Button
                  onClick={() => {
                    activateDialogProps.sendActionWithData!.callToActionWithData?.(selectedOpitonObject);
                    handleClose();
                  }}
                  variant="contained"
                  color="success"
                  className="action-button"
                >
                  {activateDialogProps?.sendActionWithData.name}
                </Button>  
              )}
            </DialogActions>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ActivateDialog;
