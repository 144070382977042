import {
  UIActionType,
  SET_TOAST_MESSAGE,
  REMOVE_TOAST_MESSAGE,
  OPEN_SECONDARY_MENU,
  CLOSE_SECONDARY_MENU,
} from 'Redux/types';
import { ToastMessageProps } from 'Redux/Reducers/UIReducer/types';

export const addToastMessage = (toastMessage: ToastMessageProps): UIActionType => ({
  type: SET_TOAST_MESSAGE,
  payload: toastMessage
});

export const removeToastMessage = (id: string): UIActionType => ({
  type: REMOVE_TOAST_MESSAGE,
  payload: id,
});


export const openSecondaryMenu = (): UIActionType => ({
  type: OPEN_SECONDARY_MENU,
});

export const closeSecondaryMenu = (): UIActionType => ({
  type: CLOSE_SECONDARY_MENU,
});
