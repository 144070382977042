import { ThunkAction } from 'redux-thunk';

import {
  SourcePathReportListActionType,
  SET_SOURCE_PATH_REPORT_LIST_SORTING_CRITERIA,
  SOURCE_PATH_REPORT_LIST_LOADING,
  SOURCE_PATH_REPORT_LIST_SUCCESS,
  SOURCE_PATH_REPORT_LIST_FAIL,
  SET_SOURCE_PATH_REPORT_LIST_FILTER,
  RESET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
  SET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
  SOURCE_PATH_SUMMARY_LOADING,
  SOURCE_PATH_SUMMARY_LIST_SUCCESS,
  SOURCE_PATH_SUMMARY_LIST_FAIL,
  SET_SOURCE_PATH_SUMMARY_LIST_SORTING_CRITERIA,
  SET_SOURCE_PATH_PAGINATION_DATA,
  SET_SOURCE_PATH_ITEM_COUNT,
} from 'Modules/Reports/Redux/types';

import { SOURCE_PATH_COUNT } from 'Modules/Reports/Constants/WebservicePaths';
import WebService  from 'Services/WebService/WebService';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import { ISourcePathReportFilter } from 'Modules/Reports/Redux/Reducers/SourcePathReducer/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { RootReducerState } from 'Redux/Reducers/types';
import UtilityService from 'Services/UtilityService/UtilityService';
import { IError } from 'Redux/Reducers/types';
import { SOURCE_PATH_LIST, SOURCE_PATH_SUMMARY_LIST } from 'Modules/Reports/Constants/WebservicePaths';
import { ISourcePathReport } from 'Modules/Reports/Redux/Reducers/SourcePathReducer/types';
import { ISourcePathWithFrequency } from 'Modules/Reports/Redux/Reducers/SourcePathSummaryReducer/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, SourcePathReportListActionType>;

type GetSourcePathReportListActionType = {
  next: string | null,
  previous: string | null,
  results: ISourcePathReport[],
};

type PredefinedComplexFilerItemCountResponseType = {
  count: number,
};

export const setSourcePathItemCount = (count: number): SourcePathReportListActionType => ({
  type: SET_SOURCE_PATH_ITEM_COUNT,
  payload: count,
});

export const resetSourcePathReportComplexFilter = (): SourcePathReportListActionType => ({
  type: RESET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
});

export const setSourcePathReportFilter = (filter: Partial<ISourcePathReportFilter>): SourcePathReportListActionType => ({
  type: SET_SOURCE_PATH_REPORT_LIST_FILTER,
  payload: filter,
});

export const setSourcePatReportComplexFilter = (filter: ComplexFilterSet): SourcePathReportListActionType => ({
  type: SET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
  payload: filter,
});

export const setSourcePathReportSortingCriteria = (sortingCriteria: SortingCriteria) => ({
  type: SET_SOURCE_PATH_REPORT_LIST_SORTING_CRITERIA,
  payload: sortingCriteria,
});

export const setSourcePathSummarySortingCriteria = (sortingCriteria: SortingCriteria) => ({
  type: SET_SOURCE_PATH_SUMMARY_LIST_SORTING_CRITERIA,
  payload: sortingCriteria,
});

export const setSourcePathPaginationInfo = (paginationInfo: Partial<PaginationInfo>) => ({
  type: SET_SOURCE_PATH_PAGINATION_DATA,
  payload: paginationInfo,
});

export const getSourcePathSummary = (success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: SOURCE_PATH_SUMMARY_LOADING,
  });

  try {
    const option = new WebServiceOption('GET', `${SOURCE_PATH_SUMMARY_LIST}`);
    const response = await WebService.shared().callWebService(option) as ISourcePathWithFrequency[];
    dispatch({
      type: SOURCE_PATH_SUMMARY_LIST_SUCCESS,
      payload: {
        data: response,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: SOURCE_PATH_SUMMARY_LIST_FAIL,
    });
    fail(error as IError);
  };
};

export const getSourcePathReportList = (params: string, success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: SOURCE_PATH_REPORT_LIST_LOADING,
  });

  try {
    const option = new WebServiceOption('GET', `${SOURCE_PATH_LIST}?${params}`);
    const response = await WebService.shared().callWebService(option) as GetSourcePathReportListActionType;
    dispatch({
      type: SOURCE_PATH_REPORT_LIST_SUCCESS,
      payload: {
        paginationData: {
          next: UtilityService.getUrlParam(response.next, 'cursor'),
          previous: UtilityService.getUrlParam(response.previous, 'cursor'),
        },
        data: response.results,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: SOURCE_PATH_REPORT_LIST_FAIL,
    });
    fail(error as IError);
  };
};

export const getComplexFilterItemCount = async (params: string | null, success: (count: number) => void, faile: (error: IError) => void) => {
  try {
    let path = SOURCE_PATH_COUNT;
    if (params) {
      path += `?${params}`;
    }
    const option = new WebServiceOption('GET', path);
    const response = await WebService.shared().callWebService(option) as PredefinedComplexFilerItemCountResponseType;
    success(response.count);
  } catch (error) {
    fail(error as IError);
  }
};
