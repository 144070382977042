import { ISourcePathReportStateWithFrequency } from 'Modules/Reports/Redux/Reducers/SourcePathSummaryReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  SOURCE_PATH_SUMMARY_LOADING,
  SOURCE_PATH_SUMMARY_LIST_SUCCESS,
  SOURCE_PATH_SUMMARY_LIST_FAIL,
  SET_SOURCE_PATH_SUMMARY_LIST_SORTING_CRITERIA,
} from 'Modules/Reports/Redux/types';
import { SourcePathReportListActionType } from 'Modules/Reports/Redux/types';
import SortingService from 'Services/SortingService/SortingService';
import { SortingCriteria } from 'CustomHooks/useSort/types';

export const initialState: ISourcePathReportStateWithFrequency = {
  status: DEFAULT,
  data: [
    {
      id: '',
      sourcePath: [],
      frequency: 0,
    }
  ],
  sortingCriteria: {
    key: 'channel.displayName',
    order: 'ASCENDING',
    dataType: 'NUMBER',
  },
};

const sourcePathSummaryReducer = (state = initialState, action: SourcePathReportListActionType): ISourcePathReportStateWithFrequency => {
  const { type, payload } = action;
  switch (type) {
    case SOURCE_PATH_SUMMARY_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SOURCE_PATH_SUMMARY_LIST_SUCCESS:
      const sortedSummaryData = SortingService.getSortedArray((payload as Partial<ISourcePathReportStateWithFrequency>).data!, state.sortingCriteria);
      return {
        ...state,
        data: sortedSummaryData,
        status: SUCCESS,
      };
    case SOURCE_PATH_SUMMARY_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case SET_SOURCE_PATH_SUMMARY_LIST_SORTING_CRITERIA:
      return {
        ...state,
        sortingCriteria: payload as SortingCriteria,
      };
    default:
      return state;
  }
};

export default sourcePathSummaryReducer;
