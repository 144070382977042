import { DeepPartial } from 'Services/UtilityService/UtilityTypes';
import { IFunnelReportState, IFunnelReportFilter } from 'Modules/Reports/Redux/Reducers/FunnelAnalysisReducer/types';
import { IAudienceReportState, IAudienceReporttFilter } from 'Modules/Reports/Redux/Reducers/AudiencesPerformanceReducer/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { Channel } from 'Modules/Reports/Redux/Reducers/ChannelReducer/types';
import { ICampaignState, ICampaignFilter, ICampaign } from 'Modules/Reports/Redux/Reducers/CampaignsReducer/types';
import { CampaignPaginationInfo } from 'CustomHooks/usePagination/types';
import { ISourcePathReportState, ISourcePathReportFilter } from './Reducers/SourcePathReducer/types';
import { ISourcePathReportStateWithFrequency } from './Reducers/SourcePathSummaryReducer/types';
import { PaginationInfo } from 'CustomHooks/usePagination/types';

// Funnel report action types
export const FUNNEL_REPORT_LIST_LOADING = 'FUNNEL_REPORT_LIST_LOADING';
interface IFunnelReportListLoading {
  type: typeof FUNNEL_REPORT_LIST_LOADING,
  payload?: never,
}

export const FUNNEL_REPORT_LIST_SUCCESS = 'FUNNEL_REPORT_LIST_SUCCESS';
interface IFunnelReportListSuccess {
  type: typeof FUNNEL_REPORT_LIST_SUCCESS,
  payload: DeepPartial<IFunnelReportState>,
}

export const FUNNEL_REPORT_LIST_FAIL = 'FUNNEL_REPORT_LIST_FAIL';
interface IFunnelReportListFail {
  type: typeof FUNNEL_REPORT_LIST_FAIL,
  payload?: never,
}

export const FUNNEL_REPORT_HIDDENSTATE_LOADING = 'FUNNEL_REPORT_HIDDENSTATE_LOADING';
interface IFunnelReportHiddenStateLoading {
  type: typeof FUNNEL_REPORT_HIDDENSTATE_LOADING,
  payload?: never,
}

export const FUNNEL_REPORT_HIDDENSTATE_SUCCESS = 'FUNNEL_REPORT_HIDDENSTATE_SUCCESS';
interface IFunnelReportHiddenStateSuccess {
  type: typeof FUNNEL_REPORT_HIDDENSTATE_SUCCESS,
  payload: DeepPartial<IFunnelReportState>,
}

export const FUNNEL_REPORT_HIDDENSTATE_FAIL = 'FUNNEL_REPORT_HIDDENSTATE_FAIL';
interface IFunnelReportHiddenStateFail {
  type: typeof FUNNEL_REPORT_HIDDENSTATE_FAIL,
  payload?: never,
}

export const SET_FUNNEL_REPORT_LIST_FILTER = 'SET_FUNNEL_REPORT_LIST_FILTER';
interface IFunnelReportListSetFilter {
  type: typeof SET_FUNNEL_REPORT_LIST_FILTER,
  payload: Partial<IFunnelReportFilter>,
}
export const SET_FUNNEL_REPORT_LIST_COMPLEX_FILTER = 'SET_FUNNEL_REPORT_LIST_COMPLEX_FILTER';
interface IFunnelReportListSetComplexFilter {
  type: typeof SET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
  payload: ComplexFilterSet,
}
export const RESET_FUNNEL_REPORT_LIST_COMPLEX_FILTER = 'RESET_FUNNEL_REPORT_LIST_COMPLEX_FILTER';
interface IFunnelReportListResetComplexFilter {
  type: typeof RESET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
  payload?: never,
}
export const SET_FUNNEL_REPORT_LIST_SORTING_CRITERIA = 'SET_FUNNEL_REPORT_LIST_SORTING_CRITERIA';
interface IFunnelReportListSetSortingCriteria {
  type: typeof SET_FUNNEL_REPORT_LIST_SORTING_CRITERIA,
  payload: SortingCriteria,
}

export type FunnelReportListActionType = 
  IFunnelReportListLoading |
  IFunnelReportListSuccess |
  IFunnelReportListFail |
  IFunnelReportListSetFilter |
  IFunnelReportListSetComplexFilter |
  IFunnelReportListResetComplexFilter |
  IFunnelReportListSetSortingCriteria |
  IFunnelReportHiddenStateLoading |
  IFunnelReportHiddenStateSuccess | 
  IFunnelReportHiddenStateFail;

// Channel action types
export const CHANNEL_LIST_LOADING = 'CHANNEL_LIST_LOADING';
interface IChannelListLoading {
  type: typeof CHANNEL_LIST_LOADING,
  payload?: never,
}

export const CHANNEL_LIST_SUCCESS = 'CHANNEL_LIST_SUCCESS';
interface IChannelListSuccess {
  type: typeof CHANNEL_LIST_SUCCESS,
  payload: Channel[],
}

export const CHANNEL_LIST_FAIL = 'CHANNEL_LIST_FAIL';
interface IChannelListFail {
  type: typeof CHANNEL_LIST_FAIL,
  payload?: never,
}

export type ChannelListAction = IChannelListLoading | IChannelListSuccess | IChannelListFail;

// ChannelType action types
export const CHANNEL_TYPE_LIST_LOADING = 'CHANNEL_TYPE_LIST_LOADING';
interface IChannelTypeListLoading {
  type: typeof CHANNEL_TYPE_LIST_LOADING,
  payload?: never,
}

export const CHANNEL_TYPE_LIST_SUCCESS = 'CHANNEL_TYPE_LIST_SUCCESS';
interface IChannelTypeListSuccess {
  type: typeof CHANNEL_TYPE_LIST_SUCCESS,
  payload: Channel[],
}

export const CHANNEL_TYPE_LIST_FAIL = 'CHANNEL_TYPE_LIST_FAIL';
interface IChannelTypeListFail {
  type: typeof CHANNEL_TYPE_LIST_FAIL,
  payload?: never,
}

export type ChannelTypeListAction = IChannelTypeListLoading | IChannelTypeListSuccess | IChannelTypeListFail;

// Site action types
export const SITE_LIST_LOADING = 'SITE_LIST_LOADING';
interface ISiteListLoading {
  type: typeof SITE_LIST_LOADING,
  payload?: never,
}

export const SITE_LIST_SUCCESS = 'SITE_LIST_SUCCESS';
interface ISiteListSuccess {
  type: typeof SITE_LIST_SUCCESS,
  payload: Channel[],
}

export const SITE_LIST_FAIL = 'SITE_LIST_FAIL';
interface ISiteListFail {
  type: typeof SITE_LIST_FAIL,
  payload?: never,
}

export type SiteListAction = ISiteListLoading | ISiteListSuccess | ISiteListFail;

// Funnel report action types
export const CAMPAIGN_LIST_LOADING = 'CAMPAIGN_LIST_LOADING';
interface ICampaignListLoading {
  type: typeof CAMPAIGN_LIST_LOADING,
  payload?: never,
}

export const CAMPAIGN_LIST_SUCCESS = 'CAMPAIGN_LIST_SUCCESS';
interface ICampaignListSuccess {
  type: typeof CAMPAIGN_LIST_SUCCESS,
  payload: DeepPartial<ICampaignState>,
}

export const CAMPAIGN_LIST_FAIL = 'CAMPAIGN_LIST_FAIL';
interface ICampaignListFail {
  type: typeof CAMPAIGN_LIST_FAIL,
  payload?: never,
}

export const SET_CAMPAIGN_LIST_FILTER = 'SET_CAMPAIGN_LIST_FILTER';
interface ICampaignListSetFilter {
  type: typeof SET_CAMPAIGN_LIST_FILTER,
  payload: Partial<ICampaignFilter>,
}

export const SET_CAMPAIGN_LIST_COMPLEX_FILTER = 'SET_CAMPAIGN_LIST_COMPLEX_FILTER';
interface ICampaignListSetComplexFilter {
  type: typeof SET_CAMPAIGN_LIST_COMPLEX_FILTER,
  payload: ComplexFilterSet,
}

export const RESET_CAMPAIGN_LIST_COMPLEX_FILTER = 'RESET_CAMPAIGN_LIST_COMPLEX_FILTER';
interface ICampaignListResetComplexFilter {
  type: typeof RESET_CAMPAIGN_LIST_COMPLEX_FILTER,
  payload?: never,
}

export const SET_CAMPAIGN_LIST_SORTING_CRITERIA = 'SET_CAMPAIGN_LIST_SORTING_CRITERIA';
interface ICampaignListSetSortingCriteria {
  type: typeof SET_CAMPAIGN_LIST_SORTING_CRITERIA,
  payload: SortingCriteria,
}

export const SET_CAMPAIGN_LIST_PAGINATION_DATA = 'SET_CAMPAIGN_LIST_PAGINATION_DATA';
interface ISetCampaignListPaginationData {
  type: typeof SET_CAMPAIGN_LIST_PAGINATION_DATA,
  payload: Partial<CampaignPaginationInfo>,
};

export const SET_CAMPAIGN = 'SET_CAMPAIGN';
interface ISetCampaign {
  type: typeof SET_CAMPAIGN,
  payload: ICampaign,
}

export const SET_CAMPAIGN_ITEM_COUNT = 'SET_CAMPAIGN_ITEM_COUNT';
interface ISetCampaignItemCount {
  type: typeof SET_CAMPAIGN_ITEM_COUNT,
  payload: number | null,
}

export type CampaignListActionType = 
  ICampaignListLoading |
  ICampaignListSuccess |
  ICampaignListFail |
  ICampaignListSetFilter |
  ICampaignListSetComplexFilter |
  ICampaignListResetComplexFilter |
  ICampaignListSetSortingCriteria |
  ISetCampaignListPaginationData |
  ISetCampaignItemCount |
  ISetCampaign;

// Audience report action types

export const AUDIENCE_REPORT_LIST_LOADING = 'AUDIENCE_REPORT_LIST_LOADING';
interface IAudienceReportListLoading {
  type: typeof AUDIENCE_REPORT_LIST_LOADING,
  payload?: never,
}

export const AUDIENCE_REPORT_LIST_SUCCESS = 'AUDIENCE_REPORT_LIST_SUCCESS';
interface IAudienceReportListSuccess {
  type: typeof AUDIENCE_REPORT_LIST_SUCCESS,
  payload: DeepPartial<IAudienceReportState>,
}

export const AUDIENCE_REPORT_LIST_FAIL = 'AUDIENCE_REPORT_LIST_FAIL';
interface IAudienceReportListFail {
  type: typeof AUDIENCE_REPORT_LIST_FAIL,
  payload?: never,
}

export const SET_AUDIENCE_REPORT_LIST_SORTING_CRITERIA = 'SET_AUDIENCE_REPORT_LIST_SORTING_CRITERIA';
interface IAudienceReportListSetSortingCriteria {
  type: typeof SET_AUDIENCE_REPORT_LIST_SORTING_CRITERIA,
  payload: SortingCriteria,
}

export const SET_AUDIENCE_REPORT_LIST_FILTER = 'SET_AUDIENCE_REPORT_LIST_FILTER';
interface IAudienceReportListSetFilter {
  type: typeof SET_AUDIENCE_REPORT_LIST_FILTER,
  payload: Partial<IAudienceReporttFilter>,
}

export const SET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER = 'SET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER';
interface IAudienceReportListSetComplexFilter {
  type: typeof SET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER,
  payload: ComplexFilterSet,
}

export const RESET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER = 'RESET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER';
interface IAudienceReportListResetComplexFilter {
  type: typeof RESET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER,
  payload?: never,
}

export type AudienceReportListActionType = 
  IAudienceReportListLoading |
  IAudienceReportListSuccess |
  IAudienceReportListFail |
  IAudienceReportListSetSortingCriteria |
  IAudienceReportListSetFilter |
  IAudienceReportListSetComplexFilter | 
  IAudienceReportListResetComplexFilter;

  // SourcePath report action types

export const SOURCE_PATH_REPORT_LIST_LOADING = 'SOURCE_PATH_REPORT_LIST_LOADING';
interface ISourcePathReportListLoading {
  type: typeof SOURCE_PATH_REPORT_LIST_LOADING,
  payload?: never,
}

export const SOURCE_PATH_SUMMARY_LOADING = 'SOURCE_PATH_SUMMARY_LOADING';
interface ISourcePathSummaryLoading {
  type: typeof SOURCE_PATH_SUMMARY_LOADING,
  payload?: never,
}

export const SOURCE_PATH_SUMMARY_LIST_SUCCESS = 'SOURCE_PATH_SUMMARY_LIST_SUCCESS';
interface ISourcePathSummaryListSuccess {
  type: typeof SOURCE_PATH_SUMMARY_LIST_SUCCESS
  payload: DeepPartial<ISourcePathReportStateWithFrequency>,
}

export const SOURCE_PATH_SUMMARY_LIST_FAIL = 'SOURCE_PATH_SUMMARY_LIST_FAIL';
interface ISourcePathSummaryListFail {
  type: typeof SOURCE_PATH_SUMMARY_LIST_FAIL,
  payload?: never,
}

export const SET_SOURCE_PATH_SUMMARY_LIST_SORTING_CRITERIA = 'SET_SOURCE_PATH_SUMMARY_LIST_SORTING_CRITERIA';
interface ISourcePathSummaryListSetSortingCriteria {
  type: typeof SET_SOURCE_PATH_SUMMARY_LIST_SORTING_CRITERIA,
  payload: SortingCriteria,
}

export const SOURCE_PATH_REPORT_LIST_SUCCESS = 'SOURCE_PATH_REPORT_LIST_SUCCESS';
interface ISourcePathReportListSuccess {
  type: typeof SOURCE_PATH_REPORT_LIST_SUCCESS,
  payload: DeepPartial<ISourcePathReportState>,
}

export const SOURCE_PATH_REPORT_LIST_FAIL = 'SOURCE_PATH_REPORT_LIST_FAIL';
interface ISourcePathReportListFail {
  type: typeof SOURCE_PATH_REPORT_LIST_FAIL,
  payload?: never,
}

export const SET_SOURCE_PATH_REPORT_LIST_SORTING_CRITERIA = 'SET_SOURCE_PATH_REPORT_LIST_SORTING_CRITERIA';
interface ISourcePathReportListSetSortingCriteria {
  type: typeof SET_SOURCE_PATH_REPORT_LIST_SORTING_CRITERIA,
  payload: SortingCriteria,
}

export const SET_SOURCE_PATH_REPORT_LIST_FILTER = 'SET_SOURCE_PATH_REPORT_LIST_FILTER';
interface ISourcePathReportListSetFilter {
  type: typeof SET_SOURCE_PATH_REPORT_LIST_FILTER,
  payload: Partial<ISourcePathReportFilter>,
}

export const SET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER = 'SET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER';
interface ISourcePathReportListSetComplexFilter {
  type: typeof SET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
  payload: ComplexFilterSet,
}

export const RESET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER = 'RESET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER';
interface ISourcePathReportListResetComplexFilter {
  type: typeof RESET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
  payload?: never,
}

export const SET_SOURCE_PATH_ITEM_COUNT = 'SET_SOURCE_PATH_ITEM_COUNT';
interface ISetSourcePathItemCount {
  type: typeof SET_SOURCE_PATH_ITEM_COUNT,
  payload: number | null,
};

export const SET_SOURCE_PATH_PAGINATION_DATA = 'SET_SOURCE_PATH_PAGINATION_DATA';
interface ISetSourcePathPaginationData {
  type: typeof SET_SOURCE_PATH_PAGINATION_DATA,
  payload: Partial<PaginationInfo>,
};

export type SourcePathReportListActionType = 
  ISourcePathReportListLoading |
  ISourcePathReportListSuccess |
  ISourcePathReportListFail |
  ISourcePathReportListSetSortingCriteria |
  ISourcePathReportListSetFilter |
  ISourcePathReportListSetComplexFilter | 
  ISourcePathReportListResetComplexFilter |
  ISourcePathSummaryLoading |
  ISourcePathSummaryListSetSortingCriteria |
  ISourcePathSummaryListSuccess |
  ISourcePathSummaryListFail |
  ISetSourcePathPaginationData | 
  ISetSourcePathItemCount;
