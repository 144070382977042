import { unionBy, cloneDeep, set } from 'lodash';

import { ChannelReportDetailType, ICampaign } from 'Modules/Reports/Redux/Reducers/CampaignsReducer/types';
import { SetKPIsRequestType } from 'Modules/Reports/Redux/Actions/CampaignActions';

class CampaignService {
  static getMergedCampaigns(existingCampaigns: ICampaign[], newCampaigns: ICampaign[]): ICampaign[] {
    return unionBy(newCampaigns, existingCampaigns, 'id');
  }
  static getCampaignWithSummarizedKpis(campaign: ICampaign): ICampaign {
    let campaignChannelValues = {impressionValue: 0, clicksValue: 0, videoViewsValue: 0, promotionCostValue: 0, interactionCostValue: 0, interactionRateValue: 0, conversionsEvents: 0, conversionValue: 0};
    let campaignChannelKpiValues = {impressionKpiValue: 0, clicksKpiValue: 0, videoViewsKpiValue: 0, promotionCostKpiValue: 0, conversionsEvents: 0, conversionValue: 0};

    function undefinedCheck(campaignValues: ChannelReportDetailType | undefined, campaignValue: number | undefined, campaignReportName: string) {
      if (campaignValues === undefined || campaignValue === undefined) {
          set(campaign, campaignReportName, 0);
      } 
    }
    campaign.channelReports.forEach(report => {
      if (report.conversionsEvents?.value) {
        undefinedCheck(campaign.conversionsEvents, campaign.conversionsEvents?.value, 'conversionsEvents.value');
        campaign.conversionsEvents!.value! = campaignChannelValues.conversionsEvents += report.conversionsEvents.value;
      }
      if (report.conversionValue?.value) {
        undefinedCheck(campaign.conversionValue, campaign.conversionValue?.value, 'conversionValue.value');
        campaign.conversionValue!.value! = campaignChannelValues.conversionValue += report.conversionValue.value;
      }

      if (report.impressions?.value) {
        undefinedCheck(campaign.impressions, campaign.impressions?.value, 'impressions.value');
        campaign.impressions!.value! = campaignChannelValues.impressionValue += report.impressions.value;
      }
      if (report.impressions?.kpiValue) {
        undefinedCheck(report.impressions, campaign.impressions?.kpiValue, 'impressions.kpiValue');
        campaign.impressions!.kpiValue! = campaignChannelKpiValues.impressionKpiValue += report.impressions.kpiValue;
      } else if (campaignChannelKpiValues.impressionKpiValue === 0) {
        set(campaign, 'impressions.kpiValue', null);
      }

      if (report.clicks?.value) {
        undefinedCheck(campaign.clicks, campaign.clicks?.value, 'clicks.value');
        campaign.clicks!.value! = campaignChannelValues.clicksValue += report.clicks.value;
      }
      if (report.clicks?.kpiValue) {
        undefinedCheck(campaign.clicks, campaign.clicks?.kpiValue, 'clicks.kpiValue');
        campaign.clicks!.kpiValue! = campaignChannelKpiValues.clicksKpiValue += report.clicks.kpiValue;
      } else if ( campaignChannelKpiValues.clicksKpiValue === 0) {
        set(campaign, 'clicks.kpiValue', null);
      }

      if (report.videoViews?.value) {
        undefinedCheck(campaign.videoViews, campaign.videoViews?.value, 'videoViews.value');
        campaign.videoViews!.value! = campaignChannelValues.videoViewsValue += report.videoViews.value;
      }
      if (report.videoViews?.kpiValue) {
        undefinedCheck(campaign.videoViews, campaign.videoViews?.kpiValue, 'videoViews.kpiValue');
        campaign.videoViews!.kpiValue! = campaignChannelKpiValues.videoViewsKpiValue += report.videoViews.kpiValue;
      } else if ( campaignChannelKpiValues.videoViewsKpiValue === 0) {
        set(campaign, 'videoViews.kpiValue', null);
      }

      if (report.interactionRate?.value) {
        undefinedCheck(campaign.interactionRate, campaign.interactionRate?.value, 'interactionRate.value');
        campaign.interactionRate!.value! = (campaignChannelValues.videoViewsValue + campaignChannelValues.clicksValue) / campaignChannelValues.impressionValue * 100 
      }

      if (report.promotionCost?.value) {
        undefinedCheck(campaign.promotionCost, campaign.promotionCost?.value, 'promotionCost.value');
        campaign.promotionCost!.value! = campaignChannelValues.promotionCostValue += report.promotionCost.value;
      }
      if (report.promotionCost?.kpiValue) {
        undefinedCheck(campaign.promotionCost, campaign.promotionCost?.kpiValue, 'promotionCost.kpiValue');
        campaign.promotionCost!.kpiValue! = campaignChannelKpiValues.promotionCostKpiValue += report.promotionCost.kpiValue;
      } else if (campaignChannelKpiValues.promotionCostKpiValue === 0) {
        set(campaign, 'promotionCost.kpiValue', null);
      }

      if (report.interactionCost?.value) {
        undefinedCheck(campaign.interactionCost, campaign.interactionCost?.value,'interactionCost.value');
        campaign.interactionCost!.value! = campaignChannelValues.promotionCostValue / (campaignChannelValues.videoViewsValue + campaignChannelValues.clicksValue);
      }
    });
    return campaign;
  }

  // Sums the reportDetail values
  static getCampaignsWithSummarizedKpis(campaigns: ICampaign[]): ICampaign[] {
    const campaignsWithSummarizedKpis: ICampaign[] = [];

    campaigns.forEach(campaign => {
      const campaignWithSummarizedKpis = this.getCampaignWithSummarizedKpis(campaign);
      campaignsWithSummarizedKpis.push(campaignWithSummarizedKpis);
    });

    return campaignsWithSummarizedKpis;;
  }

  static mergeKpisToCampaign(campaign: ICampaign, kpis: SetKPIsRequestType[]): ICampaign {
    const newCampaign: ICampaign = cloneDeep(campaign);

    kpis.forEach(({
      id,
      impressionsKpi,
      videoViewsKpi,
      clicksKpi,
      promotionCostKpi,
    }) => {
      const report = newCampaign.channelReports.find(report => id === report.id);
      if (report) {
        if (impressionsKpi) {
          report.impressions = {
            value: report.impressions?.value,
            kpiValue: impressionsKpi,
          };
        } else {
          report.impressions = {
            value: report.impressions?.value,
            kpiValue: undefined,
          };
        }
        if (videoViewsKpi) {
          report.videoViews = {
            value: report.videoViews?.value,
            kpiValue: videoViewsKpi,
          };
        } else {
          report.videoViews = {
            value: report.videoViews?.value,
            kpiValue: undefined,
          };
        }
        if (clicksKpi) {
          report.clicks = {
            value: report.clicks?.value,
            kpiValue: clicksKpi,
          };
        } else {
          report.clicks = {
            value: report.clicks?.value,
            kpiValue: undefined,
          };
        }
        if (promotionCostKpi) {
          report.promotionCost = {
            value: report.promotionCost?.value,
            kpiValue: promotionCostKpi,
          };
        } else {
          report.promotionCost = {
            value: report.promotionCost?.value,
            kpiValue: undefined,
          };
        }
        if (promotionCostKpi) {
          report.promotionCost = {
            value: report.promotionCost?.value,
            kpiValue: promotionCostKpi,
          };
        } else {
          report.promotionCost = {
            value: report.promotionCost?.value,
            kpiValue: undefined,
          };
        }
      }
    });

    return newCampaign;
  }
}

export default CampaignService;
