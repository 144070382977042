import { FC, useContext, useCallback, SyntheticEvent } from "react";
import { Snackbar, LinearProgress } from '@mui/material';
import { Alert } from '@mui/lab';

import { IToastMessage } from 'Blocks/ToastMessagePresenter/Components/types';
import { ToastMessageContext } from 'CustomHooks/useToastMessage/useToastMessage';

const ToastMessage: FC<IToastMessage> = ({
  message: {
    id,
    message,
    severity,
    withProgress,
  },
  orderFromBottom,
}) => {
  const { removeToastMessage } = useContext(ToastMessageContext);
  const handleToastMessageClose = useCallback<(event: SyntheticEvent) => void>((event) => {
    removeToastMessage(id!);
  }, [id, removeToastMessage]);

  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{
        bottom: `${orderFromBottom * 48 + 24 + orderFromBottom * 6}px`
      }}
    >
      <Alert severity={severity} onClose={withProgress ? undefined : handleToastMessageClose}>
        {message}
        {withProgress && <LinearProgress />}
      </Alert>
    </Snackbar>
  );
};
  
export default ToastMessage;
