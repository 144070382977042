import {
  FUNNEL_REPORT_PREDEFINED_COMPLEX_FILTER,
  FUNNEL_REPORT_PREDEFINED_COMPLEX_FILTERS,
  FUNNEL_REPORT_COMPLEX_FILTER_OPTIONS,
} from 'Modules/Reports/Constants/WebservicePaths';

import createComplexFilterOptionActions from 'Builders/ComplexFilterActionsBuilder';

const {
  getComplexFilterOptions: getFunnelAnalysisComplexFilterOptions,
  getPredefinedComplexFilter: getFunnelAnalysisPredefinedComplexFilters,
  postPredefinedComplexFilter: postFunnelAnalysisPredefinedComplexFilter,
  patchPredefinedComplexFilter: patchFunnelAnalysisPredefinedComplexFilter,
} = createComplexFilterOptionActions(
  'funnelAnalysis',
  FUNNEL_REPORT_COMPLEX_FILTER_OPTIONS,
  FUNNEL_REPORT_PREDEFINED_COMPLEX_FILTERS,
  FUNNEL_REPORT_PREDEFINED_COMPLEX_FILTER,
);

export {
  getFunnelAnalysisComplexFilterOptions,
  getFunnelAnalysisPredefinedComplexFilters,
  postFunnelAnalysisPredefinedComplexFilter,
  patchFunnelAnalysisPredefinedComplexFilter,
};
