import { DEFAULT, FAIL, LOADING, SUCCESS } from "Redux/types";
import { 
  INTEGRATED_PLATFORMS_LOADING, 
  INTEGRATED_PLATFORMS_SUCCESS, 
  INTEGRATED_PLATFORMS_FAIL, 
  IntegratedPlatformsAction 
} from "../types";


const initialState: any = {
  status: DEFAULT,
  data: [],
};

export const integratedPlatformsReducer = ( state = initialState, action: IntegratedPlatformsAction): any => {
  const { type, payload } = action;
  switch (type) {
    case INTEGRATED_PLATFORMS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case INTEGRATED_PLATFORMS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload,
      };
    case INTEGRATED_PLATFORMS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};
