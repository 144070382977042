import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { RootReducerState } from 'Redux/Reducers/types';
import { UserState } from 'Redux/Reducers/UserReducer/types';
import { loginPath, signUpPath, resetPasswordPath, userOnboardingPath } from 'Constants/NavigationConstants';

const ProtectedRoute: FC<RouteProps> = (props) => {
  const userState = useSelector<RootReducerState, UserState>(state => state.user);
  const history = useHistory();
  useEffect(() => {
    const { pathname, search, hash } = history.location;
    const isOnboardingPage = pathname.includes(userOnboardingPath);

    if ((!userState.credentials || !userState.credentials.accessToken || userState.credentials.isValidToken === false) && 
    history.location.pathname !== signUpPath && 
    history.location.pathname !== resetPasswordPath &&
    !(isOnboardingPage && userState.credentials.isValidToken !== false)) {
      history.replace({
        pathname: loginPath,
        search, // This keeps query params like ?token=...
        hash,   // This keeps any hash in the URL
      });
    }
  }, [history, userState.credentials]);

  return <Route {...props} />
};

export default ProtectedRoute;
