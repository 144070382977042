import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  MAILCHIMP_ACCOUNT_LIST_FAIL,
  MAILCHIMP_ACCOUNT_LIST_LOADING,
  MAILCHIMP_ACCOUNT_LIST_SUCCESS,
  MailchimpAccountListAction,
} from 'Modules/Marketing/Redux/types';
import { IMailchimpAccountListState, MailchimpAccountElement } from './types';

const initialState: IMailchimpAccountListState = {
  status: DEFAULT,
  data: [],
};

const mailchimpAccountListReducer = (state = initialState, action: MailchimpAccountListAction): IMailchimpAccountListState => {
  const { type, payload } = action;
  switch (type) {
    case MAILCHIMP_ACCOUNT_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case MAILCHIMP_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as MailchimpAccountElement[],
      };
    case MAILCHIMP_ACCOUNT_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default mailchimpAccountListReducer;
