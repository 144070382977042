import {
  FC,
  forwardRef,
  Ref,
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
  ReactElement
} from 'react';
import {
  Dialog,
  Slide,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { TransitionProps } from '@mui/material/transitions';

import { IHalfPageDialogue } from 'Components/Dialogs/type';
import { DialogContext } from 'CustomHooks/useDialog/useDialog';
import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import { yes } from 'Constants/TranslationConstants';

const Transition = forwardRef((
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>,
) => (
  <Slide direction="left" ref={ref}  {...props} />
));

const HalfPageDialogue: FC<IHalfPageDialogue> = ({
  dialogueProps,
}) => {
  const { presentAlertDialog, closeDialogue } = useContext(DialogContext);
  const yes_text = useLocalized(yes);

  // This logic is required to have the smooth closing animation provided by material UI
  const [open, setOpen] = useState(true);
  const handleClose = useCallback((forced: boolean | undefined) => {
    if (dialogueProps.preventFromGoingAwayMessage && !forced) {
      presentAlertDialog({
        title: '',
        description: dialogueProps.preventFromGoingAwayMessage,
        actions: [{
          name: yes_text,
          action: () => handleClose(true),
        }],
      });
      return;
    }
    setOpen(false);
  }, [dialogueProps.preventFromGoingAwayMessage, presentAlertDialog, yes_text]);

  const [closingStarted, setClosingStarted] = useState<boolean>(false);
  useEffect(() => {
    if (!open && !closingStarted) {
      setClosingStarted(true);
      setTimeout(() => {
        closeDialogue();
      }, 200);
    }
  }, [closeDialogue, closingStarted, open]);

  const [paperWidth, setPaperWidth] = useState<string>("");
  const paperRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (paperRef.current) {
      setPaperWidth(`${paperRef.current.clientWidth}px`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperRef.current]);

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      onClose={() => handleClose(false)}
      PaperProps={{
        style: {
          minWidth: '400px',
          width: dialogueProps.width,
        },
      }}
      TransitionProps={{
        style: {
          justifyContent: 'flex-end',
        },
      }}
    >
      <div className="half-page-dialogue-container" ref={paperRef} style={{ maxWidth: paperWidth }}>
        <IconButton onClick={() => handleClose(false)} className="half-page-dialogue-container__close">
          <Close />
        </IconButton>

        {/* MODAL CONTENT IS RENDERED HERE */}
        <div id={dialogueProps.modalBodyId} className="half-page-dialogue-container__body" />
      </div>
    </Dialog>
  );
};

export default HalfPageDialogue;
