import { IChannelTypeState, ChannelType } from 'Modules/Reports/Redux/Reducers/ChannelTypeReducer/types';
import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/types';
import {
  CHANNEL_TYPE_LIST_LOADING,
  CHANNEL_TYPE_LIST_SUCCESS,
  CHANNEL_TYPE_LIST_FAIL,
  ChannelTypeListAction,
} from 'Modules/Reports/Redux/types';

const initialState: IChannelTypeState = {
  status: DEFAULT,
  data: [],
};

const channelTypeReducer = (state = initialState, action: ChannelTypeListAction): IChannelTypeState => {
  const { type, payload } = action;
  switch (type) {
    case CHANNEL_TYPE_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CHANNEL_TYPE_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as ChannelType[],
      };
    case CHANNEL_TYPE_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default channelTypeReducer;
