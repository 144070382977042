import { NavigationItemType } from 'Blocks/Navigation/Components/NavigationItem/types';
import { digital_personas, persona_engager, performance } from 'Constants/TranslationConstants';
import PersonaEngagerIcon from 'Components/Icons/PersonaEngagerIcon';
import PerformanceIcon from 'Components/Icons/PerformanceIcon';
import DigitalPersonasIcon from 'Components/Icons/DigitalPersonasIcon';

export const loginPath = '/login';
export const signUpPath = '/sign-up'
export const digitalPersonasPath = '/digital-personas';
export const marketingPath = '/marketing';
export const performancePath = '/performance';
export const resetPasswordPath = '/reset-password'
export const userOnboardingPath = '/user-onboarding'

export const navigationPaths = [
  digitalPersonasPath,
  marketingPath,
  performancePath,
] as const;

export const navigationItems: NavigationItemType[] = [{
  title: performance,
  path: performancePath,
  icon: PerformanceIcon,
  }, {
  title: digital_personas,
  path: digitalPersonasPath,
  icon: DigitalPersonasIcon,
}, {
  title: persona_engager,
  path: marketingPath,
  icon: PersonaEngagerIcon,
  shoudldOpenSecondaryMenu: true,
}];
