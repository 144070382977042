import { UserActionType, SET_USER, RESET_LOGOUT_URL } from 'Redux/types';
import { UserState } from 'Redux/Reducers/UserReducer/types';

export const setUser = (user: Partial<UserState>): UserActionType => ({
  type: SET_USER,
  payload: user,
});

export const resetLogoutUrl = (): UserActionType => ({
  type: RESET_LOGOUT_URL,
});
