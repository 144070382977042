import { Country, ICountryState } from 'Modules/EmailManagement/Redux/Reducers/CountryReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  COUNTRY_LIST_LOADING,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_FAIL,
  CountryListAction
} from 'Modules/EmailManagement/Redux/types';

const initialState: ICountryState = {
  status: DEFAULT,
  data: [],
};

const sourceTypeReducer = (state = initialState, action: CountryListAction): ICountryState => {
  const { type, payload } = action;
  switch (type) {
    case COUNTRY_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as Country[],
      };
    case COUNTRY_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default sourceTypeReducer;
