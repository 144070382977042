import {
  IWebservice,
  IWebServiceOption,
  HttpStatusCode,
  CONTENT_TYPE_APPLICATION_JSON,
  CONTENT_TYPE_EXCEL,
  FILE_TYPE_EXCEL,
  ISignUpData,
  ILoginData,
  AuthenticationResult,
  IRequestResetPasswordData,
  IResetPasswordData,
  ISendInvite,
  IGroupCampaignsData,
  IUserData,
} from 'Services/WebService/types';
import { BACKEND_BASE_URL } from 'Constants/Options';
import { IError } from 'Redux/Reducers/types';
import WebServiceOption from './WebServiceOption';
import { GETUSER, INTEGRATED_PLATFORMS_FACEBOOK_AD_ACCOUNTS, INTEGRATED_PLATFORMS_GOOGLE_ADS_ACCOUNTS, INTEGRATED_PLATFORMS_GOOGLE_ANALYTICS_ACCOUNTS, INTEGRATION_PLATFORMS, INTEGRATION_PLATFORMS_ADD, LOGIN, REFRESHTOKEN, REQUESTRESETPASSWORD, RESETPASSWORD, SENDINVITE, SIGN_UP, VALIDATEUSER, SEND_VERIFICATION_EMAIL } from 'Constants/WebservicePaths';
import { UserState } from 'Redux/Reducers/UserReducer/types';
import { DEFAULT, SET_PRO_USER, SET_USER, SUCCESS } from 'Redux/types';
import { store } from 'Redux/store';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { FBLoginResponse, GoogleDataForSend } from 'Services/FacebookLoginService/types';
import { ListItem } from 'CustomHooks/useDialog/types';
import { CAMPAIGN_GROUP, CAMPAIGN_GROUP_LIST } from 'Modules/Reports/Constants/WebservicePaths';

class WebService implements IWebservice {
  token?: string;

  static sharedInstance: IWebservice;
  static shared() {
    if (!WebService.sharedInstance) {
      WebService.sharedInstance = new WebService();
    }
    return WebService.sharedInstance;
  }

  public async setToken(token: string) {
    this.token = token;
  }

  public removeToken() {
    this.token = undefined;
  }

  public async signUp(signUpData: ISignUpData): Promise<any> {
    try {
      const option = new WebServiceOption('POST', SIGN_UP, signUpData);
      return await this.callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async login(loginData: ILoginData) {
    try {
      const option = new WebServiceOption('POST', LOGIN, loginData);
      const response = await WebService.shared().callWebService(option);
      this.handleTokenResponse(response);
    } catch (error) {
      window.sessionStorage.clear();
      window.localStorage.clear();
      throw error;
    }
  }

  public sendVerificationEmail(data: IRequestResetPasswordData): Promise<any> {
    try {
      const option = new WebServiceOption('POST', SEND_VERIFICATION_EMAIL, data);
      return this.callWebService(option);
    } catch (error) {
      throw error;
    }
  }


  public validateUser(token: string) {
    try {
      const option = new WebServiceOption('GET', `${VALIDATEUSER}?token=${token}`);
      WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async getUser() {
    try {
      const option = new WebServiceOption('GET', GETUSER);
      const response = await WebService.shared().callWebService(option) as IUserData;
      store.dispatch({
        type: SET_PRO_USER,
        payload: {
          status: SUCCESS,
          proUser: response.organization.proUser,
          username: response.organization.name,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  public async getFacebbokAdAccounts(id: string) {
    try {
      const url = `${INTEGRATED_PLATFORMS_FACEBOOK_AD_ACCOUNTS.replace('{id}', id)}`;
      const option = new WebServiceOption('GET', url);
      return await WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async getGoogleAnalyticsAdAccounts(id: string) {
    try {
      const url = `${INTEGRATED_PLATFORMS_GOOGLE_ANALYTICS_ACCOUNTS.replace('{id}', id)}`;
      const option = new WebServiceOption('GET', url);
      return await WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async getGoogleAdsAdAccounts(id: string) {
    try {
      const url = `${INTEGRATED_PLATFORMS_GOOGLE_ADS_ACCOUNTS.replace('{id}', id)}`;
      const option = new WebServiceOption('GET', url);
      return await WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async patchFacebookAdAccounts(id: string, patchData: ListItem ) {
    try {
      const url = `${INTEGRATED_PLATFORMS_FACEBOOK_AD_ACCOUNTS.replace('{id}', id)}`;
      const option = new WebServiceOption('PATCH', url, patchData);
      return await WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async patchGoogleAnalyticsAdAccounts(groupId: string, id: string, name: string, subAccountId: string, subAccountName: string) {
    const patchData = {
      id: id,
      name: name,
      subAccountId: subAccountId,
      subAccountName: subAccountName,
    }
    try {
      const url = `${INTEGRATED_PLATFORMS_GOOGLE_ANALYTICS_ACCOUNTS.replace('{id}', groupId)}`;
      const option = new WebServiceOption('PATCH', url, patchData);
      return await WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async patchGoogleAdsAdAccounts(groupId: string, id: string, name: string, subAccountId: string, subAccountName: string) {
    const patchData = {
      id: id,
      name: name,
      subAccountId: subAccountId,
      subAccountName: subAccountName,
    }
    try {
      const url = `${INTEGRATED_PLATFORMS_GOOGLE_ADS_ACCOUNTS.replace('{id}', groupId)}`;
      const option = new WebServiceOption('PATCH', url, patchData);
      return await WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public loginFromInviteLink(data: AuthenticationResult) {
    this.handleTokenResponse(data);
  }

  public logout(fromRemote: boolean) {
     store.dispatch({
      type: SET_USER,
      payload: {
        status: DEFAULT,
        logoutUrl: fromRemote ? '' : window.location.pathname, // User is redirected to the page from where it was logged out programatically
        credentials: {
          accessToken: '',
          refreshToken: '',
          isValidToken: undefined,
        },
      },
    });
  };

  public async resetPassword(data: IResetPasswordData): Promise<any> {
    try {
        const option = new WebServiceOption('POST', RESETPASSWORD, data);
        return await this.callWebService(option);
    } catch (error) {
        throw error;
    }
  }

  public async sendInvite(data: ISendInvite): Promise<any> {
    try {
      const option = new WebServiceOption('POST', SENDINVITE, data);
      return await this.callWebService(option);
  } catch (error) {
      throw error;
  }
  }

  public async integrationPlatforms(): Promise<any> {
    try {
      const option = new WebServiceOption('GET', INTEGRATION_PLATFORMS);
      const response = await WebService.shared().callWebService(option);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  public async campaignsToGroup(params: string): Promise<any> {
    try {
      const option =  new WebServiceOption('GET', `${CAMPAIGN_GROUP_LIST}?channel_id=${params}`);
      const response = await WebService.shared().callWebService(option);
      if (response) {
        return response;
      }
    } catch (error) {
      throw error;
    }
  }

  public async groupCampaigns(data: IGroupCampaignsData): Promise<any> {
    try {
      const option = new WebServiceOption('POST', CAMPAIGN_GROUP, data);
      return await this.callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async integrationPlatformsAdd(data: FBLoginResponse | GoogleDataForSend | undefined, id: string): Promise<any> {

    const tokenPayload = data && 'authResponse' in data ? data.authResponse : data;
    const dataForSend = {
      platformId: id,
      tokenPayload: tokenPayload,
    } 
    try {
      const option = new WebServiceOption('POST', INTEGRATION_PLATFORMS_ADD, dataForSend);
      await WebService.shared().callWebService(option);
    } catch (error) {
      throw error;
    }
  }

  public async requestResetPassword(data: IRequestResetPasswordData): Promise<any> {
    try {
      const option = new WebServiceOption('POST', REQUESTRESETPASSWORD, data);
      return await this.callWebService(option);
    } catch (error) {
        throw error;
    }
  }

  public callWebService(option: IWebServiceOption) {
    return new Promise<void>(async (resolve, reject) => {
      let errorObject: IError = { code: 0 };
      try {
        if (this.token) {
          option.addAuthorizationHeader(this.token);
        }
        const response = await fetch(`${BACKEND_BASE_URL}${option.path}`, option);
        if (!response.ok) {
          switch (response.status) {
            case HttpStatusCode.UNAUTHORIZED:
              try {
                const token = await this.acquireTokenSilent();
                this.setToken(token);
                const resp = await this.callWebService(option);
                if (resp !== undefined) {
                  resolve(resp);
                } else {
                  resolve();
                }
              } catch (error) {
                this.logout(false);
                errorObject.code = HttpStatusCode.UNAUTHORIZED
                reject(errorObject);
              }
              break;
            case HttpStatusCode.NOT_FOUND:
              errorObject.code = HttpStatusCode.NOT_FOUND;
              break;
            case HttpStatusCode.FORBIDDEN:
              errorObject.code = HttpStatusCode.FORBIDDEN;
              break;
            case HttpStatusCode.BAD_REQUEST:
              const error = await response.json();
              errorObject.code = error.errorCode;
              errorObject.body = error;
              break;
            default:
              errorObject.code = HttpStatusCode.INTERNAL_SERVER_ERROR;
              break;
          }
          if (errorObject.code !== 0) {
            reject(errorObject);
          }
          return;
        }

        if (response.status === HttpStatusCode.NO_CONTENT || response.status === HttpStatusCode.ACCEPTED) {
          resolve();
          return;
        }

        const contentType = response.headers.get('Content-Type');

        if (contentType === CONTENT_TYPE_APPLICATION_JSON) {
          const jsonResponse = await response.json();
          resolve(jsonResponse);
          return;
        }

        if (contentType === CONTENT_TYPE_EXCEL) {
          const fileName = this.getFileNameFromResponseHeaders(response.headers);

          const excelBlob = await response.blob();
          const fileUrl = window.URL.createObjectURL(excelBlob);

          const downloadLink = document.createElement("a");
          downloadLink.href = fileUrl;
          downloadLink.download = fileName + FILE_TYPE_EXCEL;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          
          resolve();
          return;
        }

        resolve();
      } catch (error) {
        console.error(error);
        errorObject.code = HttpStatusCode.FRONTEND_ERROR;
        reject(errorObject);
      }
    });
  }

  private getFileNameFromResponseHeaders(headers: Headers): string {
    const contentDispositionHeader = headers.get('Content-Disposition');
    if (!contentDispositionHeader) {
      return "";
    }

    let fileName = "";
    if (contentDispositionHeader.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(contentDispositionHeader);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
    }

    return fileName;
  }

  public isValidToken(token: string) {
    const { exp } = jwt.decode(token) as JwtPayload;
    if (!exp) {
      return false;
    }
    return new Date() <= new Date(exp * 1000);
  }

  private handleTokenResponse(tokenResponse: AuthenticationResult | null) {
    if (tokenResponse === null || tokenResponse.access === '' ) {
      return;
    }

    const userObject: Partial<UserState> = {
      status: SUCCESS,
      credentials: {
        accessToken: tokenResponse.access,
        refreshToken: tokenResponse.refresh ? tokenResponse.refresh : store.getState().user.credentials.refreshToken,
        isValidToken: this.isValidToken(tokenResponse.access),
      },
    };

    store.dispatch({
      type: SET_USER,
      payload: userObject,
    })
  }

  public acquireTokenSilent() {
    return new Promise<string>(async (resolve, reject) => {
      const tokenToRefresh = {refresh: store.getState().user.credentials.refreshToken}
      try {
        const option = new WebServiceOption('POST', REFRESHTOKEN, tokenToRefresh);
        const response = await WebService.shared().callWebService(option);
        this.handleTokenResponse(response);
        resolve(response);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }
}

export default WebService;
