import { FC } from 'react';

import { IMainNavIcon } from 'Blocks/Navigation/Components/MainNavIcon/types';

const MainNavIcon: FC<IMainNavIcon> = ({
  type = 'normal',
}) => (
  <div className={`main-nav-icon ${type === 'large' ? 'large' : 'normal'}`}>
    <svg xmlns="http://www.w3.org/2000/svg" width="641.028" height="641.032" viewBox="0 0 641.028 641.032">
      <g id="Group_49" data-name="Group 49" transform="translate(77.714 191.7)">
        <path id="Path_58" data-name="Path 58" d="M101.6,22.481h596.07V618.553H101.6ZM79.118,641.032H720.146V0H79.118Z" transform="translate(-156.832 -191.7)" fill="#f74a1d"/>
        <path id="Path_59" data-name="Path 59" d="M238.207,189.305q9.231-16.469,9.233-39.129,0-22.866-9.233-39.443A61.57,61.57,0,0,0,211.56,85.345q-17.413-8.813-41.75-8.81H126.381V223.19H169.81q24.336,0,41.75-8.709a61.04,61.04,0,0,0,26.646-25.176m-68.4,21.715H141.066V88.7H169.81q30.631,0,46.683,16.263t16.05,45.213q0,28.747-16.05,44.794t-46.683,16.05" transform="translate(-112.055 -119.191)"/>
        <path id="Path_60" data-name="Path 60" d="M218.051,187.444h66.722l12.8,34.827H313.1L259.805,77.5H243.231l-53.5,144.766h15.525Zm33.361-91.477,28.956,79.308H222.46Z" transform="translate(-52.04 -118.272)"/>
        <path id="Path_61" data-name="Path 61" d="M293.128,223.19h14.685V88.7h40.708V76.535H252.215V88.7h40.913Z" transform="translate(7.161 -119.191)"/>
        <path id="Path_62" data-name="Path 62" d="M316.435,222.271l12.8-34.827h66.718l12.8,34.827h15.529L370.983,77.5h-16.57l-53.5,144.766Zm46.159-126.3,28.952,79.308H333.638Z" transform="translate(53.298 -118.272)"/>
        <path id="Path_63" data-name="Path 63" d="M201.918,246.3a193.663,193.663,0,0,0-23.182-9.231q-14.27-4.615-20.983-8.606t-6.715-11.12q0-6.5,3.988-9.967t10.491-3.461q7.551,0,12.378,4.091a15.2,15.2,0,0,1,5.246,11.225h38.816q-1.055-20.769-15.945-32.415t-39.236-11.645q-23.918,0-38.812,11.645t-14.9,32.206q0,13.846,6.506,22.45a42.962,42.962,0,0,0,15.525,13.007,165.436,165.436,0,0,0,23.287,8.814,145.574,145.574,0,0,1,15.213,5.242,29.64,29.64,0,0,1,9.022,5.877,12.058,12.058,0,0,1,3.671,9.022,12.29,12.29,0,0,1-4.613,10.175q-4.621,3.672-12.59,3.669-7.767,0-12.59-4.3T150.83,280.5H112.643q.424,14.477,8.08,24.755a47.162,47.162,0,0,0,20.456,15.529,75.742,75.742,0,0,0,28.956,5.242q16.569,0,28.744-6.084a44.845,44.845,0,0,0,18.568-16.362,42.457,42.457,0,0,0,6.4-22.872q0-13.215-6.506-21.608a44.134,44.134,0,0,0-15.421-12.8" transform="translate(-125.07 -25.739)"/>
        <path id="Path_64" data-name="Path 64" d="M265.2,233.949H209.393v-57.7H173.514V323.538h35.879V262.905H265.2v60.632h35.875V176.254H265.2Z" transform="translate(-67.401 -24.717)"/>
        <rect id="Rectangle_43" data-name="Rectangle 43" width="35.881" height="147.284" transform="translate(244.797 151.537)"/>
        <path id="Path_65" data-name="Path 65" d="M364.853,176.254H268.97V323.538h35.879V263.951h44.893v-27.9H304.849V205h95.242v118.54h35.877V205h39.024V176.254Z" transform="translate(23.034 -24.717)"/>
      </g>
    </svg>
  </div>
);

export default MainNavIcon;
