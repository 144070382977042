import { combineReducers } from 'redux';

import FunnelAnalysisReducer from 'Modules/Reports/Redux/Reducers/FunnelAnalysisReducer/FunnelAnalysisReducer';
import FunnelAnalysisComplexFilterOptionsReducer from 'Modules/Reports/Redux/Reducers/FunnelAnalysisComplexFilterOptionsReducer/FunnelAnalysisComplexFilterOptionsReducer';
import ChannelReducer from 'Modules/Reports/Redux/Reducers/ChannelReducer/ChannelReducer';
import ChannelTypeReducer from 'Modules/Reports/Redux/Reducers/ChannelTypeReducer/ChannelTypeReducer';
import SiteReducer from 'Modules/Reports/Redux/Reducers/SiteReducer/SiteReducer';
import CampaignsReducer from 'Modules/Reports/Redux/Reducers/CampaignsReducer/CampaignsReducer';
import CampaignsComplexFilterOptionsReducer from 'Modules/Reports/Redux/Reducers/CampaignsComplexFilterOptionsReducer/CampaignsComplexFilterOptionsReducer';
import AudiencesPerformanceReducer from './AudiencesPerformanceReducer/AudiencesPerformanceReducer';
import AudiencesPerformanceComplexFilterOptionsReducer from './AudiencesPerformanceComplexFilterOptionsReducer/AudiencesPerformanceComplexFilterOptionsReducer';
import SourcePathReducer from 'Modules/Reports/Redux/Reducers/SourcePathReducer/SourcePathReducer';
import SourcePathComplexFilterOptionsReducer from 'Modules/Reports/Redux/Reducers/SourcePathComplexFilterOptionsReducer/SourcePathComplexFilterOptionsReducer';
import SourcePathSummaryReducer from 'Modules/Reports/Redux/Reducers/SourcePathSummaryReducer/SourcePathSummaryReducer';

const rootReducer = combineReducers({
  campaigns: CampaignsReducer,
  campaignsComplexFilterOptions: CampaignsComplexFilterOptionsReducer,
  channels: ChannelReducer,
  channelTypes: ChannelTypeReducer,
  sites: SiteReducer,
  funnelAnalysis: FunnelAnalysisReducer,
  funnelAnalysisComplexFilterOptions: FunnelAnalysisComplexFilterOptionsReducer,
  audiencesPerformance: AudiencesPerformanceReducer,
  audiencesPerformanceComplexFilterOptions: AudiencesPerformanceComplexFilterOptionsReducer,
  sourcePath: SourcePathReducer,
  sourcePathComplexFilterOptionsReducer: SourcePathComplexFilterOptionsReducer,
  sourcePathSummary: SourcePathSummaryReducer,
});

export default rootReducer;
