import { ThunkAction } from 'redux-thunk';

import UtilityService from 'Services/UtilityService/UtilityService'
import { IError } from 'Redux/Reducers/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { SelectionActionObject } from 'CustomHooks/useSelection/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import WebService  from 'Services/WebService/WebService';
import { SegmentContactListAction } from 'Modules/Marketing/Redux/types';
import {
  SET_SEGMENT_CONTACT_FILTER,
  SEGMENT_LOADING,
  SEGMENT_SUCCESS,
  SEGMENT_FAIL,
  CREATE_SEGMENT_LOADING,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_FAIL,
  UPDATE_SEGMENT_LOADING,
  UPDATE_SEGMENT_SUCCESS,
  UPDATE_SEGMENT_FAIL,
  SEGMENT_CONTACT_LIST_LOADING,
  SEGMENT_CONTACT_LIST_SUCCESS,
  SEGMENT_CONTACT_LIST_FAIL,
  SET_SEGMENT_NAME,
  SET_SELECTED_SEGMENTS_CONTACTS,
  SET_SEGMENT_CONTACT_PAGINATION_DATA,
  SET_SEGMENT_CONTACT_SORTING_CRITERIA,
  SET_SEGMENT_CONTACT_COMPLEX_FILTER,
  RESET_SEGMENT_CONTACTS,
  BULK_SET_SEGMENT_CONTACTS,
} from 'Modules/Marketing/Redux/types';
import {
  SEGMENT_CONTACT_LIST,
  SEGMENT_LIST,
  DOWNLOAD_SEGMENT_CONTACT_LIST,
  SEGMENT_CONTACT_LIST_STATUS_BULK,
  SEGMENT_CONTACT_LIST_STATUS,
} from 'Modules/Marketing/Constants/WebservicePaths';
import { SegmentContact, ISegmentContactFilter } from 'Modules/Marketing/Redux/Reducers/SegmentContactsReducer/types';
import { Segment } from 'Modules/Marketing/Redux/Reducers/SegmentsReducer/types';

type SegmentContactThunkActionType = ThunkAction<void, RootReducerState, unknown, SegmentContactListAction>;

type GetSegmentContactListResponseType = {
  next: string | null,
  previous: string | null,
  results: SegmentContact[],
};

type PostSegmentRequestType = {
  displayName: string,
  predefinedFilterId: string,
  sourceId: string,
};

type PatchSelectedContactStatusesRequestType = {
  contactId: string,
  disabled: boolean,
}[];

export const setSegmentContactFilter = (filter: Partial<ISegmentContactFilter>): SegmentContactListAction => ({
  type: SET_SEGMENT_CONTACT_FILTER,
  payload: {
    filter,
  },
});

export const setSegmentContactSelection = (actionObject: SelectionActionObject): SegmentContactListAction => ({
  type: SET_SELECTED_SEGMENTS_CONTACTS,
  payload: {
    actionObject,
  },
});

export const setSegmentContactPagination = (paginationInfo: Partial<PaginationInfo>): SegmentContactListAction => ({
  type: SET_SEGMENT_CONTACT_PAGINATION_DATA,
  payload: {
    paginationInfo,
  },
});

export const setSegmentContactSortingCriteria = (sortingCriteria: SortingCriteria): SegmentContactListAction => ({
  type: SET_SEGMENT_CONTACT_SORTING_CRITERIA,
  payload: {
    sortingCriteria,
  },
});

export const setSegmentContactComplexFilter = (complexFilter: ComplexFilterSet): SegmentContactListAction => ({
  type: SET_SEGMENT_CONTACT_COMPLEX_FILTER,
  payload: {
    complexFilter,
  },
});

export const resetSegmentContacts = (): SegmentContactListAction => ({
  type: RESET_SEGMENT_CONTACTS,
  payload: {},
});

export const setSegmentName = (name: string): SegmentContactListAction => ({
  type: SET_SEGMENT_NAME,
  payload: {
    name,
  },
});

export const getSegment = (id: string, success: () => void, fail: (error: IError) => void): SegmentContactThunkActionType => async dispatch => {
  dispatch({
    type: SEGMENT_LOADING,
    payload: {},
  });
  try {
    const option = new WebServiceOption('GET', `${SEGMENT_LIST}/${id}`);
    const response = await WebService.shared().callWebService(option) as Segment;
    dispatch({
      type: SEGMENT_SUCCESS,
      payload: {
        segment: response,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: SEGMENT_FAIL,
      payload: {},
    });
    fail(error as IError);
  }
};

export const createSegment = (segment: PostSegmentRequestType, success: (id: string) => void, fail: (error: IError) => void): SegmentContactThunkActionType => async dispatch => {
  dispatch({
    type: CREATE_SEGMENT_LOADING,
    payload: {},
  });
  try {
    const option = new WebServiceOption('POST', `${SEGMENT_LIST}`, segment);
    const response = await WebService.shared().callWebService(option) as Segment;
    dispatch({
      type: CREATE_SEGMENT_SUCCESS,
      payload: {
        segment: response,
      },
    });
    success(response.id);
  } catch (error) {
    dispatch({
      type: CREATE_SEGMENT_FAIL,
      payload: {},
    });
    fail(error as IError);
  }
};

export const editSegment = (id: string, segment: PostSegmentRequestType, success: () => void, fail: (error: IError) => void): SegmentContactThunkActionType => async dispatch => {
  dispatch({
    type: UPDATE_SEGMENT_LOADING,
    payload: {},
  });
  try {
    const option = new WebServiceOption('PATCH', `${SEGMENT_LIST}/${id}`, segment);
    const response = await WebService.shared().callWebService(option) as Segment;
    dispatch({
      type: UPDATE_SEGMENT_SUCCESS,
      payload: {
        segment: response,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: UPDATE_SEGMENT_FAIL,
      payload: {},
    });
    fail(error as IError);
  }
};

export const getSegmentContactList = (id: string, params: string, success: () => void, fail: (error: IError) => void):SegmentContactThunkActionType => async dispatch => {
  dispatch({
    type: SEGMENT_CONTACT_LIST_LOADING,
    payload: {},
  });
  try {
    const option = new WebServiceOption('GET', `${SEGMENT_CONTACT_LIST.replace(':id', id)}?${params}`);
    const response = await WebService.shared().callWebService(option) as GetSegmentContactListResponseType;

    dispatch({
      type: SEGMENT_CONTACT_LIST_SUCCESS,
      payload: {
        state: {
          paginationData: {
            next: UtilityService.getUrlParam(response.next, 'cursor'),
            previous: UtilityService.getUrlParam(response.previous, 'cursor'),
          },
          data: response.results,
        }
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: SEGMENT_CONTACT_LIST_FAIL,
      payload: {},
    });
    fail(error as IError);
  };
};

export const downloadSegmentContactXls = async (id: string, fail: (error: IError) => void) => {
  try {
    const option = new WebServiceOption('GET', DOWNLOAD_SEGMENT_CONTACT_LIST.replace(':id', id));
    await WebService.shared().callWebService(option);
  } catch (error) {
    fail(error as IError);
  }
};

export const bulkPatchContactStatus = async (segmentId: string, status: boolean, success: () => void, fail: (error: IError) => void) => {
  try {
    const option = new WebServiceOption('PATCH', SEGMENT_CONTACT_LIST_STATUS_BULK.replace(':id', segmentId), {
      disabled: status,
    });
    await WebService.shared().callWebService(option);
    success();
  } catch (error) {
    fail(error as IError);
  }
};

export const patchSelectedContactStatuses = async (segmentId: string, patchObject: PatchSelectedContactStatusesRequestType, success: () => void, fail: (error: IError) => void) => {
  try {
    const option = new WebServiceOption('PATCH', SEGMENT_CONTACT_LIST_STATUS.replace(':id', segmentId), patchObject);
    await WebService.shared().callWebService(option);
    success();
  } catch (error) {
    fail(error as IError);
  }
};

export const bulkSetSegmentContacts = (contactIds: string[], contact: Partial<SegmentContact>): SegmentContactListAction => {
  return {
    type: BULK_SET_SEGMENT_CONTACTS,
    payload: {
      contactIds,
      contact,
    }
  };
};
