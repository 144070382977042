import {
  IFilterBase,
  FilterElement,
  IFilterable,
} from 'CustomHooks/useFilter/types';
import { IComplexFilterable } from 'CustomHooks/useComplexFilter/types';
import { IHasSortingCriteria } from 'CustomHooks/useSort/types';
import { IGenericStateWithPagination } from 'Redux/Reducers/types';
import { Tag } from 'Redux/Reducers/TagReducer/types';
import { Source } from 'Redux/Reducers/SourceReducer/types';

export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
export type ContactStatus = typeof ACTIVE | typeof INACTIVE;

export type History = {
  id: string,
  dateAdded: string,
  currentVersion: Contact,
  previousVersion: Contact | null,
};

export interface IHistoryState extends IGenericStateWithPagination<History>, IFilterable<{}> {

};

export type DataElement = {
  [key: string]: string | any,
};

export type Data = {
  id?: string,
  source: Source,
  jsonPayload: DataElement,
  updatedAt: string,
};

export type Contact = {
  id: string,
  email: string,
  status: ContactStatus,
  tags: Tag[],
  history?: IHistoryState,
  data: Data[],
  createdAt: string,
  isDeleted?: boolean,
  mergedData: DataElement,
};

export interface IContactFilter extends IFilterBase {
  searchText: FilterElement,
  predefinedFilterId: FilterElement,
}

export interface IContactState extends IGenericStateWithPagination<Contact>, IFilterable<IContactFilter>, IHasSortingCriteria, IComplexFilterable {
  contactCount: number | null,
  fixContactCount: number | null,
  taggedContactIds: string[],
};
