import { combineReducers } from 'redux';

// Main Reducers
import UserReducer from 'Redux/Reducers/UserReducer/UserReducer';
import UIReducer from 'Redux/Reducers/UIReducer/UIReducer';
import ComplexFilterOptionsReducer from 'Redux/Reducers/ComplexFilterOptionsReducer/ComplexFilterOptionsReducer';
import TagReducer from 'Redux/Reducers/TagReducer/TagReducer';
import SourceReducer from 'Redux/Reducers/SourceReducer/SourceReducer';
import SegmentSourceReducer from './SegmentSourceReducer/SegmentSourceReducer';

// Module Reducers
import EmailManagementReducer from 'Modules/EmailManagement/Redux/Reducers';
import SegmentManagementReducer from 'Modules/Marketing/Redux/Reducers';
import ReportsReducer from 'Modules/Reports/Redux/Reducers';
import AvailableComponentsReducer from './AvailableComponentsReducer/AvailableComponentsReducer';
import UserOnboardingReducer from 'Modules/UserOnboarding/Redux/Reducers';


const rootReducer = combineReducers({

    // Main reducers
    user: UserReducer,
    ui: UIReducer,
    complexFilterOption: ComplexFilterOptionsReducer,
    tags: TagReducer,
    sources: SourceReducer,
    segmentSources: SegmentSourceReducer,
    availableComponents: AvailableComponentsReducer,
    userOnboarding: UserOnboardingReducer,

    // Module reducers
    emailManagement: EmailManagementReducer,
    segmentManagement: SegmentManagementReducer,
    reportManagement: ReportsReducer,
});

export default rootReducer;
