import { createTheme, ThemeOptions } from '@mui/material';

// Color constants
export const primaryColor = "#007AFF";
const primaryColorDark = "#036FE5";
const primaryColorLight = "#268EFF";
const primaryColorSelected = "rgba(0, 122, 255, 0.2)";

const secondaryColor = "#00D153";
const secondaryColorDark = "#00B247";
const secondaryColorLight = "#21E870";

export const white = "#fff";
export const black = "#707070";
export const gray = '#C8C8C9';
export const gray2 = '#DFDFDF';
export const gray3 = '#C6C6C6';
export const lightGrey = "#fafafa";
export const lightGrey2 = "#F5F5F5";
export const lightBlue = "#DEEBFF";
export const lightBlue2 = "#B1D6FF";

// Font constants
const fontFamily = "Roboto, sans-serif";

// Other constants
export const shadowValues = "0px 2px 4px -1px rgb(0 0 0 / 16%), 0px 4px 5px 0px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 8%)";
export const accordionShadowValues = "0px 3px 3px 0px rgba(0,0,0,0.16)";
const drawerWidth = "90px"; // Width of left navigation menu

const theme: ThemeOptions = {
  palette: {
    primary: {
      light: primaryColorLight, 
      main: primaryColor,
      dark: primaryColorDark,
      contrastText: white,
    },
    secondary: {
      light: secondaryColorLight, 
      main: secondaryColor,
      dark: secondaryColorDark,
      contrastText: white,
    },
    common: {
      black: black,
      white: white,
    },
    background: {
      default: lightGrey,
    },
  },
  typography: {
    fontFamily: fontFamily,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorLeft: {
          width: drawerWidth,
          display: "flex",
          alignItems: "center"
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: primaryColor,
          border: "1px solid",
          borderColor: white,
          boxShadow: shadowValues,
        }
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          height: '19px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          backgroundColor: white,
          paddingLeft: 4,
          paddingRight: 4,
        },
        root: {
          fontSize: '0.95rem',
          transform: 'translate(14px, 13px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -8px) scale(0.75) !important',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          borderRadius: "4px",
          boxShadow: shadowValues,
          "&$focused": {
            backgroundColor: lightGrey,
          },
          "&:hover": {
            backgroundColor: lightGrey,
          }
        },
        input: {
          padding: "10px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexDirection: 'column',
        }
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          cursor: 'pointer',
          "&:hover": {
            textDecoration: "underline",
          }
        },
        underlineHover: {
          textDecoration: "underline",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px',
          color: black,
        },
        head: {
          color: black,
        },
        body: {
          color: black,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&$selected, &$selected:hover": {
            backgroundColor: primaryColorSelected,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: black,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          transition: 'width 0.2s'
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          color: black,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          top: '-4px',
          right: '-4px',
          height: '16px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: shadowValues,
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          margin: "6px 0",
          boxShadow: accordionShadowValues,
          border: `solid 1px ${gray2}`,
          borderRadius: '4px',
          "&:before": {
            backgroundColor: 'white',
          }
        },
      },
    },
  },
};

const muiTheme = createTheme(theme);

export default muiTheme;
