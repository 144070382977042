import { intersectionBy, unionBy } from 'lodash';

import { ITagService } from 'Services/TagService/types';
import { FEContact } from 'Modules/EmailManagement/Services/ContactService/types';
import { Tag } from 'Redux/Reducers/TagReducer/types';
import { TagmanagementState } from 'Modules/EmailManagement/Blocks/TagManagement/types';

class TagService implements ITagService  {
  public getCommonTagsFromContacts(contacts: FEContact[]): Tag[] {
    const arrayOfTags: Tag[][] = contacts.map(contact => contact.tags);
    const commonTags = intersectionBy(...arrayOfTags, 'id');
    return commonTags;
  }

  public getStateAfterTagDelete(state: TagmanagementState, id: string): TagmanagementState {

    const tagFromOriginals = state.originalTags.find(({ id: originalTagId }) => originalTagId === id);
    const tagFromRemoved = state.removedTags.find(({ id: removedTagId }) => removedTagId === id);

    // Returns state after deleting a tag from originalTags
    if (tagFromOriginals && !tagFromRemoved) {
      return {
        ...state,
        removedTags: [...state.removedTags, tagFromOriginals],
      };
    }

    // Returns state correctly after deleting a tag from removedTags but was original
    if (tagFromOriginals && tagFromRemoved) {
      return {
        ...state,
        removedTags: state.removedTags.filter(tag => tag.id !== id),
      };
    }

    // Returns state after deleting a tag from addedTags
    const tagFromAdded = state.addedTags.find(({ id: addedTagId }) => addedTagId === id);
    if (tagFromAdded) {
      return {
        ...state,
        addedTags: state.addedTags.filter(tag => tag.id !== id),
      };
    }
    return state;
  }

  public checkIfSelected(state: TagmanagementState, id: string): boolean {
    const tagFromAdded = state.addedTags.find(({ id: addedTagId }) => addedTagId === id);
    if (tagFromAdded) {
      return true;
    }
    const tagFromRemoved = state.removedTags.find(({ id: removedTagId }) => removedTagId === id);
    if (tagFromRemoved) {
      return false;
    }
    const tagFromOriginals = state.originalTags.find(({ id: originalTagId }) => originalTagId === id);
    if (tagFromOriginals) {
      return true;
    }
    return false;
  }

  public mergeTags(oldTags: Tag[], newTags: Tag[]): Tag[] {
    return unionBy(newTags, oldTags, 'id');
  }

  public removeTag(tagId: string, tags: Tag[]): Tag[] {
    return tags.filter(({ id }) => id !== tagId);
  }
}

export default TagService;
