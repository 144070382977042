import {
  IWebServiceOption,
  SupportedMethodType,
  SupportedContentType,
} from 'Services/WebService/types';

export class WebServiceOption implements IWebServiceOption {
  method: SupportedMethodType;
  path: string;
  body?: any;
  headers: Headers;

  constructor(method: SupportedMethodType, path: string, body?: any, contentType: SupportedContentType = 'application/json') {
    this.method = method;
    this.path = path;

    this.body = contentType === 'application/json' ? JSON.stringify(body) : body;

    const headers = new Headers();

    // For multipart form data the browser will set the content type with boundary automatically
    if (contentType !== 'multipart/form-data') {
      headers.append('Content-Type', contentType);
    }
    this.headers = headers;
  }

  public addAuthorizationHeader(token: string) {
    this.headers.set('Authorization', `Bearer ${token}`);
  }
}

export default WebServiceOption;
