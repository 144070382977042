import {
  CAMPAIGNS_PREDEFINED_COMPLEX_FILTER,
  CAMPAIGNS_PREDEFINED_COMPLEX_FILTERS,
  CAMPAIGNS_COMPLEX_FILTER_OPTIONS,
} from 'Modules/Reports/Constants/WebservicePaths';

import createComplexFilterOptionActions from 'Builders/ComplexFilterActionsBuilder';

const {
  getComplexFilterOptions: getCampaignsComplexFilterOptions,
  getPredefinedComplexFilter: getCampaignsPredefinedComplexFilters,
  postPredefinedComplexFilter: postCampaignsPredefinedComplexFilter,
  patchPredefinedComplexFilter: patchCampaignsPredefinedComplexFilter,
} = createComplexFilterOptionActions(
  'campaigns',
  CAMPAIGNS_COMPLEX_FILTER_OPTIONS,
  CAMPAIGNS_PREDEFINED_COMPLEX_FILTERS,
  CAMPAIGNS_PREDEFINED_COMPLEX_FILTER,
);

export {
  getCampaignsComplexFilterOptions,
  getCampaignsPredefinedComplexFilters,
  postCampaignsPredefinedComplexFilter,
  patchCampaignsPredefinedComplexFilter,
};
