import { IGenericDataState } from 'Redux/Reducers/types';

export const STRING = 'String';
export const INTEGER = 'Integer';
export const DATETIME = 'DateTime';
export const ARRAY = 'Array';
export const BOOLEAN = 'Boolean';
export const OBJECT = 'Object';
const filterAllowedDataTypes = [STRING, INTEGER, DATETIME, ARRAY, BOOLEAN, OBJECT] as const;
export type AllowedDataType = typeof filterAllowedDataTypes[number];

const dataTypes = ['tag', 'country', 'source'] as const;
export type ComplexFilterSelectorDataType = typeof dataTypes[number];

export type FilterCriteria = {
  displayName: string,
  criteria: string,
};

export type ComplexFilterSelectorOption = {
  id: string,
  value: string,
};

export type IComplexFilterChoiceType = {
  choice: string,
};

export type ContentType = {
  appLabel: string,
  id: number,
  model: string,
};

export type ComplexFilterOption = {
  id: string,
  displayName: string,
  key: string,
  criteria: FilterCriteria[],
  allowedDataType: AllowedDataType,
  choiceRestrictions?: IComplexFilterChoiceType[], // Used to present the options directly
  keyContentType: ContentType,
  keyRelationalContentType: ContentType,
  // dataUrl?: string, // Used to receive data from the server
  // dataType?: ComplexFilterSelectorDataType, // Used to receive data from redux state
};

export interface IComplexFilterReducerState extends IGenericDataState<ComplexFilterOption> {
  valueOptions: {
    [key: string]: ComplexFilterSelectorOption[],
  }
};

export type ComplexFilterValueOptionsActionPayloadType = {
  key: string,
  data: ComplexFilterSelectorOption[],
}

// ComplexFilter Actions Types
export const COMPLEX_FILTER_LOADING = 'COMPLEX_FILTER_LOADING';
interface IComplexFilterListLoading {
  type: typeof COMPLEX_FILTER_LOADING,
  branch: string,
  payload?: never,
}

export const COMPLEX_FILTER_SUCCESS = 'COMPLEX_FILTER_SUCCESS';
interface IComplexFilterListSuccess {
  type: typeof COMPLEX_FILTER_SUCCESS,
  branch: string,
  payload: ComplexFilterOption[],
}

export const COMPLEX_FILTER_FAIL = 'COMPLEX_FILTER_FAIL';
interface IComplexFilterListFail {
  type: typeof COMPLEX_FILTER_FAIL,
  branch: string,
  payload?: never,
}

export const COMPLEX_FILTER_VALUE_OPTION_LOADING = 'COMPLEX_FILTER_VALUE_OPTION_LOADING';
interface IComplexFilterValueOptionListLoading {
  type: typeof COMPLEX_FILTER_VALUE_OPTION_LOADING,
  branch: string,
  payload?: never,
}

export const COMPLEX_FILTER_VALUE_OPTION_SUCCESS = 'COMPLEX_FILTER_VALUE_OPTION_SUCCESS';
interface IComplexFilterValueOptionListSuccess {
  type: typeof COMPLEX_FILTER_VALUE_OPTION_SUCCESS,
  branch: string,
  payload: ComplexFilterValueOptionsActionPayloadType,
}

export const COMPLEX_FILTER_VALUE_OPTION_FAIL = 'COMPLEX_FILTER_VALUE_OPTION_FAIL';
interface IComplexFilterValueOptionListFail {
  type: typeof COMPLEX_FILTER_VALUE_OPTION_FAIL,
  branch: string,
  payload?: never,
}

export type ComplexFilterListAction = IComplexFilterListLoading |
  IComplexFilterListSuccess |
  IComplexFilterListFail |
  IComplexFilterValueOptionListLoading |
  IComplexFilterValueOptionListSuccess |
  IComplexFilterValueOptionListFail;
