import { createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore, PersistConfig } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';

import reducer from 'Redux/Reducers';
import { RootReducerState } from 'Redux/Reducers/types';
import WebService from 'Services/WebService/WebService';

const reduxStoreName = 'lat-eme-root';

const persistConfig: PersistConfig<RootReducerState> = {
  key: reduxStoreName,
  storage,
  whitelist: ['user', 'userOnboarding'],
  version: 1, // Any time the user state is updated the version number needs to increased
  debug: true,

  // When the user starts the app it is checked if the token is still valid
  stateReconciler: (inboundState, state, reducedState, config) => {
    const { accessToken } = inboundState.user.credentials;
    if (!accessToken) {
      return {
        ...state,
        user: {
          ...state.user,
          logoutUrl: inboundState.user.logoutUrl,
        },
      };
    }
    const isValidToken = WebService.shared().isValidToken(accessToken);
    return {
      ...state,
      user: {
        ...inboundState.user,
        credentials: {
          ...inboundState.user.credentials,
          isValidToken: isValidToken,
        },
      },
      userOnboarding: {
        ...state.userOnboarding,
        selectedContactDataSoruce: inboundState.userOnboarding?.selectedContactDataSoruce,
      }
    };
  }
};

const persistedReducer = persistReducer(persistConfig, reducer);

const composeEnhancer = composeWithDevTools({
  name: reduxStoreName,
});

export const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(thunk)));
export const persistor = persistStore(store);
