import { FC, useContext } from 'react';

import { IToastMessagePresenter } from 'Blocks/ToastMessagePresenter/types';
import ToastMessage from 'Blocks/ToastMessagePresenter/Components/ToastMessage';
import { ToastMessageContext } from 'CustomHooks/useToastMessage/useToastMessage';

const ToastMessagePresenter: FC<IToastMessagePresenter> = () => {
  const { messages } = useContext(ToastMessageContext);
  return (
    <>
      {messages.map((message, index) => <ToastMessage key={message.id} message={message} orderFromBottom={(messages.length - 1) - index} />)}
    </>
  );
};

export default ToastMessagePresenter;
