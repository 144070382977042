import { UserState } from 'Redux/Reducers/UserReducer/types';
import { ToastMessageProps } from 'Redux/Reducers/UIReducer/types';
import { Tag } from 'Redux/Reducers/TagReducer/types';
import { Source } from 'Redux/Reducers/SourceReducer/types';

// User action types
export const SET_USER = 'SET_USER';
interface ISetUser {
  type: typeof SET_USER,
  payload: Partial<UserState>,
}

export const RESET_LOGOUT_URL = 'RESET_LOGOUT_URL';
interface IResetLogoutUrl {
  type: typeof RESET_LOGOUT_URL,
  payload?: never,
}

export const SET_PRO_USER = 'SET_PRO_USER';
interface ISetProUser {
  type: typeof SET_PRO_USER,
  payload: Partial<UserState>,
}

export type UserActionType = ISetUser | IResetLogoutUrl | ISetProUser;

// UI action types
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';
interface ISetToastMessage {
  type: typeof SET_TOAST_MESSAGE,
  payload: ToastMessageProps,
}

export const REMOVE_TOAST_MESSAGE = 'REMOVE_TOAST_MESSAGE';
interface IRemoveToastMessage {
  type: typeof REMOVE_TOAST_MESSAGE,
  payload: string,
}

export const OPEN_SECONDARY_MENU = 'OPEN_SECONDARY_MENU';
interface IOpenSecondaryMenu {
  type: typeof OPEN_SECONDARY_MENU,
  payload?: never,
}

export const CLOSE_SECONDARY_MENU = 'CLOSE_SECONDARY_MENU';
interface ICloseSecondaryMenu {
  type: typeof CLOSE_SECONDARY_MENU,
  payload?: never,
}

export type UIActionType = ISetToastMessage |
  IRemoveToastMessage |
  IOpenSecondaryMenu |
  ICloseSecondaryMenu;

// Tag action types
export const TAG_LIST_LOADING = 'TAG_LIST_LOADING';
interface ITagListLoading {
  type: typeof TAG_LIST_LOADING,
  payload?: never,
}

export const TAG_LIST_SUCCESS = 'TAG_LIST_SUCCESS';
interface ITagListSuccess {
  type: typeof TAG_LIST_SUCCESS,
  payload: Tag[],
}

export const TAG_LIST_FAIL = 'TAG_LIST_FAIL';
interface ITagListFail {
  type: typeof TAG_LIST_FAIL,
  payload?: never,
}

export const NEW_TAG_LOADING = 'NEW_TAG_LOADING';
interface INewTagLoading {
  type: typeof NEW_TAG_LOADING,
  payload?: never,
}

export const NEW_TAG_SUCCESS = 'NEW_TAG_SUCCESS';
interface INewTagSuccess {
  type: typeof NEW_TAG_SUCCESS,
  payload: Tag,
}

export const NEW_TAG_FAIL = 'NEW_TAG_FAIL';
interface INewTagFail {
  type: typeof NEW_TAG_FAIL,
  payload?: never,
}

export const EDIT_TAG_LOADING = 'EDIT_TAG_LOADING';
interface IEditTagLoading {
  type: typeof EDIT_TAG_LOADING,
  payload?: never,
}

export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS';
interface IEditTagSuccess {
  type: typeof EDIT_TAG_SUCCESS,
  payload: Tag,
}

export const EDIT_TAG_FAIL = 'EDIT_TAG_FAIL';
interface IEditTagFail {
  type: typeof EDIT_TAG_FAIL,
  payload?: never,
}

export const DELETE_TAG_LOADING = 'DELETE_TAG_LOADING';
interface IDeleteTagLoading {
  type: typeof DELETE_TAG_LOADING,
  payload?: never,
}

export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
interface IDeleteTagSuccess {
  type: typeof DELETE_TAG_SUCCESS,
  payload: string,
}

export const DELETE_TAG_FAIL = 'DELETE_TAG_FAIL';
interface IDeleteTagFail {
  type: typeof DELETE_TAG_FAIL,
  payload?: never,
}

export const RESET_LAST_ADDED_TAG = 'RESET_LAST_ADDED_TAG';
interface IResetLastAddedTag {
  type: typeof RESET_LAST_ADDED_TAG,
  payload?: never,
}

export type TagListAction = ITagListLoading |
  ITagListSuccess |
  ITagListFail |
  INewTagLoading |
  INewTagSuccess |
  INewTagFail |
  IEditTagLoading |
  IEditTagSuccess |
  IEditTagFail |
  IDeleteTagLoading |
  IDeleteTagSuccess |
  IDeleteTagFail |
  IResetLastAddedTag;

// Source type action types
export const SOURCE_LIST_LOADING = 'SOURCE_LIST_LOADING';
interface ISourceListLoading {
  type: typeof SOURCE_LIST_LOADING,
  payload?: never,
}

export const SOURCE_LIST_SUCCESS = 'SOURCE_LIST_SUCCESS';
interface ISourceListSuccess {
  type: typeof SOURCE_LIST_SUCCESS,
  payload: Source[],
}

export const SOURCE_LIST_FAIL = 'SOURCE_LIST_FAIL';
interface ISourceListFail {
  type: typeof SOURCE_LIST_FAIL,
  payload?: never,
}

export type SourceListAction = ISourceListLoading | ISourceListSuccess | ISourceListFail;

// Segment Source type action types
export const SEGMENT_SOURCE_LIST_LOADING = 'SEGMENT_SOURCE_LIST_LOADING';
interface ISegmentSourceListLoading {
  type: typeof SEGMENT_SOURCE_LIST_LOADING,
  payload?: never,
}

export const SEGMENT_SOURCE_LIST_SUCCESS = 'SEGMENT_SOURCE_LIST_SUCCESS';
interface ISegmentSourceListSuccess {
  type: typeof SEGMENT_SOURCE_LIST_SUCCESS,
  payload: Source[],
}

export const SEGMENT_SOURCE_LIST_FAIL = 'SEGMENT_SOURCE_LIST_FAIL';
interface ISegmentSourceListFail {
  type: typeof SEGMENT_SOURCE_LIST_FAIL,
  payload?: never,
}

export type SegmentSourceListAction = ISegmentSourceListLoading | ISegmentSourceListSuccess | ISegmentSourceListFail;

// Segment Source type action types
export const AVAILABLE_COMPONENTS_LOADING = 'AVAILABLE_COMPONENTS_LOADING';
interface IAvailableComponentsLoading {
  type: typeof AVAILABLE_COMPONENTS_LOADING,
  payload?: never,
}

export const AVAILABLE_COMPONENTS_SUCCESS = 'AVAILABLE_COMPONENTS_SUCCESS';
interface IAvailableComponentsSuccess {
  type: typeof AVAILABLE_COMPONENTS_SUCCESS,
  payload: [],
}

export const AVAILABLE_COMPONENTS_FAIL = 'AVAILABLE_COMPONENTS_FAIL';
interface IAvailableComponentsFail {
  type: typeof AVAILABLE_COMPONENTS_FAIL,
  payload?: never,
}

export type AvailableComponentsAction = IAvailableComponentsLoading | IAvailableComponentsSuccess | IAvailableComponentsFail;

// Generic status types
export const DEFAULT = 'DEFAULT';
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';

export const CREATE_LOADING = 'CREATE_LOADING';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';

export const EDIT_LOADING = 'EDIT_LOADING';
export const EDIT_SUCCESS = 'EDIT_SUCCESS';
export const EDIT_FAIL = 'EDIT_FAIL';

export const DELETE_LOADING = 'DELETE_LOADING';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAIL = 'DELETE_FAIL';

export const SYNC_LOADING = 'SYNC_LOADING';
export const SYNC_SUCCESS = 'SYNC_SUCCESS';
export const SYNC_FAIL = 'SYNC_FAIL';

export type GenericStatus = typeof DEFAULT | 
  typeof LOADING | 
  typeof SUCCESS | 
  typeof FAIL |
  typeof CREATE_LOADING |
  typeof CREATE_SUCCESS |
  typeof CREATE_FAIL |
  typeof EDIT_LOADING |
  typeof EDIT_SUCCESS |
  typeof EDIT_FAIL |
  typeof DELETE_LOADING |
  typeof DELETE_SUCCESS |
  typeof DELETE_FAIL |
  typeof SYNC_LOADING |
  typeof SYNC_SUCCESS |
  typeof SYNC_FAIL;
