import { useCallback, FC } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Search, Clear } from '@mui/icons-material';
import { ISearchField } from 'Components/SearchField/types';
import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import * as translationConstants from 'Constants/TranslationConstants';

const SearchField: FC<ISearchField> = ({
  value,
  onChange,
  name,
  clearable,
  className,
}) => {
  const handleChange = useCallback((e) => {
    onChange({ value: e.target.value }, name);
  }, [name, onChange]);

  const handleClear = useCallback(() => {
    onChange({ id: undefined, value: '' }, name);
  }, [name, onChange]);

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            style={{
              marginTop: '0px', // Needed to position search icon to the vertical center
            }}
          >
            <Search color="inherit"/>
          </InputAdornment>
        ),
        endAdornment: clearable && value && value.length > 0  ? (
          <IconButton
            style={{
              marginTop: '0px', // Needed to position search icon to the vertical center
            }}
            onClick={handleClear}
            size="small"
          >
            <Clear color="inherit"/>
          </IconButton>
        ) : null,
        disableUnderline: true,
      }}
      placeholder={useLocalized(translationConstants.search)}
      value={value}
      onChange={handleChange}
      variant="filled"
      className={`search-field-component ${className ? className : ''}`}
    />
  );
};

export default SearchField;
