class UtilityService {
  static getUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }

  static getRandomNumberBetween(number1: number, number2: number): number {
    return number1 + (number2 - number1) * Math.random();
  }

  // converts text separated with dots to camelcase
  static convertDottedTextToCamelCase(text: string): string {
    const camelisedTextArray: string[] = [];
    const textChunks = text.split('.');
    textChunks.forEach((value, index) => {
      camelisedTextArray.push(index === 0 ? value : this.capitalizeFirstLetter(value));
    });

    return camelisedTextArray.join('');
  }

  static getFirstPartOfDottedText(text: string): string {
    const textChunks = text.split('.');
    return textChunks[0];
  }

  static camelToSnakeCase(text: string): string {
    return text.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  static capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static getUrlParam(urlString: string | null, param: string): string | null {
    if (urlString === null) {
      return null;
    }
    const url = new URL(urlString);
    return url.searchParams.get(param);
  };

  static getFormattedNumberString(number: number): string {
    if (number >= 1000000) {
      const x = number/1000000;
      if (x % 1 !== 0) {
        return `${x.toFixed(1)}M`;  
      }
      return `${x}M`;
    }
    if (number >= 10000) {
      return `${Math.floor(number/1000)}K`;
    }
    return this.getLocalizedNumberString(number);
  }

  static getLocalizedNumberString(number: number | undefined): string {
    if (typeof number !== 'number') {
      return "-";
    }
    // TODO: it is hardcoded now, it can be received from the redux store later when multi language support will be needed.
    return number.toLocaleString('en-EN', { maximumFractionDigits: 2 });
  }

  static getLocalizedNumberTwoDigitsString(number: number | undefined): string {
    if (typeof number !== 'number') {
      return "-";
    }
    // TODO: it is hardcoded now, it can be received from the redux store later when multi language support will be needed.
    return number.toLocaleString('en-EN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  
  static getLocalizedRoundNumber(number: number | undefined): string {
    if (typeof number !== 'number') {
      return "-";
    }
    // TODO: it is hardcoded now, it can be received from the redux store later when multi language support will be needed.
    return number.toLocaleString('en-EN', { maximumFractionDigits: 0 });
  }
}

export default UtilityService;
