import { ThunkAction } from 'redux-thunk';

import { SEGMENT_SOURCE_LIST } from 'Constants/WebservicePaths';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import WebService  from 'Services/WebService/WebService';
import { IError } from 'Redux/Reducers/types';
import {
  SegmentSourceListAction,
  SEGMENT_SOURCE_LIST_LOADING,
  SEGMENT_SOURCE_LIST_SUCCESS,
  SEGMENT_SOURCE_LIST_FAIL,
} from 'Redux/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { Source } from 'Redux/Reducers/SourceReducer/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, SegmentSourceListAction>;

export const getSegmentSourceList = (success: (sources: Source[]) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: SEGMENT_SOURCE_LIST_LOADING,
  });
  try {
    const option = new WebServiceOption('GET', SEGMENT_SOURCE_LIST);
    const response = await WebService.shared().callWebService(option) as Source[];
    dispatch({
      type: SEGMENT_SOURCE_LIST_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: SEGMENT_SOURCE_LIST_FAIL,
    });
    fail(error as IError);
  };
};
