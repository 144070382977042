import { IBizmailListState, BizmailListElement} from 'Modules/EmailManagement/Redux/Reducers/BizmailListReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  BIZMAIL_LIST_LOADING,
  BIZMAIL_LIST_SUCCESS,
  BIZMAIL_LIST_FAIL,
  BizmailListAction,
} from 'Modules/EmailManagement/Redux/types';

const initialState: IBizmailListState = {
  status: DEFAULT,
  data: [],
};

const bizmailListReducer = (state = initialState, action: BizmailListAction): IBizmailListState => {
  const { type, payload } = action;
  switch (type) {
    case BIZMAIL_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case BIZMAIL_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as BizmailListElement[],
      };
    case BIZMAIL_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default bizmailListReducer;
