import { IContactState, IContactFilter, Contact, IHistoryState } from 'Modules/EmailManagement/Redux/Reducers/ContactReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
  CREATE_LOADING,
  CREATE_SUCCESS,
  CREATE_FAIL,
  EDIT_LOADING,
  EDIT_SUCCESS,
  EDIT_FAIL,
  DELETE_LOADING,
  DELETE_SUCCESS,
  DELETE_FAIL,
  SYNC_LOADING,
  SYNC_SUCCESS,
  SYNC_FAIL,
} from 'Redux/types';
import {
  ContactListAction,
  SET_CONTACT_FILTER,
  SET_CONTACT_COMPLEX_FILTER,
  RESET_CONTACT_COMPLEX_FILTER,
  SET_CONTACT_SORTING_CRITERIA,
  CONTACT_LIST_LOADING,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAIL,
  CREATE_CONTACT_LOADING,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAIL,
  UPLOAD_CONTACTS_LOADING,
  UPLOAD_CONTACTS_SUCCESS,
  UPLOAD_CONTACTS_FAIL,
  EDIT_CONTACT_LOADING,
  EDIT_CONTACT_SUCCESS,
  EDIT_CONTACT_DATA_SUCCESS,
  EDIT_CONTACT_FAIL,
  DELETE_CONTACT_LOADING,
  DELETE_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  CONTACT_BY_ID_LOADING,
  CONTACT_BY_ID_SUCCESS,
  CONTACT_BY_ID_FAIL,
  CONTACT_HISTORY_BY_ID_LOADING,
  CONTACT_HISTORY_BY_ID_SUCCESS,
  CONTACT_HISTORY_BY_ID_FAIL,
  SYNC_WITH_SOURCE_LOADING,
  SYNC_WITH_SOURCE_SUCCESS,
  SYNC_WITH_SOURCE_FAIL,
  SET_CONTACT_HISTORY_BY_ID,
  EDIT_TAGS_ON_CONTACTS_LOADING,
  EDIT_TAGS_ON_CONTACTS_SUCCESS,
  EDIT_TAGS_ON_CONTACTS_FAIL,
  SET_SELECTED_CONTACTS,
  SET_CONTACT_PAGINATION_DATA,
  SET_TAGGED_CONTACTS,
  SET_CONTACT_ITEM_COUNT,
  ContactHistoryById,
  EditDataSuccess,
} from 'Modules/EmailManagement/Redux/types';
import ContactService from 'Modules/EmailManagement/Services/ContactService/ContactService';
import SortingService from 'Services/SortingService/SortingService';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { SelectionActionObject } from 'CustomHooks/useSelection/types';
import { PAGINATION_DEFAULT_ROW_NUMBER } from 'Constants/Options';

const initialState: IContactState = {
  paginationData: {
    previous: null,
    next: null,
    cursor: null,
    limit: PAGINATION_DEFAULT_ROW_NUMBER,
    page: 1,
  },
  status: DEFAULT,
  data: [],
  selectedIds: [],
  selectedAll: false,
  contactCount: null,
  fixContactCount: null,
  taggedContactIds: [],
  sortingCriteria: {
    key: 'createdAt',
    order: 'ASCENDING',
    dataType: 'DATE',
  },
  filter: {
    searchText: {
      value: '',
      appliedOn: ['email', 'lastName', 'firstName'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    predefinedFilterId: {
      value: '',
      appliedOn: ['predefinedFilterId'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
  },
  complexFilter: {
    name: '',
    filterSet: [],
  },
};

const getPaginationInfo = (oldPaginationInfo: PaginationInfo, newPaginationInfo: PaginationInfo): PaginationInfo => {
  if (newPaginationInfo.previous === null) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: 1,
    }
  }
  if (oldPaginationInfo.next === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page + 1,
    }
  }
  if (oldPaginationInfo.previous === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page - 1,
    }
  }
  return {
    ...oldPaginationInfo,
    ...newPaginationInfo,
    page: 1,
  }
};

const contactReducer = (state = initialState, action: ContactListAction): IContactState => {
  const { type, payload } = action;
  switch (type) {
    case SET_CONTACT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload as IContactFilter,
        },
        paginationData: {
          ...state.paginationData,
          cursor: null,
        },
        selectedIds: [],
        selectedAll: false,
      };
    case SET_CONTACT_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: payload as ComplexFilterSet,
      };
    case RESET_CONTACT_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: {
          name: '',
          filterSet: [],
        },
      }
    case SET_CONTACT_SORTING_CRITERIA:
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          cursor: null,
        },
        selectedIds: [],
        selectedAll: false,
        sortingCriteria: payload as SortingCriteria,
      };
    case SET_CONTACT_PAGINATION_DATA:
      return {
        ...state,
        selectedAll: false,
        paginationData: {
          ...state.paginationData,
          ...payload as PaginationInfo,
        },
      };
    case CONTACT_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CONTACT_LIST_SUCCESS:
      const sortedData = SortingService.getSortedArray((payload as Partial<IContactState>).data!, state.sortingCriteria);
      return {
        ...state,
        data: sortedData,
        paginationData: getPaginationInfo(state.paginationData, (payload as Partial<IContactState>).paginationData!),
        status: SUCCESS,
      };
    case CONTACT_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case CONTACT_BY_ID_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case CONTACT_BY_ID_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: (new ContactService()).getMergedContacts(state.data, [payload as Contact]),
      };
      case CONTACT_BY_ID_FAIL:
    return {
        ...state,
        status: FAIL,
      };
    case CREATE_CONTACT_LOADING:
      return {
        ...state,
        status: CREATE_LOADING,
      };
    case CREATE_CONTACT_SUCCESS:
      return {
        ...state,
        status: CREATE_SUCCESS,
        data: (new ContactService()).getMergedContacts(state.data, [payload as Contact]),
      };
    case CREATE_CONTACT_FAIL:
      return {
        ...state,
        status: CREATE_FAIL,
      };
    case UPLOAD_CONTACTS_LOADING:
      return {
        ...state,
        status: CREATE_LOADING,
      }
    case UPLOAD_CONTACTS_SUCCESS:
      return {
        ...state,
        status: CREATE_SUCCESS,
      }
    case UPLOAD_CONTACTS_FAIL:
      return {
        ...state,
        status: CREATE_FAIL,
      }
    case EDIT_CONTACT_LOADING:
      return {
        ...state,
        status: EDIT_LOADING,
      };
    case EDIT_CONTACT_SUCCESS:
      return {
        ...state,
        status: EDIT_SUCCESS,
        data: (new ContactService()).getMergedContacts(state.data, [payload as Contact]),
      };
    case EDIT_CONTACT_DATA_SUCCESS:
      return {
        ...state,
        status: EDIT_SUCCESS,
        data: (new ContactService()).getContactsWithContactData((payload as EditDataSuccess).contactId, (payload as EditDataSuccess).data, state.data),
      };
    case EDIT_CONTACT_FAIL:
      return {
        ...state,
        status: EDIT_FAIL,
      };
    case EDIT_TAGS_ON_CONTACTS_LOADING:
      return {
        ...state,
        status: EDIT_LOADING,
      }
    case EDIT_TAGS_ON_CONTACTS_SUCCESS:
      return {
        ...state,
        status: EDIT_SUCCESS,
        data: (new ContactService()).getMergedContacts(state.data, payload as Contact[]),
      }
    case EDIT_TAGS_ON_CONTACTS_FAIL:
      return {
        ...state,
        status: EDIT_FAIL,
      }
    case DELETE_CONTACT_LOADING:
      return {
        ...state,
        status: DELETE_LOADING,
      }
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        selectedIds: [],
        status: DELETE_SUCCESS,
        data: (new ContactService()).removeContacts(payload as string[], state.data),
      }
    case DELETE_CONTACT_FAIL:
      return {
        ...state,
        status: DELETE_FAIL,
      }
    case CONTACT_HISTORY_BY_ID_LOADING:
      return {
        ...state,
        data: (new ContactService()).getContactsWithContactHistoryState((payload as ContactHistoryById).contactId, (payload as ContactHistoryById).history as IHistoryState, state.data),
      };
    case CONTACT_HISTORY_BY_ID_SUCCESS:
      return {
        ...state,
        data: (new ContactService()).getContactsWithContactHistoryState((payload as ContactHistoryById).contactId, (payload as ContactHistoryById).history as IHistoryState, state.data),
      };
    case CONTACT_HISTORY_BY_ID_FAIL:
      return {
        ...state,
        data: (new ContactService()).getContactsWithContactHistoryState((payload as ContactHistoryById).contactId, (payload as ContactHistoryById).history as IHistoryState, state.data),
      };
    case SET_CONTACT_HISTORY_BY_ID:
      return {
        ...state,
        data: (new ContactService()).getContactsWithContactHistoryState((payload as ContactHistoryById).contactId, (payload as ContactHistoryById).history as IHistoryState, state.data),
      };
    case SYNC_WITH_SOURCE_LOADING:
      return {
        ...state,
        status: SYNC_LOADING,
      };
    case SYNC_WITH_SOURCE_SUCCESS:
      return {
        ...state,
        status: SYNC_SUCCESS,
      };
    case SYNC_WITH_SOURCE_FAIL:
      return {
        ...state,
        status: SYNC_FAIL,
      };
    case SET_SELECTED_CONTACTS:
      return {
        ...state,
        selectedIds: (payload as SelectionActionObject).ids,
        selectedAll: (payload as SelectionActionObject).selectedAll,
      };
    case SET_TAGGED_CONTACTS:
      return {
        ...state,
        taggedContactIds: payload as string[],
      };
    case SET_CONTACT_ITEM_COUNT:
      return {
        ...state,
        contactCount: payload as number,
        fixContactCount: state.fixContactCount === null ? payload as number : state.fixContactCount,
      }
    default:
      return state;
  }
};

export default contactReducer;
