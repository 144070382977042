import { ThunkAction } from 'redux-thunk';

import { RootReducerState, IError } from 'Redux/Reducers/types'
import {
  COMPLEX_FILTER_LOADING,
  COMPLEX_FILTER_SUCCESS,
  COMPLEX_FILTER_FAIL,
  COMPLEX_FILTER_VALUE_OPTION_LOADING,
  COMPLEX_FILTER_VALUE_OPTION_SUCCESS,
  COMPLEX_FILTER_VALUE_OPTION_FAIL,
  ComplexFilterListAction,
} from 'Builders/types';
import { PredefinedComplexFilter, PredefinedComplexFilterWithId } from 'CustomHooks/useComplexFilter/types';
import { ComplexFilterOption, ComplexFilterSelectorOption } from 'Builders/types';
import WebService from 'Services/WebService/WebService';
import WebServiceOption from 'Services/WebService/WebServiceOption';
import UtilityService from 'Services/UtilityService/UtilityService';
import { DeepPartial } from 'Services/UtilityService/UtilityTypes';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, ComplexFilterListAction>;

export type GetPredefinedComplexFiltersResponseType = {
  next: string | null,
  previous: string | null,
  results: PredefinedComplexFilterWithId[],
};

type PostPredefinedComplexFiltersFailResponseType = {
  errorDetails: {
    message: string,
    numberOfCalls: number,
    predefinedFilterId: string,
  },
};

const getComplexFilterOptionsWithConstructorOptions = (constructorBranch: string, contactFilterOptionsUrl: string) => (success: () => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  dispatch({
    type: COMPLEX_FILTER_LOADING,
    branch: constructorBranch,
  });

  try {
    const option = new WebServiceOption('GET', contactFilterOptionsUrl);
    const response = await WebService.shared().callWebService(option) as ComplexFilterOption[];
    dispatch({
      type: COMPLEX_FILTER_SUCCESS,
      branch: constructorBranch,
      payload: response,
    });
    success();
  } catch (error) {
    dispatch({
      type: COMPLEX_FILTER_FAIL,
      branch: constructorBranch,
    });
    fail(error as IError);
  };
};


const getPredefinedComplexFiltersWithConstructorOptions = (predefinedComplexFiltersUrl: string) => async (params: string, success: (response: GetPredefinedComplexFiltersResponseType) => void, fail: (error: IError) => void) => {
  try {
    const option = new WebServiceOption('GET', `${predefinedComplexFiltersUrl}?${params}`);
    const response = await WebService.shared().callWebService(option) as GetPredefinedComplexFiltersResponseType;
    success({
      next: UtilityService.getUrlParam(response.next, 'cursor'),
      previous: UtilityService.getUrlParam(response.previous, 'cursor'),
      results: response.results,
    });
  } catch (error) {
    fail(error as IError);
  }
};

const getPostPredefinedComplexFilterWithConstructorOptions = (predefinedComplexFilterUrl: string) => (complexFilterWsArray: PredefinedComplexFilter, success: (id: string, numberOfCalls?: number) => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  try {
    const option = new WebServiceOption('POST', predefinedComplexFilterUrl, complexFilterWsArray);
    const response = await WebService.shared().callWebService(option) as PredefinedComplexFilterWithId;
    success(response.id);
  } catch (error) {
    const errorObject = error as IError;
    
    if (errorObject.code === 1010 &&  errorObject.body) {
      const failResponseObject = errorObject.body as PostPredefinedComplexFiltersFailResponseType;
      success(failResponseObject.errorDetails.predefinedFilterId, failResponseObject.errorDetails.numberOfCalls);
      return;
    }
    
    fail(errorObject);
  };
};

const getPatchPredefinedComplexFilterWithConstructorOptions = (predefinedComplexFilterUrl: string) => (complexFilterId: string, complexFilterWsArray: DeepPartial<PredefinedComplexFilter>, success: () => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  try {
    const option = new WebServiceOption('PATCH', `${predefinedComplexFilterUrl}/${complexFilterId}`, complexFilterWsArray);
    await WebService.shared().callWebService(option) as PredefinedComplexFilterWithId;
    success();
  } catch (error) {
    const errorObject = error as IError;
    fail(errorObject);
  };
};

const getComplexFilterSelectorOptionForKeyAndUrlActionWithConstructorOptions = (constructorBranch: string) => (key: string, url: string, success: () => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  dispatch({
    type: COMPLEX_FILTER_VALUE_OPTION_LOADING,
    branch: constructorBranch,
  });
  try {
    const option = new WebServiceOption('GET', url);
    const response = await WebService.shared().callWebService(option) as ComplexFilterSelectorOption[];
    dispatch({
      type: COMPLEX_FILTER_VALUE_OPTION_SUCCESS,
      branch: constructorBranch,
      payload: {
        key,
        data: response,
      },
    });
    success();
  } catch (error) {
    dispatch({
      branch: constructorBranch,
      type: COMPLEX_FILTER_VALUE_OPTION_FAIL,
    });
    fail(error as IError);
  };
};

const createComplexFilterOptionActions = (constructorBranch: string, contactFilterOptionsUrl: string, predefinedComplexFiltersUrl: string, predefinedComplexFilterUrl: string) => ({
  getComplexFilterSelectorOptionForKeyAndUrl: getComplexFilterSelectorOptionForKeyAndUrlActionWithConstructorOptions(constructorBranch),
  getComplexFilterOptions: getComplexFilterOptionsWithConstructorOptions(constructorBranch, contactFilterOptionsUrl),
  getPredefinedComplexFilter: getPredefinedComplexFiltersWithConstructorOptions(predefinedComplexFiltersUrl),
  postPredefinedComplexFilter: getPostPredefinedComplexFilterWithConstructorOptions(predefinedComplexFilterUrl),
  patchPredefinedComplexFilter: getPatchPredefinedComplexFilterWithConstructorOptions(predefinedComplexFilterUrl),
});

export default createComplexFilterOptionActions;
