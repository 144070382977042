import {
  FC,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import { IAlertDialogue } from 'Components/Dialogs/type';
import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import { cancel } from 'Constants/TranslationConstants';
import { DialogContext } from 'CustomHooks/useDialog/useDialog';

const AlertDialog: FC<IAlertDialogue> = ({
  dialogueProps: {
    alertDialogProps,
  },
}) => {
  const { closeDialogue } = useContext(DialogContext);

  // This logic is required to have the smooth closing animation provided by material UI
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        closeDialogue();
      }, 200);
    }
  }, [closeDialogue, open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      {alertDialogProps?.title && (
        <DialogTitle>
          {alertDialogProps.title}
        </DialogTitle>
      )}
      {alertDialogProps?.description && (
        <DialogContent className="alert-dialog-content">
          {alertDialogProps.description}
        </DialogContent>
      )}
      <DialogActions>
        {alertDialogProps?.actions && alertDialogProps.actions.map(action => (
          <Button
            key={action.name}
            onClick={() => {
              handleClose()
              setTimeout(() => {
                action.action();

                // Higher timeout is needed to let time for dialogs state updates, if the Alert dialog is a prevent going away dialog
              }, 300);
            }}
            variant="contained"
            color="primary"
          >
            {action.name}
          </Button>  
        ))}
        <Button onClick={handleClose}>
          {useLocalized(cancel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
