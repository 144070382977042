import { ThunkAction } from 'redux-thunk';

import WebService  from 'Services/WebService/WebService';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import {
  FunnelReportListActionType,
  FUNNEL_REPORT_LIST_LOADING,
  FUNNEL_REPORT_LIST_SUCCESS,
  FUNNEL_REPORT_LIST_FAIL,
  SET_FUNNEL_REPORT_LIST_FILTER,
  SET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
  RESET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
  SET_FUNNEL_REPORT_LIST_SORTING_CRITERIA,
} from 'Modules/Reports/Redux/types';
import { IFunnelReportFilter, FunnelReport, SiteReport } from 'Modules/Reports/Redux/Reducers/FunnelAnalysisReducer/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { IError } from 'Redux/Reducers/types';
import { FUNNEL_REPORT } from 'Modules/Reports/Constants/WebservicePaths';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, FunnelReportListActionType>;

type GetFunnelReportListActionType = {
  siteReports: SiteReport,
  channelReports: FunnelReport[],
};

export const resetFunnelReportComplexFilter = (): FunnelReportListActionType => ({
  type: RESET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
});

export const setFunnelReportFilter = (filter: Partial<IFunnelReportFilter>): FunnelReportListActionType => ({
  type: SET_FUNNEL_REPORT_LIST_FILTER,
  payload: filter,
});

export const setFunnelReportComplexFilter = (filter: ComplexFilterSet): FunnelReportListActionType => ({
  type: SET_FUNNEL_REPORT_LIST_COMPLEX_FILTER,
  payload: filter,
});

export const setFunnelReportSortingCriteria = (sortingCriteria: SortingCriteria) => ({
  type: SET_FUNNEL_REPORT_LIST_SORTING_CRITERIA,
  payload: sortingCriteria,
});

export const getFunnelReportList = (params: string, success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: FUNNEL_REPORT_LIST_LOADING,
  });

  try {
    const option = new WebServiceOption('GET', `${FUNNEL_REPORT}?${params}`);
    const response = await WebService.shared().callWebService(option) as GetFunnelReportListActionType;

    dispatch({
      type: FUNNEL_REPORT_LIST_SUCCESS,
      payload: {
        data: response.channelReports,
        siteReports: response.siteReports,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: FUNNEL_REPORT_LIST_FAIL,
    });
    fail(error as IError);
  };
};
