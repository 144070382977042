import {
  CONTACT_COMPLEX_FILTER,
  CONTACT_PREDEFINED_COMPLEX_FILTERS,
  CONTACT_COMPLEX_FILTER_OPTIONS,
  SEGMENT_COMPLEX_FILTER,
  SEGMENT_COMPLEX_FILTERS,
} from 'Constants/WebservicePaths';

import createComplexFilterOptionActions from 'Builders/ComplexFilterActionsBuilder';

const {
  getComplexFilterSelectorOptionForKeyAndUrl,
  getComplexFilterOptions,
  getPredefinedComplexFilter: getContactPredefinedComplexFilters,
  postPredefinedComplexFilter: postContactPredefinedComplexFilter,
  patchPredefinedComplexFilter: patchContactPredefinedComplexFilter,
} = createComplexFilterOptionActions(
  'contacts',
  CONTACT_COMPLEX_FILTER_OPTIONS,
  CONTACT_PREDEFINED_COMPLEX_FILTERS,
  CONTACT_COMPLEX_FILTER,
);

const {
  getPredefinedComplexFilter: getSegmentPredefinedComplexFilters,
  postPredefinedComplexFilter: postSegmentPredefinedComplexFilter,
  patchPredefinedComplexFilter: patchSegmentPredefinedComplexFilter,
} = createComplexFilterOptionActions(
  'segments',
  CONTACT_COMPLEX_FILTER_OPTIONS,
  SEGMENT_COMPLEX_FILTERS,
  SEGMENT_COMPLEX_FILTER,
)

export {
  getComplexFilterSelectorOptionForKeyAndUrl,
  getComplexFilterOptions,
  getContactPredefinedComplexFilters,
  postContactPredefinedComplexFilter,
  patchContactPredefinedComplexFilter,
  getSegmentPredefinedComplexFilters,
  postSegmentPredefinedComplexFilter,
  patchSegmentPredefinedComplexFilter,
};
