import { ThunkAction } from 'redux-thunk';

import { FUNNEL_REPORT_CHANNEL } from 'Modules/Reports/Constants/WebservicePaths';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import WebService  from 'Services/WebService/WebService';
import { IError } from 'Redux/Reducers/types';
import {
  CHANNEL_LIST_LOADING,
  CHANNEL_LIST_SUCCESS,
  CHANNEL_LIST_FAIL,
  ChannelListAction,
} from 'Modules/Reports/Redux/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { Channel } from 'Modules/Reports/Redux/Reducers/ChannelReducer/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, ChannelListAction>;

export const getChannelList = (success: (channels: Channel[]) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: CHANNEL_LIST_LOADING,
  });
  try {
    const option = new WebServiceOption('GET', FUNNEL_REPORT_CHANNEL);
    const response = await WebService.shared().callWebService(option) as Channel[];
    dispatch({
      type: CHANNEL_LIST_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: CHANNEL_LIST_FAIL,
    });
    fail(error as IError);
  };
};
