import { ThunkAction } from 'redux-thunk';

import WebServiceOption  from 'Services/WebService/WebServiceOption';
import WebService  from 'Services/WebService/WebService';
import UtilityService from 'Services/UtilityService/UtilityService';
import { IError } from 'Redux/Reducers/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { SelectionActionObject } from 'CustomHooks/useSelection/types';

import { SEGMENT_LIST_RESET, SegmentListAction } from 'Modules/Marketing/Redux/types';
import {
  SET_SEGMENT_FILTER,
  SEGMENT_LIST_LOADING,
  SEGMENT_LIST_SUCCESS,
  SEGMENT_LIST_FAIL,
  DELETE_SEGMENT_LOADING,
  DELETE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_FAIL,
  SET_SELECTED_SEGMENTS,
  SET_SEGMENT_PAGINATION_DATA,
  SET_SEGMENT_SORTING_CRITERIA,
  PUBLISH_SEGMENT_LOADING,
  PUBLISH_SEGMENT_SUCCESS,
  PUBLISH_SEGMENT_FAIL,
} from 'Modules/Marketing/Redux/types';
import { SEGMENT_LIST, SEGMENT_LIST_PUBLISH_FACEBOOK, SEGMENT_LIST_PUBLISH_GOOGLE, SEGMENT_LIST_PUBLISH_MAILCHIMP } from 'Modules/Marketing/Constants/WebservicePaths';
import { Segment, ISegmentFilter } from 'Modules/Marketing/Redux/Reducers/SegmentsReducer/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, SegmentListAction>;

type GetSegmentListResponseType = {
  next: string | null,
  previous: string | null,
  results: Segment[],
};

export const setSegmentFilter = (sourceId: string, filter: Partial<ISegmentFilter>): SegmentListAction => ({
  type: SET_SEGMENT_FILTER,
  payload: {
    filter,
    sourceId,
  },
});

export const setSegmentSelection = (sourceId: string, actionObject: SelectionActionObject): SegmentListAction => ({
  type: SET_SELECTED_SEGMENTS,
  payload: {
    sourceId,
    actionObject,
  },
});

export const setSegmentPagination = (sourceId: string, paginationInfo: Partial<PaginationInfo>): SegmentListAction => ({
  type: SET_SEGMENT_PAGINATION_DATA,
  payload: {
    sourceId,
    paginationInfo,
  },
});

export const setSegmentSortingCriteria = (sourceId: string, sortingCriteria: SortingCriteria): SegmentListAction => ({
  type: SET_SEGMENT_SORTING_CRITERIA,
  payload: {
    sourceId,
    sortingCriteria,
  },
});

export const resetSegmentList = (sourceId: string) => ({
  type: SEGMENT_LIST_RESET,
  payload: {
    sourceId,
    state: {
      data: {},
    }
  }
});

export const getSegmentList = (sourceId: string, params: string, success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: SEGMENT_LIST_LOADING,
    payload: {
      sourceId,
    }
  });

  try {
    const option = new WebServiceOption('GET', `${SEGMENT_LIST}?${params}&sourceId=${sourceId}`);
    const response = await WebService.shared().callWebService(option) as GetSegmentListResponseType;

    dispatch({
      type: SEGMENT_LIST_SUCCESS,
      payload: {
        sourceId,
        state: {
          paginationData: {
            next: UtilityService.getUrlParam(response.next, 'cursor'),
            previous: UtilityService.getUrlParam(response.previous, 'cursor'),
          },
          data: response.results,
        }
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: SEGMENT_LIST_FAIL,
      payload: {
        sourceId,
      }
    });
    fail(error as IError);
  };
};

export const deleteSegment = (segmentId: string, sourceId: string, success: () => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  dispatch({
    type: DELETE_SEGMENT_LOADING,
    payload: {
      sourceId,
    },
  });
  try {
    const option = new WebServiceOption('DELETE', `${SEGMENT_LIST}/${segmentId}`);
    await WebService.shared().callWebService(option) as Segment;
    dispatch({
      type: DELETE_SEGMENT_SUCCESS,
      payload: {
        sourceId,
        segmentId,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: DELETE_SEGMENT_FAIL,
      payload: {
        sourceId,
      },
    });
    fail(error as IError);
  }
};

export const publishFacebookSegment = (segmentId: string, sourceId: string, success: () => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  dispatch({
    type: PUBLISH_SEGMENT_LOADING,
    payload: {
      sourceId,
    },
  });
  try {
    const option = new WebServiceOption('POST', `${SEGMENT_LIST_PUBLISH_FACEBOOK}`, { segmentId });
    await WebService.shared().callWebService(option) as Segment;
    dispatch({
      type: PUBLISH_SEGMENT_SUCCESS,
      payload: {
        sourceId,
        segmentId,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: PUBLISH_SEGMENT_FAIL,
      payload: {
        sourceId,
      },
    });
    fail(error as IError);
  }
};

export const publishGoogleSegment = (segmentId: string, googleAdsAccountId: string, sourceId: string, success: () => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  dispatch({
    type: PUBLISH_SEGMENT_LOADING,
    payload: {
      sourceId,
    },
  });
  try {
    const option = new WebServiceOption('POST', `${SEGMENT_LIST_PUBLISH_GOOGLE}`, { segmentId, googleAdsAccountId });
    await WebService.shared().callWebService(option) as Segment;
    dispatch({
      type: PUBLISH_SEGMENT_SUCCESS,
      payload: {
        sourceId,
        segmentId,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: PUBLISH_SEGMENT_FAIL,
      payload: {
        sourceId,
      },
    });
    fail(error as IError);
  }
};

export const publishMailchimpSegment = (segmentId: string, mailchimpAccountId: string, sourceId: string, success: () => void, fail: (error: IError) => void): ThunkActionType => async dispatch => {
  dispatch({
    type: PUBLISH_SEGMENT_LOADING,
    payload: {
      sourceId,
    },
  });
  try {
    const option = new WebServiceOption('POST', `${SEGMENT_LIST_PUBLISH_MAILCHIMP}`, { segmentId, mailchimpAccountId });
    await WebService.shared().callWebService(option) as Segment;
    dispatch({
      type: PUBLISH_SEGMENT_SUCCESS,
      payload: {
        sourceId,
        segmentId,
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: PUBLISH_SEGMENT_FAIL,
      payload: {
        sourceId,
      },
    });
    fail(error as IError);
  }
};
