import { FC, useContext } from 'react';

import { DialogContext } from 'CustomHooks/useDialog/useDialog';
import { DialogueProps, HALF_PAGE_DIALOGUE, ALERT_DIALOGUE, POP_UP_DIALOGUE, SYNC_DIALOGUE, ACTIVATE_DIALOGUE, GROUP_DIALOGUE } from 'CustomHooks/useDialog/types';
import HalfPageDialogue from 'Components/Dialogs/HalfPageDialogue';
import AlertDialog from 'Components/Dialogs/AlertDialog';
import PopUpDialog from 'Components/Dialogs/PopUpDialog';
import SyncDialog from 'Components/Dialogs/SyncDialog';
import GroupDialog from 'Components/Dialogs/GroupDialog';
import ActivateDialog from 'Components/Dialogs/ActivateDialog';

const renderDialogue = (dialogue: DialogueProps): JSX.Element => {
  switch (dialogue.type) {
    case HALF_PAGE_DIALOGUE:
      return <HalfPageDialogue key={dialogue.modalBodyId} dialogueProps={dialogue} />;
    
    case ALERT_DIALOGUE:
      return <AlertDialog key={dialogue.modalBodyId} dialogueProps={dialogue} />;

    case POP_UP_DIALOGUE:
      return <PopUpDialog key={dialogue.modalBodyId} dialogueProps={dialogue} />;

    case SYNC_DIALOGUE:
      return <SyncDialog key={dialogue.modalBodyId} dialogueProps={dialogue} />;

    case ACTIVATE_DIALOGUE:
      return <ActivateDialog key={dialogue.modalBodyId} dialogueProps={dialogue}/>;

    case GROUP_DIALOGUE:
      return <GroupDialog key={dialogue.modalBodyId} dialogueProps={dialogue} />;

    default:
      return <HalfPageDialogue key={dialogue.modalBodyId} dialogueProps={dialogue} />;
  }
};

const DialogPresenter: FC = () => {
  const { dialogues } = useContext(DialogContext);
  return (
    <>
      {dialogues.map((dialogue) => renderDialogue(dialogue))}
    </>
  );
};

export default DialogPresenter;
