import { unionBy, cloneDeep, difference, isEqual } from 'lodash';

import { IContactService, FEContact, FEHistory } from 'Modules/EmailManagement/Services/ContactService/types';
import { Contact, Data, IHistoryState, History, DataElement } from 'Modules/EmailManagement/Redux/Reducers/ContactReducer/types';
import { getTranslationKeyFromText } from 'Modules/EmailManagement/CustomHooks/useTranslatedSourceDataKey/useTranslatedSourceDataKey';
import DateService from 'Services/DateService/DateService';
import { DeepPartial } from 'Services/UtilityService/UtilityTypes';
import { Locale } from 'Services/LocalizationService/types';
import { Source } from 'Redux/Reducers/SourceReducer/types';
import { Tag } from 'Redux/Reducers/TagReducer/types';
import LocalizationService from 'Services/LocalizationService/LocalizationService';
import * as translationConstants from 'Constants/TranslationConstants';


const DATA_ARRAY_KEY_PRIORITY_LIST: string[] = ['description'];

class ContactService implements IContactService {
  public convertContactsToFEContacts(contacts: Contact[]): FEContact[] {
    const feContacts: FEContact[] = contacts.map(({
      id,
      email,
      status,
      createdAt,
      tags,
      data,
      mergedData,
    }) => {
      const sortedDatas = this.getSortedDatas(data);
      const emptySource: Source = {
        id: '',
        name: '',
        isEditable: true,
        logo: undefined,
        color: '',
      };
  
      return {
        id,
        email,
        status,
        createdAt,
        tags,
        firstName: mergedData ? (mergedData as DataElement).firstName : '',
        lastName: mergedData ? (mergedData as DataElement).lastName : '',
        country: mergedData ? (mergedData as DataElement).country : '',
        customerType: mergedData ? (mergedData as DataElement).customerType : '',
        cxStatus: mergedData ? (mergedData as DataElement).cxStatus : '',
        customerCategory: mergedData ? (mergedData as DataElement).customerCategory : '',
        audienceCategory: mergedData ? (mergedData as DataElement).audienceCategory : '',
        consent: mergedData ? (mergedData as DataElement).consent : '',
        sources: sortedDatas.length > 0 ? this.getSourcesFromDatas(sortedDatas) : [emptySource],
      };
    });
    return feContacts;
  }

  public convertContactHistoriesToFEHistories(histories: History[], locale: Locale): FEHistory[] {
    const feHistories: FEHistory[] = [];
    histories.forEach(({
      id,
      dateAdded,
      previousVersion,
      currentVersion,
    }) => {
      const [title, description] = this.getHistoryTitleAndDescription(previousVersion, currentVersion, locale);
      if (description.length > 0) {
        feHistories.push({
          id,
          title,
          description,
          date: dateAdded,
        });
      }
    });
    return feHistories;
  }

  public getMergedContacts(existingContacts: Contact[], newContacts: Contact[]): Contact[] {
    // History state is passed to the new merged state
    const newContactsWithHistory: Contact[] = [];
    newContacts.forEach((contact) => {
      const existingContact = existingContacts.find(({ id }) => id === contact.id);
      if (existingContact && existingContact.history) {
        newContactsWithHistory.push({
          ...contact,
          history: existingContact.history,
        });
      } else {
        newContactsWithHistory.push(contact);
      }
    });
    return unionBy(newContactsWithHistory, existingContacts, 'id');
  }

  public getContactsWithContactHistoryState(contactId: string, history: IHistoryState, contacts: Contact[]): Contact[] {
    const contact = contacts.find(({ id }) => id === contactId);
    if (!contact) {
      return contacts;
    }
    let contactClone = cloneDeep(contact);
    if (contactClone.history !== undefined) {
      contactClone.history.data = this.getSortedHistory(unionBy(contactClone.history.data, history.data, 'id'));
      contactClone.history.paginationData = {
        ...contactClone.history.paginationData,
        ...history.paginationData,
      };
      if (history.status) {
        contactClone.history.status = history.status;
      }
    } else {
      contactClone.history = history;
    };
    return unionBy([contactClone], contacts, 'id');
  }

  public getContactsWithContactData(contactId: string, data: Data, contacts: Contact[]): Contact[] {
    const contact = contacts.find(({ id }) => id === contactId);
    if (!contact) {
      return contacts;
    }
    let contactClone = cloneDeep(contact);
    contactClone.data = unionBy([data], contact.data, 'id');

    return unionBy([contactClone], contacts, 'id');
  }

  public removeContacts(contactIds: string[], contacts: Contact[]): Contact[] {
    return contacts.filter(({ id }) => !contactIds.includes(id));
  }

  // Creates the request body for the update tags API
  public getTags(tags: Tag[]): Partial<Tag>[] {
    return tags.map(({ id }) => ({ id }));
  }

  // Creates the request body for the update tags API
  public getContactsWithUpdatedTags(contacts: Contact[], removedTags: Tag[], addedTags: Tag[]): DeepPartial<Contact>[] {
    let updatedContacts: DeepPartial<Contact>[] = [];
    const removedTagIds = removedTags.map(({ id }) => id);

    contacts.forEach(contact => {
      // If there are no tags to remove only the added tags are sent to the API
      let newData = removedTags.length === 0 ? [...addedTags] : [...contact.tags, ...addedTags];
      newData = newData.filter(({ id }) => !removedTagIds.includes(id));
      updatedContacts.push({
        id: contact.id,
        tags: this.getTags(newData),
      });
    });
    return updatedContacts;
  }

  private getSortedDatas(sources: Data[]): Data[] {
    const sortedSources = sources.sort(({
      updatedAt: a,
    }, {
      updatedAt: b,
    }) => DateService.shared().isBefore(b, a) ? 1 : -1);
    return sortedSources;
  }

  private getSortedHistory(history: History[]): History[] {
    const sortedHistory = history.sort(({
      dateAdded: a,
    }, {
      dateAdded: b,
    }) => DateService.shared().isBefore(a, b) ? 1 : -1);
    return sortedHistory;
  }

  private getSortedContacts(contacts: Contact[]): Contact[] {
    const sortedContacts = contacts.sort(({
      createdAt: a,
    }, {
      createdAt: b,
    }) => DateService.shared().isBefore(a, b) ? 1 : -1);
    return sortedContacts;
  }

  private getDataFromDatasByKey(sources: Data[], key: string): string | undefined {
    let data = undefined;
    let i = 0;
    while (i < sources.length && data === undefined) {
      const source = sources[i];
      data = source.jsonPayload[key];
      i += 1;
    }
    return data;
  }

  private getSourcesFromDatas(datas: Data[]): Source[] {
    return datas.map(({ source }) => source);
  }

  private getStringFromArrayOfObjects(array: any[]): string {
    if (array.length === 0) {
      return '';
    }
    let usedKey = '';
    let i = 0;
    while (usedKey === '' && i<= DATA_ARRAY_KEY_PRIORITY_LIST.length) {
      const key = DATA_ARRAY_KEY_PRIORITY_LIST[i];
      const value = array[0][key];
      if (value !== undefined && value !== '' && value !== null) {
        usedKey = key;
      }
      i += 1;
    }

    if (usedKey === '') {
      return `${array.length} elements`;
    }

    const stringArray: string[] = [];
    array.forEach(element => {
      stringArray.push(`${element[usedKey]}`);
    });
    return stringArray.join(', ');
  }

  private getHistoryTitleAndDescription(previousVersion: Contact | null, currentVersion: Contact, locale: Locale): [string, string] {
    let title: string[] = [];
    let description = '';
    
    // Handling tags - description
    const newTags = currentVersion && currentVersion.tags ? currentVersion.tags.map(({ name }) => name) : [];
    const oldTags = previousVersion && previousVersion.tags ? previousVersion?.tags.map(({ name }) => name) : [];

    const addedTags = difference(newTags, oldTags);
    const removedTags = difference(oldTags, newTags);

    if (addedTags.length > 0 || removedTags.length > 0) {
      description += `
        <b>${LocalizationService.shared().getLocalizedText(locale, translationConstants.tag_updates)}</b>
        <br>
      `;
    }

    if (addedTags.length > 0) {
      const added_tags_text = LocalizationService.shared().getLocalizedText(locale, translationConstants.added_tags_description);
      description += `
        ${added_tags_text}: <b> ${addedTags.join(', ')} </b>
        <br>
      `;
    }
    if (removedTags.length > 0) {
      const removed_tags_text = LocalizationService.shared().getLocalizedText(locale, translationConstants.removed_tags_description);
      description += `
        ${removed_tags_text}: <b> ${removedTags.join(', ')} </b>
        <br>
      `;
    }

    // Handling data - description
    const removedData: string[] = [];
    const addedData: string[] = [];
    const changedData: string[] = [];
    
    if (currentVersion && currentVersion.mergedData) {
      Object.keys(currentVersion.mergedData).forEach((key) => {
        let currentData = currentVersion.mergedData[key];
        let oldData = previousVersion && previousVersion.mergedData ? previousVersion.mergedData[key] : null;

        const translationKey = getTranslationKeyFromText(key);
        const translatedKey = LocalizationService.shared().getLocalizedText(locale, translationKey);

        if (DateService.shared().getFormattedDetailedDateStringFromString(currentData) !== "Invalid date") {
          currentData = DateService.shared().getFormattedDetailedDateStringFromString(currentData);
        };

        if (!oldData && currentData) {
          const added_key_text = LocalizationService.shared().getLocalizedText(locale, translationConstants.added_key);
          if (Array.isArray(currentData)) {
            const valuesString = this.getStringFromArrayOfObjects(currentData);
            addedData.push(`<i> ${translatedKey} </i>: <b> ${valuesString} </b> ${added_key_text}`);
          } else {
            addedData.push(`<i> ${translatedKey} </i>: <b> ${currentData} </b> ${added_key_text}`);
          }
        }
        const updated_key_text = LocalizationService.shared().getLocalizedText(locale, translationConstants.updated_key);
        const from_this_text = LocalizationService.shared().getLocalizedText(locale, translationConstants.from_this);
        const to_this_text = LocalizationService.shared().getLocalizedText(locale, translationConstants.to_this);
        if (oldData && oldData !== currentData && !Array.isArray(oldData)) {
          changedData.push(`<i> ${translatedKey} </i> ${updated_key_text} ${from_this_text} <b> ${oldData} </b> ${to_this_text} <b> ${currentData} </b>`);
        }
        if (oldData && Array.isArray(oldData) && !isEqual(oldData, currentData)) {
          oldData = this.getStringFromArrayOfObjects(oldData);
          if (Array.isArray(currentData)) {
            currentData = this.getStringFromArrayOfObjects(currentData);
          }
          changedData.push(`<i> ${translatedKey} </i> ${updated_key_text} ${from_this_text} <b> ${oldData} </b> ${to_this_text} <b> ${currentData} </b>`);
        }
      }); 
    }

    if (previousVersion && previousVersion.mergedData) {
      Object.keys(previousVersion?.mergedData as DataElement).forEach((key) => {
        const currentData = (currentVersion.mergedData as DataElement)[key];
        const oldData = (previousVersion?.mergedData as DataElement)[key];

        const translationKey = getTranslationKeyFromText(key);
        const translatedKey = LocalizationService.shared().getLocalizedText(locale, translationKey);

        if (!currentData && oldData) {
          const removed_key_text = LocalizationService.shared().getLocalizedText(locale, translationConstants.removed_key);
          if (Array.isArray(oldData)) {
            const valuesString = this.getStringFromArrayOfObjects(oldData);
            removedData.push(`<i> ${translatedKey} </i>: <b> ${valuesString} </b> ${removed_key_text}`);
          } else {
            removedData.push(`<i> ${translatedKey} </i>: <b> ${oldData} </b> ${removed_key_text}`);
          }
        }
      }); 
    }

    if (removedData.length > 0 || addedData.length > 0 || changedData.length > 0) {
      description += `
        <b>${LocalizationService.shared().getLocalizedText(locale, translationConstants.data_updates)}</b>
        <br>
      `;
    }

    if (removedData.length > 0) {
      description += `
        ${removedData.join('<br>')}
      `;
    }

    if (addedData.length > 0) {
      description += `
        ${addedData.join('<br>')}
      `;
    }

    if (changedData.length > 0) {
      description += `
        ${changedData.join('<br>')}
      `;
    }

    // Handling title
    if (!previousVersion) {
      title.push(LocalizationService.shared().getLocalizedText(locale, translationConstants.created));
    }

    if (previousVersion && removedTags.length > 0 && addedTags.length === 0) {
      title.push(LocalizationService.shared().getLocalizedText(locale, translationConstants.removed_tags));
    }

    if (previousVersion && removedTags.length === 0 && addedTags.length > 0) {
      title.push(LocalizationService.shared().getLocalizedText(locale, translationConstants.added_tags));
    }

    if (previousVersion && removedTags.length > 0 && addedTags.length > 0) {
      title.push(LocalizationService.shared().getLocalizedText(locale, translationConstants.updated_tags));
    }

    if (previousVersion && removedData.length > 0 && addedData.length === 0 && changedData.length === 0) {
      title.push(LocalizationService.shared().getLocalizedText(locale, translationConstants.removed_data));
    }

    if (removedData.length === 0 && addedData.length > 0 && changedData.length === 0) {
      title.push(LocalizationService.shared().getLocalizedText(locale, translationConstants.added_data));
    }

    if (previousVersion && (changedData.length > 0 || (removedData.length > 0 && addedData.length > 0))) {
      title.push(LocalizationService.shared().getLocalizedText(locale, translationConstants.updated_data));
    }

    return [title.join(', '), description];
  }
}

export default ContactService;
