import { ThunkAction } from 'redux-thunk';

import { MAILCHIMP_SYNC, MAILCHIMP_SYNC_PATCH } from 'Modules/EmailManagement/Constants/WebservicePaths';
import WebService  from 'Services/WebService/WebService';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import { RootReducerState } from 'Redux/Reducers/types';
import { IError } from 'Redux/Reducers/types';
import {
  MAILCHIMP_SYNC_FAIL,
  MAILCHIMP_SYNC_LOADING,
  MAILCHIMP_SYNC_PATCH_FAIL,
  MAILCHIMP_SYNC_PATCH_LOADING,
  MAILCHIMP_SYNC_PATCH_SUCCESS,
  MAILCHIMP_SYNC_SUCCESS,
  MailchimpAccountsAction,
} from 'Modules/EmailManagement/Redux/types';
import { FilterValueType } from 'CustomHooks/useComplexFilter/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, MailchimpAccountsAction>;

export const syncWithMailchimp = (name: string, selectedIds: string[], predefinedFilter: FilterValueType, success: (response: any) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: MAILCHIMP_SYNC_LOADING,
  });
  try {
    let syncObject = {
      displayName: name,
      sourceIds: selectedIds,
      predefinedFilterId: predefinedFilter,
    };
    const option = new WebServiceOption('POST',`${MAILCHIMP_SYNC}`, syncObject);
    const response = await WebService.shared().callWebService(option);
    dispatch({
      type: MAILCHIMP_SYNC_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: MAILCHIMP_SYNC_FAIL,
    });
    fail(error as IError);
  }
};

export const syncWithExistingMailchimp = (name: string, segmentIds: string[], predefinedFilter: FilterValueType, success: (response: any) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: MAILCHIMP_SYNC_PATCH_LOADING,
  });
  try {
    let syncObject = {
      displayName: name,
      predefinedFilterId: predefinedFilter,
      segmentIds: segmentIds,
    };
    const option = new WebServiceOption('PATCH', MAILCHIMP_SYNC_PATCH, syncObject);
    const response = await WebService.shared().callWebService(option);
    dispatch({
      type: MAILCHIMP_SYNC_PATCH_SUCCESS,
      payload: response,
    });
    success(response);
  } catch (error) {
    dispatch({
      type: MAILCHIMP_SYNC_PATCH_FAIL,
    });
    fail(error as IError);
  }
};
