export const CONTACT_PREDEFINED_COMPLEX_FILTERS = '/contact/predefined_filters';
export const CONTACT_COMPLEX_FILTER_OPTIONS = '/contact/advanced_filter';
export const CONTACT_COMPLEX_FILTER = '/contact/predefined_filter';

export const SEGMENT_PREDEFINED_COMPLEX_FILTERS = '/segment/predefined_filters';
export const SEGMENT_COMPLEX_FILTER_OPTIONS = '/segment/advanced_filter';
export const SEGMENT_COMPLEX_FILTER = '/segment/predefined_filter';
export const SEGMENT_COMPLEX_FILTERS = '/segment/predefined_filters';

export const SOURCE_LIST = '/source';
export const SEGMENT_SOURCE_LIST = '/segment/source';
export const TAG_LIST = '/tag';
export const TAG_LIST_BULK = '/tag_bulk';
export const AVAILABLE_COMPONENTS_LIST = '/core/components/available'

export const SIGN_UP = '/auth/register';
export const LOGIN = '/auth/login';
export const GETUSER = '/auth/user';
export const REFRESHTOKEN = '/auth/refresh';
export const VALIDATEUSER = '/auth/email_verify/';
export const REQUESTRESETPASSWORD = '/auth/request_password_reset';
export const RESETPASSWORD = '/auth/reset_password';
export const SENDINVITE = '/auth/invite';
export const SEND_VERIFICATION_EMAIL = '/auth/send_verification_again';

export const INTEGRATION_PLATFORMS = '/core/integration_platforms';
export const INTEGRATION_PLATFORMS_ADD = '/core/integration_platforms/add';
export const INTEGRATED_PLATFORMS = '/core/integration_platforms/integrated';
export const INTEGRATED_PLATFORMS_FACEBOOK_AD_ACCOUNTS = '/core/integration_platforms/{id}/facebook_ad_accounts';
export const INTEGRATED_PLATFORMS_GOOGLE_ADS_ACCOUNTS = '/core/integration_platforms/{id}/google_ads_accounts';
export const INTEGRATED_PLATFORMS_GOOGLE_ANALYTICS_ACCOUNTS = '/core/integration_platforms/{id}/google_analytics_accounts';
