import moment, { Moment } from 'moment';

import { IDateService } from 'Services/DateService/types';
import { DATE_FORMAT, DETAILED_DATE_FORMAT, ISO_DATE_FORMAT } from 'Constants/Options';

moment.locale('en', {
  week: {
    dow: 1,
  },
})

class DateService implements IDateService {
  static sharedInstance: IDateService;
  static shared() {
    if (!DateService.sharedInstance) {
      DateService.sharedInstance = new DateService();
    }
    return DateService.sharedInstance;
  }

  getFormattedDateString(date: Date | Moment): string {
    return moment(date).format(DATE_FORMAT);
  }

  getDateFromString(string: string): Moment {
    return moment(string, [DATE_FORMAT, ISO_DATE_FORMAT]);
  }

  getFormattedDateStringFromString(string: string): string {
    return moment(string, [DATE_FORMAT, ISO_DATE_FORMAT]).format(DATE_FORMAT);
  }

  getFormattedDetailedDateStringFromString(string: string): string {
    return moment(string, [DATE_FORMAT, ISO_DATE_FORMAT]).format(DETAILED_DATE_FORMAT);
  }

  isBefore(a: string, b: string): boolean {
    return moment(a, [DATE_FORMAT, ISO_DATE_FORMAT]).isBefore(moment(b, [DATE_FORMAT, ISO_DATE_FORMAT]));
  }

  isSameDay(a: string, b: string): boolean {
    const aDate = moment(a, [DATE_FORMAT, ISO_DATE_FORMAT]);
    const bDate = moment(b, [DATE_FORMAT, ISO_DATE_FORMAT]);
    return `${aDate.year()}${aDate.month()}${aDate.date()}` === `${bDate.year()}${bDate.month()}${bDate.date()}`;
  }

  getOneWeekFrom(date: Date | Moment): string {
    return moment(date).subtract(1, 'weeks').format(DATE_FORMAT);
  }

  getLastWeek(): [string, string] {
    const startLastWeek = moment().subtract(1, 'weeks').startOf('week').format(DATE_FORMAT);
    const endLastWeek = moment().subtract(1, 'weeks').endOf('week').format(DATE_FORMAT);
    return [startLastWeek, endLastWeek];
  }

  getLastMonth(): [string, string] {
    const startLastMonth = moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT);
    const endLastMonth = moment().subtract(1, 'months').endOf('month').format(DATE_FORMAT);
    return [startLastMonth, endLastMonth];
  }

  getLastYear(): [string, string] {
    const startLastYear = moment().subtract(1, 'years').startOf('year').format(DATE_FORMAT);
    const endLastYear = moment().subtract(1, 'years').endOf('year').format(DATE_FORMAT);
    return [startLastYear, endLastYear];
  }

  getThisMonthUntillYesterday(): [string, string] {
    const startThisMonth = moment().subtract(1, 'days').startOf('month').format(DATE_FORMAT);
    const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT);
    return [startThisMonth, yesterday];
  }

  getLast28Days(): [string, string] {
    const twentyEightDaysBefore = moment().subtract(28, 'days').format(DATE_FORMAT);
    const yesterday = moment().subtract(1, 'days').format(DATE_FORMAT);
    return [twentyEightDaysBefore, yesterday];
  }
}

export default DateService;
