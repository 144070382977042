import { SortingCriteria } from 'CustomHooks/useSort/types';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { DeepPartial } from 'Services/UtilityService/UtilityTypes';
import { SelectionActionObject } from 'CustomHooks/useSelection/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';

import { ISegmentState, ISegmentFilter, Segment } from 'Modules/Marketing/Redux/Reducers/SegmentsReducer/types';
import { ISegmentContactFilter, ISegmentContactState, SegmentContact } from 'Modules/Marketing/Redux/Reducers/SegmentContactsReducer/types';
import { GoogleAccountElement } from 'Modules/Marketing/Redux/Reducers/GoogleAccountListReducer/types';
import { MailchimpAccountElement } from 'Modules/EmailManagement/Redux/Reducers/MailchimpAccountsReducer/types';

// Segment action types
export const SEGMENT_LIST_LOADING = 'SEGMENT_LIST_LOADING';
interface ISegmentListLoading {
  type: typeof SEGMENT_LIST_LOADING,
  payload: {
    segmentId?: never,
    sourceId: string,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
}

export const SEGMENT_LIST_SUCCESS = 'SEGMENT_LIST_SUCCESS';
interface ISegmentListSuccess {
  type: typeof SEGMENT_LIST_SUCCESS,
  payload: {
    segmentId?: never,
    state: DeepPartial<ISegmentState>,
    sourceId: string,
    filter?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
}

export const SEGMENT_LIST_RESET = 'SEGMENT_LIST_RESET';
interface ISegmentListReset {
  type: typeof SEGMENT_LIST_RESET,
  payload: {
    segmentId?: never,
    state: {},
    sourceId: string,
    filter?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
}

export const SEGMENT_LIST_FAIL = 'SEGMENT_LIST_FAIL';
interface ISegmentListFail {
  type: typeof SEGMENT_LIST_FAIL,
  payload: {
    segmentId?: never,
    sourceId: string,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
}

export const DELETE_SEGMENT_LOADING = 'DELETE_SEGMENT_LOADING';
interface IDeleteSegmentContactLoading {
  type: typeof DELETE_SEGMENT_LOADING,
  payload: {
    sourceId: string,
    segmentId?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};

export const DELETE_SEGMENT_SUCCESS = 'DELETE_SEGMENT_SUCCESS';
interface IDeleteSegmentContactSuccess {
  type: typeof DELETE_SEGMENT_SUCCESS,
  payload: {
    sourceId: string,
    segmentId: string,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};

export const DELETE_SEGMENT_FAIL = 'DELETE_SEGMENT_FAIL';
interface IDeleteSegmentContactFail {
  type: typeof DELETE_SEGMENT_FAIL,
  payload: {
    sourceId: string,
    segmentId?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};

export const PUBLISH_SEGMENT_LOADING = 'PUBLISH_SEGMENT_LOADING';
interface IPublishSegmentContactLoading {
  type: typeof PUBLISH_SEGMENT_LOADING,
  payload: {
    sourceId: string,
    segmentId?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};

export const PUBLISH_SEGMENT_SUCCESS = 'PUBLISH_SEGMENT_SUCCESS';
interface IPublishSegmentContactSuccess {
  type: typeof PUBLISH_SEGMENT_SUCCESS,
  payload: {
    sourceId: string,
    segmentId: string,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};

export const PUBLISH_SEGMENT_FAIL = 'PUBLISH_SEGMENT_FAIL';
interface IPublishSegmentContactFail {
  type: typeof PUBLISH_SEGMENT_FAIL,
  payload: {
    sourceId: string,
    segmentId?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};

export const SET_SEGMENT_FILTER = 'SET_SEGMENT_FILTER';
interface ISetSegmentFilter {
  type: typeof SET_SEGMENT_FILTER,
  payload: {
    segmentId?: never,
    filter: Partial<ISegmentFilter>,
    sourceId: string,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};

export const SET_SELECTED_SEGMENTS = 'SET_SELECTED_SEGMENTS';
interface ISetSelectedSegments {
  type: typeof SET_SELECTED_SEGMENTS,
  payload: {
    segmentId?: never,
    sourceId: string,
    actionObject: SelectionActionObject,
    filter?: never,
    state?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
  },
};



export const SET_SEGMENT_PAGINATION_DATA = 'SET_SEGMENT_PAGINATION_DATA';
interface ISetSegmentPaginationData {
  type: typeof SET_SEGMENT_PAGINATION_DATA,
  payload: {
    segmentId?: never,
    sourceId: string,
    paginationInfo: Partial<PaginationInfo>,
    filter?: never,
    state?: never,
    actionObject?: never,
    sortingCriteria?: never,
  },
};

export const SET_SEGMENT_SORTING_CRITERIA = 'SET_SEGMENT_SORTING_CRITERIA';
interface ISetSegmentSortingCriteria {
  type: typeof SET_SEGMENT_SORTING_CRITERIA,
  payload: {
    segmentId?: never,
    sourceId: string,
    sortingCriteria: SortingCriteria,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
  },
};

export type SegmentListAction = ISegmentListLoading |
  ISegmentListSuccess |
  ISegmentListFail |
  ISetSegmentFilter |
  IDeleteSegmentContactLoading |
  IDeleteSegmentContactSuccess |
  IDeleteSegmentContactFail |
  ISetSelectedSegments |
  ISetSegmentPaginationData |
  ISetSegmentSortingCriteria |
  IPublishSegmentContactLoading |
  IPublishSegmentContactSuccess |
  ISegmentListReset |
  IPublishSegmentContactFail;

// Segment contact list action types
export const SEGMENT_LOADING = 'SEGMENT_LOADING';
interface ISegmentContactLoading {
  type: typeof SEGMENT_LOADING,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SEGMENT_SUCCESS = 'SEGMENT_SUCCESS';
interface ISegmentContactSuccess {
  type: typeof SEGMENT_SUCCESS,
  payload: {
    contactIds?: never,
    contact?: never,
    segment: Segment,
    name?: never,
    state?: DeepPartial<ISegmentContactState>,
    filter?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SEGMENT_FAIL = 'SEGMENT_FAIL';
interface ISegmentContactFail {
  type: typeof SEGMENT_FAIL,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const CREATE_SEGMENT_LOADING = 'CREATE_SEGMENT_LOADING';
interface ICreateSegmentContactLoading {
  type: typeof CREATE_SEGMENT_LOADING,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const CREATE_SEGMENT_SUCCESS = 'CREATE_SEGMENT_SUCCESS';
interface ICreateSegmentContactSuccess {
  type: typeof CREATE_SEGMENT_SUCCESS,
  payload: {
    contactIds?: never,
    contact?: never,
    segment: Segment,
    name?: never,
    state?: never,
    filter?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const CREATE_SEGMENT_FAIL = 'CREATE_SEGMENT_FAIL';
interface ICreateSegmentContactFail {
  type: typeof CREATE_SEGMENT_FAIL,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const UPDATE_SEGMENT_LOADING = 'UPDATE_SEGMENT_LOADING';
interface IUpdateSegmentContactLoading {
  type: typeof UPDATE_SEGMENT_LOADING,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const UPDATE_SEGMENT_SUCCESS = 'UPDATE_SEGMENT_SUCCESS';
interface IUpdateSegmentContactSuccess {
  type: typeof UPDATE_SEGMENT_SUCCESS,
  payload: {
    contactIds?: never,
    contact?: never,
    segment: Segment,
    name?: never,
    state?: never,
    filter?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const UPDATE_SEGMENT_FAIL = 'UPDATE_SEGMENT_FAIL';
interface IUpdateSegmentContactFail {
  type: typeof UPDATE_SEGMENT_FAIL,
  payload: {
    segment?: never
    contactIds?: never,
    contact?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SEGMENT_CONTACT_LIST_LOADING = 'SEGMENT_CONTACT_LIST_LOADING';
interface ISegmentContactListLoading {
  type: typeof SEGMENT_CONTACT_LIST_LOADING,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SEGMENT_CONTACT_LIST_SUCCESS = 'SEGMENT_CONTACT_LIST_SUCCESS';
interface ISegmentContactListSuccess {
  type: typeof SEGMENT_CONTACT_LIST_SUCCESS,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    state: DeepPartial<ISegmentContactState>,
    filter?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SEGMENT_CONTACT_LIST_FAIL = 'SEGMENT_CONTACT_LIST_FAIL';
interface ISegmentContactListFail {
  type: typeof SEGMENT_CONTACT_LIST_FAIL,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SET_SEGMENT_CONTACT_FILTER = 'SET_SEGMENT_CONTACT_FILTER';
interface ISetSegmentContactFilter {
  type: typeof SET_SEGMENT_CONTACT_FILTER,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    filter: Partial<ISegmentContactFilter>,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SET_SELECTED_SEGMENTS_CONTACTS = 'SET_SELECTED_SEGMENTS_CONTACTS';
interface ISetSelectedSegmentContacts {
  type: typeof SET_SELECTED_SEGMENTS_CONTACTS,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    actionObject: SelectionActionObject,
    filter?: never,
    state?: never,
    paginationInfo?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SET_SEGMENT_CONTACT_PAGINATION_DATA = 'SET_SEGMENT_CONTACT_PAGINATION_DATA';
interface ISetSegmentContactPaginationData {
  type: typeof SET_SEGMENT_CONTACT_PAGINATION_DATA,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    paginationInfo: Partial<PaginationInfo>,
    filter?: never,
    state?: never,
    actionObject?: never,
    sortingCriteria?: never,
    complexFilter?: never,
  },
};

export const SET_SEGMENT_CONTACT_SORTING_CRITERIA = 'SET_SEGMENT_CONTACT_SORTING_CRITERIA';
interface ISetSegmentContactSortingCriteria {
  type: typeof SET_SEGMENT_CONTACT_SORTING_CRITERIA,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    sortingCriteria: SortingCriteria,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    complexFilter?: never,
  },
};

export const RESET_SEGMENT_CONTACTS = 'RESET_SEGMENT_CONTACTS';
interface IResetSegmentContacts {
  type: typeof RESET_SEGMENT_CONTACTS,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    sortingCriteria?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    complexFilter?: never,
  },
};

export const SET_SEGMENT_CONTACT_COMPLEX_FILTER = 'SET_SEGMENT_CONTACT_COMPLEX_FILTER';
interface ISetSegmentContactComplexFilter {
  type: typeof SET_SEGMENT_CONTACT_COMPLEX_FILTER,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name?: never,
    sortingCriteria?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    complexFilter: ComplexFilterSet,
  },
};

export const SET_SEGMENT_NAME = 'SET_SEGMENT_NAME';
interface ISetSegmentName {
  type: typeof SET_SEGMENT_NAME,
  payload: {
    contactIds?: never,
    contact?: never,
    segment?: never,
    name: string,
    sortingCriteria?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    complexFilter?: never,
  },
};

export const BULK_SET_SEGMENT_CONTACTS = 'BULK_SET_SEGMENT_CONTACTS';
interface IBulkSetSegmentContacts {
  type: typeof BULK_SET_SEGMENT_CONTACTS,
  payload: {
    contactIds: string[],
    contact: Partial<SegmentContact>,
    segment?: never,
    name?: never,
    sortingCriteria?: never,
    filter?: never,
    state?: never,
    actionObject?: never,
    paginationInfo?: never,
    complexFilter?: never,
  },
};

export type SegmentContactListAction = ISegmentContactLoading |
  ISegmentContactSuccess |
  ISegmentContactFail |
  ICreateSegmentContactLoading |
  ICreateSegmentContactSuccess |
  ICreateSegmentContactFail |
  IUpdateSegmentContactLoading |
  IUpdateSegmentContactSuccess |
  IUpdateSegmentContactFail |
  ISegmentContactListLoading |
  ISegmentContactListSuccess |
  ISegmentContactListFail |
  ISetSegmentContactFilter |
  ISetSelectedSegmentContacts |
  ISetSegmentContactPaginationData |
  ISetSegmentContactSortingCriteria |
  IResetSegmentContacts |
  ISetSegmentContactComplexFilter |
  ISetSegmentName| 
  IBulkSetSegmentContacts;

  // Google account list action types
export const GOOGLE_ACCOUNT_LIST_LOADING = 'GOOGLE_ACCOUNT_LIST_LOADING';
interface IGoogleAccountListLoading {
  type: typeof GOOGLE_ACCOUNT_LIST_LOADING,
  payload?: never,
}

export const GOOGLE_ACCOUNT_LIST_SUCCESS = 'GOOGLE_ACCOUNT_LIST_SUCCESS';
interface IGoogleAccountListSuccess {
  type: typeof GOOGLE_ACCOUNT_LIST_SUCCESS,
  payload: GoogleAccountElement[],
}

export const GOOGLE_ACCOUNT_LIST_FAIL = 'GOOGLE_ACCOUNT_LIST_FAIL';
interface IGoogleAccountListFail {
  type: typeof GOOGLE_ACCOUNT_LIST_FAIL,
  payload?: never,
}

export type GoogleAccountListAction = IGoogleAccountListLoading | IGoogleAccountListSuccess | IGoogleAccountListFail;

 // Google account list action types
 export const MAILCHIMP_ACCOUNT_LIST_LOADING = 'MAILCHIMP_ACCOUNT_LIST_LOADING';
 interface IMailchimpAccountListLoading {
   type: typeof MAILCHIMP_ACCOUNT_LIST_LOADING,
   payload?: never,
 }
 
 export const MAILCHIMP_ACCOUNT_LIST_SUCCESS = 'MAILCHIMP_ACCOUNT_LIST_SUCCESS';
 interface IMailchimpAccountListSuccess {
   type: typeof MAILCHIMP_ACCOUNT_LIST_SUCCESS,
   payload: MailchimpAccountElement[],
 }
 
 export const MAILCHIMP_ACCOUNT_LIST_FAIL = 'MAILCHIMP_ACCOUNT_LIST_FAIL';
 interface IMailchimpAccountListFail {
   type: typeof MAILCHIMP_ACCOUNT_LIST_FAIL,
   payload?: never,
 }
 
 export type MailchimpAccountListAction = IMailchimpAccountListLoading | IMailchimpAccountListSuccess | IMailchimpAccountListFail;


export const SET_SEGMENT_ITEM_COUNT = 'SET_SEGMENT_ITEM_COUNT';
interface ISegmentContactCount {
  type: typeof SET_SEGMENT_ITEM_COUNT,
  payload: number,
}

export const SET_SEGMENT_LIST_ITEM_COUNT = 'SET_SEGMENT_LIST_ITEM_COUNT';
interface ISegmentListContactCount {
  type: typeof SET_SEGMENT_LIST_ITEM_COUNT,
  payload: number,
}

export type SegmentContactCountAction = ISegmentContactCount | ISegmentListContactCount;
