import { ToastMessageProps, UIStateType } from 'Redux/Reducers/UIReducer/types';
import {
  UIActionType,
  SET_TOAST_MESSAGE,
  REMOVE_TOAST_MESSAGE,
  OPEN_SECONDARY_MENU,
  CLOSE_SECONDARY_MENU,
} from 'Redux/types';

const initialState: UIStateType = {
  toastMessages: [],
  isSecondaryMenuOpen: false,
};

const uiReducer = (state = initialState, action: UIActionType): UIStateType => {
  const { type, payload } = action;
  switch (type) {
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessages: [
          payload as ToastMessageProps,
          ...state.toastMessages,
        ],
      };
    case REMOVE_TOAST_MESSAGE: 
      return {
        ...state,
        toastMessages: state.toastMessages.filter(({ id }) => id !== payload as string),
      };
    case OPEN_SECONDARY_MENU:
      return {
        ...state,
        isSecondaryMenuOpen: true,
      };
    case CLOSE_SECONDARY_MENU:
      return {
        ...state,
        isSecondaryMenuOpen: false,
      };
    default:
      return state;
  }
};

export default uiReducer;
