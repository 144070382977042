import { useSelector } from 'react-redux';

import { RootReducerState } from 'Redux/Reducers/types';
import { Locale } from 'Services/LocalizationService/types';
import LocalizationService from 'Services/LocalizationService/LocalizationService';

const useLocalized = (text: string): string => {
  const locale = useSelector<RootReducerState, Locale>(state => state.user.locale);
  const localizedText = LocalizationService.shared().getLocalizedText(locale, text);
  return localizedText;
}

export default useLocalized;
