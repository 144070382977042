import { ISiteState, Site } from 'Modules/Reports/Redux/Reducers/SiteReducer/types';
import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
} from 'Redux/types';
import {
  SITE_LIST_LOADING,
  SITE_LIST_SUCCESS,
  SITE_LIST_FAIL,
  SiteListAction,
} from 'Modules/Reports/Redux/types';

const initialState: ISiteState = {
  status: DEFAULT,
  data: [],
};

const siteReducer = (state = initialState, action: SiteListAction): ISiteState => {
  const { type, payload } = action;
  switch (type) {
    case SITE_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SITE_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as Site[],
      };
    case SITE_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default siteReducer;
