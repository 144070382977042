import { ListItem } from "CustomHooks/useDialog/types";
import { GroupCampaignListItem } from "Modules/Reports/ReportScreens/CampaignsScreen/Blocks/CampaignList/types";
import { FBLoginResponse, GoogleDataForSend } from "Services/FacebookLoginService/types";

const supportedMethods = [
  'GET',
  'POST',
  'PUT',
  'DELETE',
  'PATCH',
] as const;

export type SupportedMethodType = typeof supportedMethods[number];

export const CONTENT_TYPE_APPLICATION_JSON = 'application/json';
export const CONTENT_TYPE_MULTIPART_FORM_DATA = 'multipart/form-data';
export const CONTENT_TYPE_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const FILE_TYPE_EXCEL = '.xlsx';

const supportedContentTypes = [
  CONTENT_TYPE_APPLICATION_JSON,
  CONTENT_TYPE_MULTIPART_FORM_DATA,
] as const;

export type SupportedContentType = typeof supportedContentTypes[number];

export enum HttpStatusCode {
  SUCCESS = 200,
  CREATED = 201,
  ACCEPTED = 202,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  FRONTEND_ERROR = 900, // Unkown exception catched at WebService class 
}

export interface IWebServiceOption {
  method: SupportedMethodType,
  body?: any,
  path: string,
  headers: Headers;
  addAuthorizationHeader: (token: string) => void,
}

export interface ISignUpData {
  email: string,
  name: string,
  password: string,
  accountType: string,
  organizationName: string,
}

export interface ILoginData {
  email: string,
  password: string,
}

export interface IUserDataOrganization {
  id: string,
  name: string,
  accountType: string,
  createdAt: string,
  updatedAt: string,
  proUser: boolean,
}

export interface IUserData {
  createdAt: string,
  id: string,
  organization: IUserDataOrganization,
  updatedAt: string,
}

export interface IRequestResetPasswordData {
  email: string
}

export interface IResetPasswordData {
  newPassword: string,
  confirmPassword: string,
  token: string,
}

export interface ISendInvite {
  email: string,
  name: string,
  integrationPlatformId: string,
}

export interface IGroupCampaignsData {
  groupName: string,
  campaigns: GroupCampaignListItem[],
}

export interface AuthenticationResult {
  access: string,
  refresh: string,
}

export interface IWebservice {
  callWebService: (option: IWebServiceOption) => Promise<any | void>,
  setToken: (token: string) => void,
  removeToken: () => void,
  login: (loginData: ILoginData) => void,
  signUp: (signUpData: ISignUpData) => void,
  getUser: () => Promise<any>,
  requestResetPassword: (data: IRequestResetPasswordData) => void,
  sendVerificationEmail: (data: IRequestResetPasswordData) => Promise<any>,
  resetPassword: (data: IResetPasswordData) => Promise<any>,
  sendInvite: (data: ISendInvite) => Promise<any>,
  integrationPlatforms: () => Promise<any>,
  campaignsToGroup: (params: string) => Promise<any>,
  groupCampaigns: (data: IGroupCampaignsData) => Promise<any>,
  integrationPlatformsAdd: (data: FBLoginResponse | GoogleDataForSend | undefined, id: string) => Promise<any>,
  loginFromInviteLink: (data: AuthenticationResult) => void,
  getFacebbokAdAccounts: (id: string) => Promise<any>,
  getGoogleAnalyticsAdAccounts: (id: string) => Promise<any>,
  getGoogleAdsAdAccounts: (id: string) => Promise<any>,
  patchFacebookAdAccounts: (id: string, patchData: ListItem) => Promise<any>,
  patchGoogleAnalyticsAdAccounts: ( groupId: string, id: string, name: string, subAccountId: string, subAccountName: string) => Promise<any>,
  patchGoogleAdsAdAccounts: ( groupId: string, id: string, name: string, subAccountId: string, subAccountName: string) => Promise<any>,
  logout: (fromRemote: boolean) => void,
  acquireTokenSilent: () => void,
  isValidToken: (token: string) => void,
  validateUser: (token: string) => void,
}
