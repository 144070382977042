import { IAudienceReportState, IAudienceReporttFilter } from 'Modules/Reports/Redux/Reducers/AudiencesPerformanceReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  SET_AUDIENCE_REPORT_LIST_SORTING_CRITERIA,
  AUDIENCE_REPORT_LIST_LOADING,
  AUDIENCE_REPORT_LIST_SUCCESS,
  AUDIENCE_REPORT_LIST_FAIL,
  SET_AUDIENCE_REPORT_LIST_FILTER,
  SET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER,
  RESET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER,
} from 'Modules/Reports/Redux/types';
import { AudienceReportListActionType } from 'Modules/Reports/Redux/types';
import SortingService from 'Services/SortingService/SortingService';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';

const initialState: IAudienceReportState = {
  status: DEFAULT,
  data: [
    {
      id: '',
      audienceList: '',
      listSize: 0,
      impressions: 0,
      interactions: 0,
      interactionRate: 0,
      sessions: 0,
      sessionRate: 0,
      conversion: 0,
      conversionRate: 0,
      clicks: 0,
      videoViews: 0,
      segmentId: '',
    },
  ],
  sortingCriteria: {
    key: 'channel.displayName',
    order: 'ASCENDING',
    dataType: 'STRING',
  },
  filter: {
    predefinedFilterId: {
      value: '',
      appliedOn: ['predefinedFilterId'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate1: {
      value: null,
      appliedOn: ['dataAtDate1'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate2: {
      value: null,
      appliedOn: ['dataAtDate2'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate3: {
      value: null,
      appliedOn: ['dataAtDate3'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate4: {
      value: null,
      appliedOn: ['dataAtDate4'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
  },
  complexFilter: {
    name: '',
    filterSet: [],
  },
};

const audiencesPerformanceReducer = (state = initialState, action: AudienceReportListActionType): IAudienceReportState => {
  const { type, payload } = action;
  switch (type) {
    case SET_AUDIENCE_REPORT_LIST_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload as IAudienceReporttFilter,
        },
        selectedAll: false,
      };
    case SET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: payload as ComplexFilterSet,
      };
    case RESET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: {
          name: '',
          filterSet: [],
        },
      }
    case SET_AUDIENCE_REPORT_LIST_SORTING_CRITERIA:
      return {
        ...state,
        sortingCriteria: payload as SortingCriteria,
      };
    case AUDIENCE_REPORT_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case AUDIENCE_REPORT_LIST_SUCCESS:
      const sortedData = SortingService.getSortedArray((payload as Partial<IAudienceReportState>).data!, state.sortingCriteria);
      return {
        ...state,
        data: sortedData,
        status: SUCCESS,
      };
    case AUDIENCE_REPORT_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default audiencesPerformanceReducer;
