import { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { DEFAULT } from 'Redux/types';
import { IGenericDataState, IError } from 'Redux/Reducers/types';
import { ToastMessageContext } from 'CustomHooks/useToastMessage/useToastMessage';

const useGetData = (state: IGenericDataState<any>, getAction: (success: () => void, fail: (error: IError) => void) => void) => {
  const dispatch = useDispatch();
  const { presentErrorToastByCode } = useContext(ToastMessageContext);
  useEffect(() => {
    if (state.status === DEFAULT) {
      dispatch(getAction(() => {

      }, (error) => {
        presentErrorToastByCode(error.code);
      }));
    }
  }, [dispatch, getAction, presentErrorToastByCode, state]);
};

export default useGetData;
