import { IBizmailAccountsState, BizmailAccountElement} from 'Modules/EmailManagement/Redux/Reducers/BizmailAccountsReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  BIZMAIL_ACCOUNTS_LOADING,
  BIZMAIL_ACCOUNTS_SUCCESS,
  BIZMAIL_ACCOUNTS_FAIL,
  BizmailAccountsAction,
} from 'Modules/EmailManagement/Redux/types';

const initialState: IBizmailAccountsState = {
  status: DEFAULT,
  data: [],
};

const bizmailAccountsReducer = (state = initialState, action: BizmailAccountsAction): IBizmailAccountsState => {
  const { type, payload } = action;
  switch (type) {
    case BIZMAIL_ACCOUNTS_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case BIZMAIL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as BizmailAccountElement[],
      };
    case BIZMAIL_ACCOUNTS_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default bizmailAccountsReducer;
