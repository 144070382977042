import { ISourcePathReportState, ISourcePathReportFilter } from 'Modules/Reports/Redux/Reducers/SourcePathReducer/types';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
} from 'Redux/types';
import {
  SET_SOURCE_PATH_REPORT_LIST_SORTING_CRITERIA,
  SOURCE_PATH_REPORT_LIST_LOADING,
  SOURCE_PATH_REPORT_LIST_SUCCESS,
  SOURCE_PATH_REPORT_LIST_FAIL,
  SET_SOURCE_PATH_REPORT_LIST_FILTER,
  SET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
  RESET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER,
  SET_SOURCE_PATH_PAGINATION_DATA,
  SET_SOURCE_PATH_ITEM_COUNT,
} from 'Modules/Reports/Redux/types';
import { SourcePathReportListActionType } from 'Modules/Reports/Redux/types';
import SortingService from 'Services/SortingService/SortingService';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { PAGINATION_DEFAULT_ROW_NUMBER } from 'Constants/Options';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';

const initialState: ISourcePathReportState = {
  paginationData: {
    previous: null,
    next: null,
    cursor: null,
    limit: PAGINATION_DEFAULT_ROW_NUMBER,
    page: 1,
  },
  status: DEFAULT,
  data: [
    {
      id: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      country: {
        id: '',
        countryName: '',
        isoCode: '',
        isoCodeType: '',
      },
      sourcePath: [],
    },
  ],
  sourcePathCount: null,
  selectedIds: [],
  filter: {
    searchText: {
      value: '',
      appliedOn: ['email', 'lastName', 'firstName'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    predefinedFilterId: {
      value: '',
      appliedOn: ['predefinedFilterId'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate1: {
      value: null,
      appliedOn: ['dataAtDate1'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate2: {
      value: null,
      appliedOn: ['dataAtDate2'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate3: {
      value: null,
      appliedOn: ['dataAtDate3'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    dataAtDate4: {
      value: null,
      appliedOn: ['dataAtDate4'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
  },
  sortingCriteria: {
    key: 'createdAt',
    order: 'ASCENDING',
    dataType: 'STRING',
  },
  complexFilter: {
    name: '',
    filterSet: [],
  },
};

const getPaginationInfo = (oldPaginationInfo: PaginationInfo, newPaginationInfo: PaginationInfo): PaginationInfo => {

  if (newPaginationInfo.previous === null) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: 1,
    }
  }
  if (oldPaginationInfo.next === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page + 1,
    }
  }
  if (oldPaginationInfo.previous === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page - 1,
    }
  }
  return {
    ...oldPaginationInfo,
    ...newPaginationInfo,
    page: 1,
  }
};

const sourcePathReducer = (state = initialState, action: SourcePathReportListActionType): ISourcePathReportState => {
  const { type, payload } = action;
  switch (type) {
    case SET_SOURCE_PATH_REPORT_LIST_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload as ISourcePathReportFilter,
        },
        selectedAll: false,
      };
    case SET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: payload as ComplexFilterSet,
      };
    case RESET_SOURCE_PATH_REPORT_LIST_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: {
          name: '',
          filterSet: [],
        },
      };
    case SET_SOURCE_PATH_PAGINATION_DATA:
    return {
      ...state,
      paginationData: {
        ...state.paginationData,
        ...payload as PaginationInfo,
      },
    };
    case SET_SOURCE_PATH_REPORT_LIST_SORTING_CRITERIA:
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          cursor: null,
        },
        selectedIds: [],
        selectedAll: false,
        sortingCriteria: payload as SortingCriteria,
      };
    case SOURCE_PATH_REPORT_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SOURCE_PATH_REPORT_LIST_SUCCESS:
      const sortedData = SortingService.getSortedArray((payload as Partial<ISourcePathReportState>).data!, state.sortingCriteria);
      return {
        ...state,
        data: sortedData,
        paginationData: getPaginationInfo(state.paginationData, (payload as Partial<ISourcePathReportState>).paginationData!),
        status: SUCCESS,
      };
    case SOURCE_PATH_REPORT_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case SET_SOURCE_PATH_ITEM_COUNT:
      return {
        ...state,
        sourcePathCount: payload as number,
      };
    default:
      return state;
  }
};

export default sourcePathReducer;
