export const ASCENDING = 'ASCENDING';
export const DESCENDING = 'DESCENDING';
export type Order = typeof ASCENDING | typeof DESCENDING;

export const STRING = 'STRING';
export const NUMBER = 'NUMBER';
export const DATE = 'DATE';
export type SortingDataType = typeof STRING | typeof NUMBER | typeof DATE;

export type SortingCriteria = {
  key: string,
  order: Order,
  dataType: SortingDataType,
};

// Not all the object elements can be sorted, that is why any is added
export type Sortable = {
  [key: string]: string | number | undefined | any,
};

export interface IHasSortingCriteria {
  sortingCriteria: SortingCriteria,
}
