import SortingService from 'Services/SortingService/SortingService';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { PAGINATION_DEFAULT_ROW_NUMBER } from 'Constants/Options';
import {
  LOADING,
  SUCCESS,
  FAIL,
  EDIT_LOADING,
  EDIT_SUCCESS,
  EDIT_FAIL,
  DEFAULT,
  CREATE_LOADING,
  CREATE_FAIL,
  CREATE_SUCCESS,
} from 'Redux/types'
import { ISegmentContactState, ISegmentContactFilter, SegmentContact } from 'Modules/Marketing/Redux/Reducers/SegmentContactsReducer/types';
import {
  SEGMENT_LOADING,
  SEGMENT_SUCCESS,
  SEGMENT_FAIL,
  CREATE_SEGMENT_LOADING,
  CREATE_SEGMENT_SUCCESS,
  CREATE_SEGMENT_FAIL,
  UPDATE_SEGMENT_LOADING,
  UPDATE_SEGMENT_SUCCESS,
  UPDATE_SEGMENT_FAIL,
  SEGMENT_CONTACT_LIST_LOADING,
  SEGMENT_CONTACT_LIST_SUCCESS,
  SEGMENT_CONTACT_LIST_FAIL,
  SET_SEGMENT_CONTACT_FILTER,
  SET_SELECTED_SEGMENTS_CONTACTS,
  SET_SEGMENT_CONTACT_PAGINATION_DATA,
  SET_SEGMENT_CONTACT_SORTING_CRITERIA,
  SET_SEGMENT_CONTACT_COMPLEX_FILTER,
  RESET_SEGMENT_CONTACTS,
  SET_SEGMENT_NAME,
  BULK_SET_SEGMENT_CONTACTS,
  SegmentContactListAction,
} from 'Modules/Marketing/Redux/types';
import { Segment } from '../SegmentsReducer/types';

const initialState: ISegmentContactState = {
  status: DEFAULT,
  segmentName: '',
  data: [],
  paginationData: {
    previous: null,
    next: null,
    cursor: null,
    limit: PAGINATION_DEFAULT_ROW_NUMBER,
    page: 1,
  },
  sortingCriteria: {
    key: 'createdAt',
    order: 'ASCENDING',
    dataType: 'DATE',
  },
  complexFilter: {
    name: '',
    filterSet: [],
  },
  predefinedComplexFilter: undefined,
  selectedAll: false,
  selectedIds: [],
  filter: {
    searchText: {
      value: '',
      appliedOn: ['firstName', 'lastName', 'email'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    disabled: {
      value: null,
      appliedOn: ['disabled'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
  },
};

const getStateFromSegment = (segment: Segment, state: ISegmentContactState): ISegmentContactState => {
  return {
    ...state,
    segmentName: segment.displayName,
    complexFilter: {
      ...state.complexFilter,
      name: segment.predefinedFilter.title || "",
    },
    predefinedComplexFilter: segment.predefinedFilter,
  };
};

const getUpdatedSegmentContactList = (list: SegmentContact[], contactIds: string[], updateContactObject: Partial<SegmentContact>): SegmentContact[] => {
  return list.map(contact => {
    if (contactIds.includes(contact.id)) {
      return {
        ...contact,
        ...updateContactObject,
      };
    }
    return contact;
  });
};

const getPaginationInfo = (oldPaginationInfo: PaginationInfo, newPaginationInfo: PaginationInfo): PaginationInfo => {
  if (newPaginationInfo.previous === null) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: 1,
    }
  }
  if (oldPaginationInfo.next === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page + 1,
    }
  }
  if (oldPaginationInfo.previous === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page - 1,
    }
  }
  return {
    ...oldPaginationInfo,
    ...newPaginationInfo,
    page: 1,
  }
};

const SegmentContactsReducer = (state = initialState, action: SegmentContactListAction): ISegmentContactState => {
  const { type, payload } = action;

  switch (type) {
    case SEGMENT_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SEGMENT_SUCCESS:
      return {
        ...state,
        ...getStateFromSegment(payload.segment!, state),
      };
    case SEGMENT_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case CREATE_SEGMENT_LOADING:
      return {
        ...state,
        status: CREATE_LOADING,
      }
    case CREATE_SEGMENT_SUCCESS:
      return {
        ...state,
        ...getStateFromSegment(payload.segment!, state),
        status: CREATE_SUCCESS,
      }
    case CREATE_SEGMENT_FAIL:
      return {
        ...state,
        status: CREATE_FAIL,
      }
    case UPDATE_SEGMENT_LOADING:
      return {
        ...state,
        status: EDIT_LOADING,
      }
    case UPDATE_SEGMENT_SUCCESS:
      return {
        ...state,
        ...getStateFromSegment(payload.segment!, state),
        status: EDIT_SUCCESS,
      }
    case UPDATE_SEGMENT_FAIL:
      return {
        ...state,
        status: EDIT_FAIL,
      }
    case SEGMENT_CONTACT_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SEGMENT_CONTACT_LIST_SUCCESS:
      const { state: usedState } = payload;
      const sortedData = SortingService.getSortedArray((usedState as Partial<ISegmentContactState>).data!, state.sortingCriteria);
      return {
        ...state,
        status: SUCCESS,
        data: sortedData,
        paginationData: getPaginationInfo(state.paginationData, (usedState as Partial<ISegmentContactState>).paginationData!),
      };
    case SEGMENT_CONTACT_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    case SET_SEGMENT_CONTACT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload.filter as ISegmentContactFilter,
        },
        paginationData: {
          ...state.paginationData,
          cursor: null,
        },
        selectedIds: [],
        selectedAll: false,
      };
    case SET_SELECTED_SEGMENTS_CONTACTS:
      return {
        ...state,
        selectedAll: payload.actionObject!.selectedAll,
        selectedIds: payload.actionObject!.ids,
      };
    case SET_SEGMENT_CONTACT_PAGINATION_DATA:
      return {
        ...state,
        selectedAll: false,
        paginationData: {
          ...state.paginationData,
          ...payload.paginationInfo,
        },
      };
    case SET_SEGMENT_CONTACT_SORTING_CRITERIA:
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          cursor: null,
        },
        selectedIds: [],
        selectedAll: false,
        sortingCriteria: payload.sortingCriteria!,
      };
    case SET_SEGMENT_CONTACT_COMPLEX_FILTER:
      return {
        ...state,
        complexFilter: payload.complexFilter!,
      };
    case SET_SEGMENT_NAME:
      return {
        ...state,
        segmentName: payload.name!,
      };
    case BULK_SET_SEGMENT_CONTACTS:
      return {
        ...state,
        data: getUpdatedSegmentContactList(state.data, payload.contactIds!, payload.contact!),
      };
    case RESET_SEGMENT_CONTACTS:
      return initialState;
    default:
      return state;
  }
};

export default SegmentContactsReducer;
