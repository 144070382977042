import { FC } from 'react';

import { IIcon } from 'Components/Icons/types';

const PerformanceIcon: FC<IIcon> = ({
  customcolor,
}) => (
  <svg id="Performance_icon" data-name="Performance icon" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 150 150">
    <path id="Path_60" data-name="Path 60" d="M122.178,74.013,90.339,105.852,54.083,69.6,12.6,111.081" fill="none" stroke={customcolor ? customcolor : "#707070"} strokeLinejoin="round" strokeWidth="15"/>
    <rect id="Rectangle_44" data-name="Rectangle 44" width="150" height="150" fill="none"/>
    <path id="Path_61" data-name="Path 61" d="M108.505,66.53l28.9-7.74-7.741,28.9Z" fill={customcolor ? customcolor : "#707070"}/>
    <path id="Path_62" data-name="Path 62" d="M104.48,44.085A14.381,14.381,0,1,1,90.1,29.7,14.381,14.381,0,0,1,104.48,44.085" fill={customcolor ? customcolor : "#707070"}/>
  </svg>
);

export default PerformanceIcon;
