import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import * as translationConstants from 'Constants/TranslationConstants';

export const getTranslationKeyFromText = (text: string): string => {
  let keyToTranslate: string = "";
  switch (text) {
    case 'country':
      keyToTranslate = translationConstants.country;
      break;
    case 'firstName':
      keyToTranslate = translationConstants.first_name;
      break;
    case 'lastName':
      keyToTranslate = translationConstants.last_name;
      break;
    case 'acceptCommunication':
      keyToTranslate = translationConstants.accept_communication;
      break;
    case 'createdOn':
      keyToTranslate = translationConstants.created_on;
      break;
    case 'role':
      keyToTranslate = translationConstants.role;
      break;
    case 'userName':
      keyToTranslate = translationConstants.user_name;
      break;
    case 'language':
      keyToTranslate = translationConstants.language;
      break;
    case 'lastUpdated':
      keyToTranslate = translationConstants.last_updated;
      break;
    case 'birthDate':
      keyToTranslate = translationConstants.birth_date;
      break;
    case 'salutation':
      keyToTranslate = translationConstants.salutation;
      break;
    case 'group':  
      keyToTranslate = translationConstants.group;
      break;
    case 'title':  
      keyToTranslate = translationConstants.title;
      break;
    case 'externalId':  
      keyToTranslate = translationConstants.external_id;
      break;
    case 'languageCode':
      keyToTranslate = translationConstants.language_code;
      break;
    case 'levelOfConsent':
      keyToTranslate = translationConstants.level_of_consent;
      break;
    case 'subscriberType':
      keyToTranslate = translationConstants.subscriber_type;
      break;
    case 'subscriptionDate':
      keyToTranslate = translationConstants.subscription_date;
      break;
    case 'updatedSubscriptionDate':
      keyToTranslate = translationConstants.updated_subscription_date;
      break;
    case 'lastActivityAt':
      keyToTranslate = translationConstants.last_activity_at;
      break;
    case 'customerType':
      keyToTranslate = translationConstants.customer_type;
      break;
    case 'cxStatus':
      keyToTranslate = translationConstants.cx_status;
      break;
    case 'customerCategory':
      keyToTranslate = translationConstants.customer_category;
      break;
    case 'audienceCategory':
      keyToTranslate = translationConstants.audience_category;
      break;
    case 'dateAdded':
      keyToTranslate = translationConstants.date_added;
      break;
    case 'lastUpdateOnDataset':
      keyToTranslate = translationConstants.last_update_on_dataset;
      break;
    default:
      keyToTranslate = text;
      break;
  }
  return keyToTranslate;
}

const useTranslatedSourceDataKey = (key: string): string => {
  let keyToTranslate = getTranslationKeyFromText(key);
  return useLocalized(keyToTranslate);
};

export default useTranslatedSourceDataKey;
