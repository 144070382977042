import {
  createContext,
  useCallback,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';

import { ToastMessageProps } from 'Redux/Reducers/UIReducer/types';
import { ToastMessageContextType } from 'CustomHooks/useToastMessage/types';
import { TOAST_MESSAGE_TIMEOUT } from 'Constants/Options';
import UtilityService from 'Services/UtilityService/UtilityService';
import { RootReducerState } from 'Redux/Reducers/types';
import { Locale } from 'Services/LocalizationService/types';
import { UIStateType } from 'Redux/Reducers/UIReducer/types';
import LocalizationService from 'Services/LocalizationService/LocalizationService';
import { addToastMessage, removeToastMessage } from 'Redux/Actions';

export const ToastMessageContext = createContext<ToastMessageContextType>({
  messages: [],
  presentToastMessage: () => '',
  removeToastMessage: () => {},
  presentErrorToastByCode: () => '',
});

const useToastMessage = (): [ToastMessageProps[], (toastMessage: ToastMessageProps) => string, (id: string) => void, (code: number) => string] => {
  const dispatch = useDispatch();
  const { toastMessages } = useSelector<RootReducerState, UIStateType>(state => state.ui);

  const removeToastMessageAction = useCallback<(id: string) => void>((id) => {
    dispatch(removeToastMessage(id));
    
  }, [dispatch]);

  useEffect(() => {
    const newToastMessages = cloneDeep(toastMessages);
    const poppedToast = newToastMessages.shift();
    if (!poppedToast) {
      return;
    }

    // Error messages and messages explicitly set to be kept are not removed 
    if (poppedToast && (poppedToast.severity === 'error' || poppedToast.shouldKeepItOpen)) {
      return;
    }
    setTimeout(() => {
      dispatch(removeToastMessage(poppedToast.id!));
    }, TOAST_MESSAGE_TIMEOUT);
  }, [dispatch, toastMessages]);

  const locale = useSelector<RootReducerState, Locale>(state => state.user.locale);
  const getErrorMessageByCode = useCallback<(code: number) => string>((code) => {
    const errorText = `error_${code}`;
    const localizedText = LocalizationService.shared().getLocalizedText(locale, errorText);
    return localizedText;
  }, [locale]);

  const presentToastMessage = useCallback<(toastMessage: ToastMessageProps) => string>((toastMessage) => {
    const toastMessageId = UtilityService.getUUID();
    setTimeout(() => {
      dispatch(addToastMessage({
        ...toastMessage,
        id: toastMessageId,
      }));
    }, 0);
    return toastMessageId;
  }, [dispatch]);

  const presentErrorToastByCode = useCallback<(code: number) => string>((code) => {
    const id = presentToastMessage({
      severity: 'error',
      message: getErrorMessageByCode(code),
    });
    return id;
  }, [getErrorMessageByCode, presentToastMessage]);

  return [toastMessages, presentToastMessage, removeToastMessageAction, presentErrorToastByCode]
};

export default useToastMessage;
