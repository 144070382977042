import { combineReducers } from 'redux';

import ContactReducer from 'Modules/EmailManagement/Redux/Reducers/ContactReducer/ContactReducer';
import ContactDetailReducer from 'Modules/EmailManagement/Redux/Reducers/ContactDetailReducer/ContactDetailReducer';
import CountryReducer from 'Modules/EmailManagement/Redux/Reducers/CountryReducer/CountryReducer';
import BizmailListReducer from 'Modules/EmailManagement/Redux/Reducers/BizmailListReducer/BizmailListReducer';
import BizmailAccountsReducer from 'Modules/EmailManagement/Redux/Reducers/BizmailAccountsReducer/BizmailAccountsReducer';
import mailchimpAccountsReducer from './MailchimpAccountsReducer/MailchimpAccountsReducer';

const rootReducer = combineReducers({
  contacts: ContactReducer,
  contactDetail: ContactDetailReducer,
  countries: CountryReducer,
  bizmailLists: BizmailListReducer,
  bizmailAccounts: BizmailAccountsReducer,
  mailchimpData: mailchimpAccountsReducer,
});

export default rootReducer;
