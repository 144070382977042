import English from 'Constants/English.json';
import { en_EN } from 'Constants/LocaleConstants';
import { ILocalizationService, Locale, ITranslation } from 'Services/LocalizationService/types';

class LocalizationService implements ILocalizationService {
  static sharedInstance: ILocalizationService;
  static shared() {
    if (!this.sharedInstance) {
      this.sharedInstance = new LocalizationService();
    }
    return this.sharedInstance;
  }

  public getLocalizedText(locale: Locale, text: string): string {
    let currentTranslation = {};
    switch (locale) {
      case en_EN:
        currentTranslation = English;
        break;
      default:
        currentTranslation = English;
        break;
    }
    const translatedText = this.getTextFromTranslation(currentTranslation, text);
    if (!translatedText) {
      return text;
    }
    return translatedText;
  }

  private getTextFromTranslation(translation: ITranslation, key: string): string | undefined {
    return translation[key];
  }
}

export default LocalizationService;
