import { FC } from 'react';

import { IIcon } from 'Components/Icons/types';

const DigitalPersonasIcon: FC<IIcon> = ({
  customcolor,
}) => (
  <svg id="Digital_Personas_icon_1_" data-name="Digital Personas icon(1)" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 150 150">
    <path id="Path_69" data-name="Path 69" d="M91.33,34.083A16.331,16.331,0,1,1,75,17.753a16.331,16.331,0,0,1,16.33,16.33" transform="translate(0.332)" fill={customcolor ? customcolor : "#fff"}/>
    <path id="Path_70" data-name="Path 70" d="M75,126.79c17.832,0,18.9-57.248,18.9-57.248H56.1S57.168,126.79,75,126.79Z" transform="translate(0.4)" fill="none" stroke={customcolor ? customcolor : "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="12"/>
    <g id="Group_47" data-name="Group 47">
      <path id="Path_71" data-name="Path 71" d="M115.5,40.137a53.448,53.448,0,0,1,.368,69.3" fill="none" stroke={customcolor ? customcolor : "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" strokeDasharray="0 25"/>
      <path id="Path_71-2" data-name="Path 71" d="M128.438,40.137a53.448,53.448,0,0,0-.368,69.3" transform="translate(-93.508)" fill="none" stroke={customcolor ? customcolor : "#fff"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="12" strokeDasharray="0 25"/>
    </g>
    <rect id="Rectangle_47" data-name="Rectangle 47" width="150" height="150" fill="none"/>
  </svg>
);

export default DigitalPersonasIcon;
