import { ThunkAction } from 'redux-thunk';

import {
  AudienceReportListActionType,
  SET_AUDIENCE_REPORT_LIST_SORTING_CRITERIA,
  AUDIENCE_REPORT_LIST_LOADING,
  AUDIENCE_REPORT_LIST_SUCCESS,
  AUDIENCE_REPORT_LIST_FAIL,
  SET_AUDIENCE_REPORT_LIST_FILTER,
  SET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER,
  RESET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER
} from 'Modules/Reports/Redux/types';

import WebService  from 'Services/WebService/WebService';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import { AUDIENCE_REPORT } from 'Modules/Reports/Constants/WebservicePaths';

import { IAudienceReporttFilter, AudienceReport } from 'Modules/Reports/Redux/Reducers/AudiencesPerformanceReducer/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { IError } from 'Redux/Reducers/types';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, AudienceReportListActionType>;

export const resetAudienceReportComplexFilter = (): AudienceReportListActionType => ({
  type: RESET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER,
});

export const setAudienceReportFilter = (filter: Partial<IAudienceReporttFilter>): AudienceReportListActionType => ({
  type: SET_AUDIENCE_REPORT_LIST_FILTER,
  payload: filter,
});

export const setAudienceReportComplexFilter = (filter: ComplexFilterSet): AudienceReportListActionType => ({
  type: SET_AUDIENCE_REPORT_LIST_COMPLEX_FILTER,
  payload: filter,
});

export const setAudienceReportSortingCriteria = (sortingCriteria: SortingCriteria) => ({
  type: SET_AUDIENCE_REPORT_LIST_SORTING_CRITERIA,
  payload: sortingCriteria,
});

export const getAudienceReportList = (params: string, success: (audienceReport: AudienceReport[]) => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: AUDIENCE_REPORT_LIST_LOADING,
  });
  
  try {
    const option = new WebServiceOption('GET', `${AUDIENCE_REPORT}?${params}`);
    const response = await WebService.shared().callWebService(option) as AudienceReport[];
    dispatch({
      type: AUDIENCE_REPORT_LIST_SUCCESS,
      payload: {
        data: response,
      },
    });
    success(response);
  } catch (error) {
    dispatch({
      type: AUDIENCE_REPORT_LIST_FAIL,
    });
    fail(error as IError);
  };
};
