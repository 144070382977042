import { ISourceState, Source } from 'Redux/Reducers/SourceReducer/types';

import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  SOURCE_LIST_LOADING,
  SOURCE_LIST_SUCCESS,
  SOURCE_LIST_FAIL,
  SourceListAction,
} from 'Redux/types';

const initialState: ISourceState = {
  status: DEFAULT,
  data: [],
};

const sourceTypeReducer = (state = initialState, action: SourceListAction): ISourceState => {
  const { type, payload } = action;
  switch (type) {
    case SOURCE_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SOURCE_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as Source[],
      };
    case SOURCE_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default sourceTypeReducer;
