import { en_EN } from 'Constants/LocaleConstants'
import { UserState } from 'Redux/Reducers/UserReducer/types';
import {
  UserActionType,
  SET_USER,
  SET_PRO_USER,
  RESET_LOGOUT_URL,
  DEFAULT,
} from 'Redux/types';

const initialState: UserState = {
  status: DEFAULT,
  locale: en_EN,
  username: '',
  logoutUrl: '',
  proUser: false,
  credentials: {
    refreshToken: '',
    accessToken: '',
    isValidToken: undefined,
  },
};

const userReducer = (state = initialState, action: UserActionType): UserState => {
  const { type } = action;
  switch (type) {
    case SET_USER:
      const partialUser = action.payload as Partial<UserState>;
      return {
        ...state,
        ...partialUser,
        credentials: {
          ...state.credentials,
          ...partialUser.credentials,
        },
      };
    case SET_PRO_USER:
      const { proUser, username } = action.payload as Partial<UserState>;
      return {
        ...state,
        proUser: proUser !== undefined ? proUser : state.proUser,
        username: username !== undefined ? username : state.username,
      };
      
    case RESET_LOGOUT_URL:
      return {
        ...state,
        logoutUrl: '',
      };
    default:
      return state;
  }
}

export default userReducer;
