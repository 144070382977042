import { combineReducers } from 'redux';

import SegmentsReducer from 'Modules/Marketing/Redux/Reducers/SegmentsReducer/SegmentsReducer';
import SegmentContactsReducer from 'Modules/Marketing/Redux/Reducers/SegmentContactsReducer/SegmentContactsReducer';
import GoogleAccountListReducer from 'Modules/Marketing/Redux/Reducers/GoogleAccountListReducer/GoogleAccountListReducer';
import SegmentCountReducer from 'Modules/Marketing/Redux/Reducers/SegmentCountReducer/SegmentCountReducer';
import MailchimpAccountListReducer from './MailchimpAccountListReducer/MailchimpAccountListReducer';

const rootReducer = combineReducers({
  segments: SegmentsReducer,
  segmentContacts: SegmentContactsReducer,
  googleAccounts: GoogleAccountListReducer,
  segmentCount: SegmentCountReducer,
  mailchimpAccounts: MailchimpAccountListReducer,
});

export default rootReducer;
