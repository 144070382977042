import { Source } from 'Redux/Reducers/SourceReducer/types';
import { ISegmentSourceState } from 'Redux/Reducers/SegmentSourceReducer/types';

import {
  DEFAULT,
  LOADING,
  SUCCESS,
  FAIL,
  SEGMENT_SOURCE_LIST_LOADING,
  SEGMENT_SOURCE_LIST_SUCCESS,
  SEGMENT_SOURCE_LIST_FAIL,
  SegmentSourceListAction,
} from 'Redux/types';

const initialState: ISegmentSourceState = {
  status: DEFAULT,
  data: [],
};

const segmentSourceTypeReducer = (state = initialState, action: SegmentSourceListAction): ISegmentSourceState => {
  const { type, payload } = action;
  switch (type) {
    case SEGMENT_SOURCE_LIST_LOADING:
      return {
        ...state,
        status: LOADING,
      };
    case SEGMENT_SOURCE_LIST_SUCCESS:
      return {
        ...state,
        status: SUCCESS,
        data: payload as Source[],
      };
    case SEGMENT_SOURCE_LIST_FAIL:
      return {
        ...state,
        status: FAIL,
      };
    default:
      return state;
  }
};

export default segmentSourceTypeReducer;
