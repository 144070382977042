import { FC } from 'react';

import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import { loading_module } from 'Constants/TranslationConstants';

const SuspenseFallFallback: FC = () => (
  <>
    {useLocalized(loading_module)}
  </>
);

export default SuspenseFallFallback;
