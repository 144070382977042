import { cloneDeep, get } from 'lodash';

import DateService from 'Services/DateService/DateService';
import {
  SortingCriteria,
  STRING,
  NUMBER,
  DATE,
  DESCENDING,
} from 'CustomHooks/useSort/types';
import { ISortingService } from 'Services/SortingService/types';

class SortingService implements ISortingService {
  static getSortedArray(array: any[], sortingCriteria: SortingCriteria): any[] {
    const clonedArray = cloneDeep(array);
    const sortedArray = clonedArray.sort((a, b) => {
      const aValue = get(a, sortingCriteria.key);
      const bValue = get(b, sortingCriteria.key);
      
      if (aValue === undefined || bValue === undefined || aValue === null || bValue === null) {
        return 0;
      }
      let sortingValue = 0;
      switch (sortingCriteria.dataType) {
        case STRING:
          sortingValue = (aValue as string).localeCompare(bValue as string);
          break;
        case NUMBER:
          sortingValue = (aValue as number) - (bValue as number);
          break;
        case DATE:
          sortingValue = DateService.shared().isBefore(aValue as string, bValue as string) ? -1 : 1;
          break;
        default:
          break;
      }
      if (sortingCriteria.order === DESCENDING) {
        sortingValue = sortingValue * -1;
      }

      return sortingValue;
    });
    return sortedArray;
  }
}

export default SortingService;
