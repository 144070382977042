import { IComplexFilterReducerState, ComplexFilterOption } from 'Builders/types';
import {
  ComplexFilterListAction,
  COMPLEX_FILTER_LOADING,
  COMPLEX_FILTER_SUCCESS,
  COMPLEX_FILTER_FAIL,
  COMPLEX_FILTER_VALUE_OPTION_LOADING,
  COMPLEX_FILTER_VALUE_OPTION_SUCCESS,
  COMPLEX_FILTER_VALUE_OPTION_FAIL,
  ComplexFilterValueOptionsActionPayloadType,
} from 'Builders/types';

import {
  DEFAULT,
  LOADING,
  FAIL,
  SUCCESS,
} from 'Redux/types';

const initialState: IComplexFilterReducerState = {
  status: DEFAULT,
  data: [],
  valueOptions: {},
};

type ComplexFilterReducerType = (state: IComplexFilterReducerState, action: ComplexFilterListAction) => IComplexFilterReducerState;

const createComplexFilterOptionsReducer = (constructorBranch: string): ComplexFilterReducerType  => {
  return (state = initialState, action: ComplexFilterListAction): IComplexFilterReducerState => {
    const { type, payload, branch } = action;
    switch (`${branch}/${type}`) {
      case `${constructorBranch}/${COMPLEX_FILTER_LOADING}`: {
        return {
          ...state,
          status: LOADING,
        };
      }
      case `${constructorBranch}/${COMPLEX_FILTER_SUCCESS}`: {
        return {
          ...state,
          status: SUCCESS,
          data: payload as ComplexFilterOption[],
        };
      }
      case `${constructorBranch}/${COMPLEX_FILTER_FAIL}`: {
        return {
          ...state,
          status: FAIL,
        };
      }
      case `${constructorBranch}/${COMPLEX_FILTER_VALUE_OPTION_LOADING}`: {
        return {
          ...state,
          status: LOADING,
        };
      }
      case `${constructorBranch}/${COMPLEX_FILTER_VALUE_OPTION_SUCCESS}`: {
        const { key, data } = payload as ComplexFilterValueOptionsActionPayloadType;
        return {
          ...state,
          status: SUCCESS,
          valueOptions: {
            ...state.valueOptions,
            [key]: data,
          }
        };
      }
      case `${constructorBranch}/${COMPLEX_FILTER_VALUE_OPTION_FAIL}`: {
        return {
          ...state,
          status: FAIL,
        };
      }
      default:
        return state;
    };
  };
}

export default createComplexFilterOptionsReducer;
