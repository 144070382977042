import { DataElement, INACTIVE } from 'Modules/EmailManagement/Redux/Reducers/ContactReducer/types';
import { IContactDetailState } from 'Modules/EmailManagement/Redux/Reducers/ContactDetailReducer/types';
import { Contact } from 'Modules/EmailManagement/Redux/Reducers/ContactReducer/types';
import {
  ContactDetailAction,
  SET_CONTACT_DETAIL,
  RESET_CONTACT_DETAIL,
  REMOVE_TAG_FROM_CONTACT_DETAIL,
  SET_EDITED_MANUAL_DATA,
} from 'Modules/EmailManagement/Redux/types';
import TagService from 'Services/TagService/TagService';
import { manualInputData } from 'Services/SourceService/SourceService';

const initialState: IContactDetailState = {
  data: {
    id: '',
    email: '',
    status: INACTIVE,
    tags: [],
    data: [],
    mergedData: [{}],
    createdAt: (new Date()).toISOString(),
  },
  editedManualData: manualInputData,
};

const contactDetailReducer = (state = initialState, action: ContactDetailAction): IContactDetailState => {
  const { type, payload } = action;
  switch (type) {
    case SET_CONTACT_DETAIL:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload as Partial<Contact>,
        },
      };
    case RESET_CONTACT_DETAIL:
      return {
        ...initialState,
      };
    case SET_EDITED_MANUAL_DATA:
      return {
        ...state,
        editedManualData: {
          ...state.editedManualData,
          jsonPayload: {
            ...state.editedManualData.jsonPayload,
            ...payload as DataElement,
          },
        },
      };
    case REMOVE_TAG_FROM_CONTACT_DETAIL:
      return {
        ...state,
        data: {
          ...state.data,
          tags: (new TagService()).removeTag(payload as string, state.data.tags),
        },
      }
    default:
      return state;
  }
};

export default contactDetailReducer;

