export const SEGMENT_LIST = '/segment';

export const SEGMENT_LIST_PUBLISH_FACEBOOK = "/segment/synchronize_facebook_audience";
export const SEGMENT_LIST_PUBLISH_GOOGLE = "/segment/synchronize_google_ads_segment";
export const GOOGLE_ADS_ACCOUNT_LIST = "/google-ads";

export const MAILCHIMP_ACCOUNT_LIST = "/mailchimp-account";
export const SEGMENT_LIST_PUBLISH_MAILCHIMP = "/segment/synchronize_mailchimp_segment";

export const SEGMENT_CONTACT_LIST = '/segment/:id/contacts';
export const DOWNLOAD_SEGMENT_CONTACT_LIST = '/segment/:id/export';
export const SEGMENT_CONTACT_LIST_STATUS_BULK = '/segment/:id/contact_list/status_bulk';
export const SEGMENT_CONTACT_LIST_STATUS = '/segment/:id/contact_list/bulk';
