//Todo import paths when the backend responde to it.
import {
  SOURCE_PATH_REPORT_PREDEFINED_COMPLEX_FILTER,
  SOURCE_PATH_REPORT_PREDEFINED_COMPLEX_FILTERS,
  SOURCE_PATH_REPORT_COMPLEX_FILTER_OPTIONS,
} from 'Modules/Reports/Constants/WebservicePaths';

import createComplexFilterOptionActions from 'Builders/ComplexFilterActionsBuilder';

const {
  getComplexFilterOptions: getSourcePathComplexFilterOptions,
  getPredefinedComplexFilter: getSourcePathPredefinedComplexFilters,
  postPredefinedComplexFilter: postSourcePathPredefinedComplexFilter,
  patchPredefinedComplexFilter: patchSourcePathPredefinedComplexFilter,
} = createComplexFilterOptionActions (
  'sourcePath',
  SOURCE_PATH_REPORT_COMPLEX_FILTER_OPTIONS,
  SOURCE_PATH_REPORT_PREDEFINED_COMPLEX_FILTERS,
  SOURCE_PATH_REPORT_PREDEFINED_COMPLEX_FILTER,
);
  
export {
  getSourcePathComplexFilterOptions,
  getSourcePathPredefinedComplexFilters,
  postSourcePathPredefinedComplexFilter,
  patchSourcePathPredefinedComplexFilter,
};
