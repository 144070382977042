import { ThunkAction } from 'redux-thunk';

import WebService  from 'Services/WebService/WebService';
import WebServiceOption  from 'Services/WebService/WebServiceOption';
import {
  CampaignListActionType,
  CAMPAIGN_LIST_LOADING,
  CAMPAIGN_LIST_SUCCESS,
  CAMPAIGN_LIST_FAIL,
  SET_CAMPAIGN_LIST_FILTER,
  SET_CAMPAIGN_LIST_COMPLEX_FILTER,
  RESET_CAMPAIGN_LIST_COMPLEX_FILTER,
  SET_CAMPAIGN_LIST_SORTING_CRITERIA,
  SET_CAMPAIGN_LIST_PAGINATION_DATA,
  SET_CAMPAIGN,
  SET_CAMPAIGN_ITEM_COUNT,
} from 'Modules/Reports/Redux/types';
import { ICampaignFilter, ICampaign } from 'Modules/Reports/Redux/Reducers/CampaignsReducer/types';
import { ComplexFilterSet } from 'CustomHooks/useComplexFilter/types';
import { SortingCriteria } from 'CustomHooks/useSort/types';
import { RootReducerState } from 'Redux/Reducers/types';
import { IError } from 'Redux/Reducers/types';
import { CAMPAIGN_LIST, CAMPAIGN_LIST_BULK_KPI_UPDATE, CAMPAIGN_LIST_COUNT } from 'Modules/Reports/Constants/WebservicePaths';
import { CampaignPaginationInfo } from 'CustomHooks/usePagination/types';
import UtilityService from 'Services/UtilityService/UtilityService';
import CampaignService from 'Modules/Reports/Services/CampaignService';

type ThunkActionType = ThunkAction<void, RootReducerState, unknown, CampaignListActionType>;

type PredefinedComplexFilerItemCountResponseType = {
  count: number,
};

export type SetKPIsRequestType = {
  id: string,
  impressionsKpi?: number,
  videoViewsKpi?: number,
  clicksKpi?: number,
  promotionCostKpi?: number,
};

type GetCampaignListResponseType = {
  next: string | null,
  page: string,
  maxItems: string | undefined,
  previous: string | null,
  results: ICampaign[],
};


export const setCampaignItemCount = (count: number): CampaignListActionType => ({
  type: SET_CAMPAIGN_ITEM_COUNT,
  payload: count,
});

export const resetCampaignComplexFilter = (): CampaignListActionType => ({
  type: RESET_CAMPAIGN_LIST_COMPLEX_FILTER,
});

export const setCampaignFilter = (filter: Partial<ICampaignFilter>): CampaignListActionType => ({
  type: SET_CAMPAIGN_LIST_FILTER,
  payload: filter,
});

export const setCampaignComplexFilter = (filter: ComplexFilterSet): CampaignListActionType => ({
  type: SET_CAMPAIGN_LIST_COMPLEX_FILTER,
  payload: filter,
});

export const setCampaignSortingCriteria = (sortingCriteria: SortingCriteria) => ({
  type: SET_CAMPAIGN_LIST_SORTING_CRITERIA,
  payload: sortingCriteria,
});

export const setCampaignPaginationInfo = (paginationInfo: Partial<CampaignPaginationInfo>): CampaignListActionType => ({
  type: SET_CAMPAIGN_LIST_PAGINATION_DATA,
  payload: paginationInfo,
});

export const getCampaignList = (params: string, success: () => void, fail: (error: IError) => void):ThunkActionType => async dispatch => {
  dispatch({
    type: CAMPAIGN_LIST_LOADING,
  });

  try {
    const option = new WebServiceOption('GET', `${CAMPAIGN_LIST}?${params}`);
    const response = await WebService.shared().callWebService(option) as GetCampaignListResponseType;
    dispatch({
      type: CAMPAIGN_LIST_SUCCESS,
      payload: {
        paginationData: {
          next: UtilityService.getUrlParam(response.next, 'page'),
          previous: UtilityService.getUrlParam(response.previous, 'page'),
        },
        data: CampaignService.getCampaignsWithSummarizedKpis(response.results),
      },
    });
    success();
  } catch (error) {
    dispatch({
      type: CAMPAIGN_LIST_FAIL,
    });
    fail(error as IError);
  };
};

export const setCampaign = (campaign: ICampaign): CampaignListActionType => ({
  type: SET_CAMPAIGN,
  payload: campaign,
})

export const setCampaignKpis = async (campaignId: string, kpis: SetKPIsRequestType[], success: () => void, fail: (error: IError) => void) =>{
  try {
    const option = new WebServiceOption('PUT', CAMPAIGN_LIST_BULK_KPI_UPDATE.replace("::campaign-id::", campaignId), kpis);
    await WebService.shared().callWebService(option) as ICampaign;
    success();
  } catch (error) {
    fail(error as IError);
  };
};

export const getCampaignItemCount = async (params: string | null, success: (count: number) => void, faile: (error: IError) => void) => {
  try {
    let path = CAMPAIGN_LIST_COUNT;
    if (params) {
      path += `?${params}`;
    }
    const option = new WebServiceOption('GET', path);
    const response = await WebService.shared().callWebService(option) as PredefinedComplexFilerItemCountResponseType;
    success(response.count);
  } catch (error) {
    fail(error as IError);
  }
};
