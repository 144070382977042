import SortingService from 'Services/SortingService/SortingService';
import { PaginationInfo } from 'CustomHooks/usePagination/types';
import { PAGINATION_DEFAULT_ROW_NUMBER } from 'Constants/Options';
import {
  LOADING,
  SUCCESS,
  FAIL,
  DEFAULT,
  DELETE_LOADING,
  DELETE_SUCCESS,
  DELETE_FAIL,
  EDIT_LOADING,
  EDIT_SUCCESS,
  EDIT_FAIL,
} from 'Redux/types';

import {
  SEGMENT_LIST_LOADING,
  SEGMENT_LIST_SUCCESS,
  SEGMENT_LIST_FAIL,
  DELETE_SEGMENT_LOADING,
  DELETE_SEGMENT_SUCCESS,
  DELETE_SEGMENT_FAIL,
  PUBLISH_SEGMENT_LOADING,
  PUBLISH_SEGMENT_SUCCESS,
  PUBLISH_SEGMENT_FAIL,
  SET_SEGMENT_FILTER,
  SET_SELECTED_SEGMENTS,
  SET_SEGMENT_PAGINATION_DATA,
  SET_SEGMENT_SORTING_CRITERIA,
  SEGMENT_LIST_RESET,
  SegmentListAction
} from 'Modules/Marketing/Redux/types';
import {
  ISegmentState,
  ISegmentFilter,
  ISegmentCollectionState,
} from 'Modules/Marketing/Redux/Reducers/SegmentsReducer/types';

export const baseEmptyState: ISegmentState = {
  status: DEFAULT,
  data: [],
  selectedIds: [],
  selectedAll: false,
  paginationData: {
    previous: null,
    next: null,
    cursor: null,
    limit: PAGINATION_DEFAULT_ROW_NUMBER,
    page: 1,
  },
  sortingCriteria: {
    key: 'createdAt',
    order: 'ASCENDING',
    dataType: 'DATE',
  },
  filter: {
    searchText: {
      value: '',
      appliedOn: ['displayName'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    published: {
      value: null,
      appliedOn: ['published'],
      matchingType: 'INCLUDES',
      isUsingIdToFetch: false,
    },
    createdAtFrom: {
      valueType: "DATE",
      value: null,
      matchingType: "GREATER_THAN",
      isUsingIdToFetch: false,
      appliedOn: ['createdAtFrom'],
    },
    createdAtTo: {
      valueType: "DATE",
      value: null,
      matchingType: "SMALLER_THAN",
      isUsingIdToFetch: false,
      appliedOn: ['createdAtTo'],
    },
  },
};

const initialState: ISegmentCollectionState = {};

const getPaginationInfo = (oldPaginationInfo: PaginationInfo, newPaginationInfo: PaginationInfo): PaginationInfo => {
  if (newPaginationInfo.previous === null) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: 1,
    }
  }
  if (oldPaginationInfo.next === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page + 1,
    }
  }
  if (oldPaginationInfo.previous === oldPaginationInfo.cursor) {
    return {
      ...oldPaginationInfo,
      ...newPaginationInfo,
      page: oldPaginationInfo.page - 1,
    }
  }
  return {
    ...oldPaginationInfo,
    ...newPaginationInfo,
    page: 1,
  }
};

const segmentsReducer = (state = initialState, action: SegmentListAction): ISegmentCollectionState => {
  const { type, payload } = action;
  
  let key: string | undefined = undefined;

  if (payload) {
    key = payload.sourceId;
  }

  if (!key) {
    return state;
  }

  if (state[key] === undefined) {
    return {
      ...state,
      [key]: baseEmptyState,
    };
  }

  switch (type) {
    case SEGMENT_LIST_LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: LOADING,
        }
      };
    case SEGMENT_LIST_SUCCESS:
      const { state: usedState } = payload;
      const sortedData = SortingService.getSortedArray((usedState as Partial<ISegmentState>).data!, state[key].sortingCriteria);
      return {
        ...state,
        [key]: {
          ...state[key],
          status: SUCCESS,
          data: sortedData,
          paginationData: getPaginationInfo(state[key].paginationData, (usedState as Partial<ISegmentState>).paginationData!),
        },
      };
    case SEGMENT_LIST_RESET:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: DEFAULT,
          data: [],
        },
      };
    case SEGMENT_LIST_FAIL:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: FAIL,
        }
      };
    case DELETE_SEGMENT_LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: DELETE_LOADING,
        },
      };
    case DELETE_SEGMENT_SUCCESS:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: DELETE_SUCCESS,
          data: state[key].data.filter(({ id }) => id !== payload.segmentId!),
        },
      };
    case DELETE_SEGMENT_FAIL:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: DELETE_FAIL,
        },
      };
    case PUBLISH_SEGMENT_LOADING:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: EDIT_LOADING,
        },
      };
    case PUBLISH_SEGMENT_SUCCESS:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: EDIT_SUCCESS,
        },
      };
    case PUBLISH_SEGMENT_FAIL:
      return {
        ...state,
        [key]: {
          ...state[key],
          status: EDIT_FAIL,
        },
      };
    case SET_SEGMENT_FILTER:
      return {
        ...state,
        [key]: {
          ...state[key],
          filter: {
            ...state[key].filter,
            ...payload.filter as ISegmentFilter,
          },
          paginationData: {
            ...state[key].paginationData,
            cursor: null,
          },
          selectedIds: [],
          selectedAll: false,
        },
      };
    case SET_SELECTED_SEGMENTS:
      return {
        ...state,
        [key]: {
          ...state[key],
          selectedAll: payload.actionObject!.selectedAll,
          selectedIds: payload.actionObject!.ids,
        },
      };
    case SET_SEGMENT_PAGINATION_DATA:
      return {
        ...state,
        [key]: {
          ...state[key],
          selectedIds: [],
          selectedAll: false,
          paginationData: {
            ...state[key].paginationData,
            ...payload.paginationInfo,
          },
        },
      };
    case SET_SEGMENT_SORTING_CRITERIA:
      return {
        ...state,
        [key]: {
          ...state[key],
          paginationData: {
            ...state[key].paginationData,
            cursor: null,
          },
          selectedIds: [],
          selectedAll: false,
          sortingCriteria: payload.sortingCriteria!,
        },
      };
    default:
      return state;
  }
};

export default segmentsReducer;
