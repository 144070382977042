import { cloneDeep } from 'lodash';

import { Data, DataElement } from 'Modules/EmailManagement/Redux/Reducers/ContactReducer/types';
import { Source } from 'Redux/Reducers/SourceReducer/types';

export const MANUAL_INPUT_SOURCE = 'Manual input';

export const manualInputData: Data = {
  source: {
    id: '',
    name: MANUAL_INPUT_SOURCE,
    isEditable: true,
    color: '',
  },
  jsonPayload: {
    lastName: '',
    firstName: '',
    country: null,
    consent: false,
    customerType: null,
    audienceCategory: '',
    customerCategory: '',
    cxStatus: '',
  },
  updatedAt: (new Date()).toISOString(),
};

class SourceService {
  static getDatasWithManualInput(datas: Data[], sources: Source[], mergedData?: DataElement): Data[] {
    const manualInput = this.getManualData(datas);
    if (manualInput) {
      return datas;
    }
    const manualInputSourceFromRedux = sources.find(({ name }) => name === MANUAL_INPUT_SOURCE);
    manualInputData.source = manualInputSourceFromRedux!;

    // If there is no manual created, but there is merged data, the consent value is auto filled
    if (mergedData && mergedData.consent) {
      manualInputData.jsonPayload.consent = mergedData.consent;
    }
    return [manualInputData, ...datas];
  }

  static isEditableData(data: Data | undefined): boolean {
    if (!data) {
      return false;
    }
    return data.source.isEditable;
  }

  static getManualData(data: Data[]): Data | undefined {
    const manualDataType = data.find(({
      source: {
        name,
      },
    }) => name === MANUAL_INPUT_SOURCE);
    return manualDataType;
  }

  static getManualSourceId(sources: Source[]): string | undefined {
    return sources.find(({ name }) => name === MANUAL_INPUT_SOURCE)?.id;
  }

  static getStrippedData(data: Data): Data {
    const strippedData: Data = {
      ...cloneDeep(data),
      jsonPayload: {},
    };
    
    for (const key in data.jsonPayload) {
      if (Object.prototype.hasOwnProperty.call(data.jsonPayload, key)) {
        const value = data.jsonPayload[key];
        if (value !== "" && value !== null) {
          strippedData.jsonPayload[key] = value;
        }
      }
    }

    return strippedData;
  }
}

export default SourceService;
