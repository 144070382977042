import { SelectedContactDataSoruceAction, SET_GOOGLE_ADS, SET_GOOGLE_ANALYTICS, SET_META_ADS } from "../types";

const initialState = {
  selectedMetaAds: false,
  selectedGoogleAds: false,
  selectedGoogleAnalytics: false,
};

const selectedContactDataSoruceReducer = (state = initialState, action: SelectedContactDataSoruceAction) => {
  switch (action.type) {
    case SET_META_ADS:
      return { ...state, selectedMetaAds: action.payload };
    case SET_GOOGLE_ADS:
      return { ...state, selectedGoogleAds: action.payload };
    case SET_GOOGLE_ANALYTICS:
      return { ...state, selectedGoogleAnalytics: action.payload };
    default:
      return state;
  }
};

export default selectedContactDataSoruceReducer;
