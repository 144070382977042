import {
  FC,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  SelectChangeEvent,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material';

import { IGroupDialog } from 'Components/Dialogs/type';
import { DialogContext } from 'CustomHooks/useDialog/useDialog';
import useLocalized from 'CustomHooks/useLocalized/useLocalized';
import { finish, save_and_crate_gorup } from 'Constants/TranslationConstants';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'Redux/Reducers/types';
import { IChannelState } from 'Modules/Reports/Redux/Reducers/ChannelReducer/types';
import { SUCCESS } from 'Redux/types';
import WebService from 'Services/WebService/WebService';
import { GroupCampaignListItem } from 'Modules/Reports/ReportScreens/CampaignsScreen/Blocks/CampaignList/types';
import { ICampaignState } from 'Modules/Reports/Redux/Reducers/CampaignsReducer/types';
import { getCampaignList } from 'Modules/Reports/Redux/Actions';
import useGetDataWithFilterAndSortingCriteriaCampaigns from 'CustomHooks/useGetDataWithFilterAndSortingCriteriaCampaigns/useGetDataWithFilterAndSortingCriteriaCampaigns';

const GroupDialog: FC<IGroupDialog> = () => {
  const { closeDialogue } = useContext(DialogContext);

  const [open, setOpen] = useState(true);
  const [gorupName, setGorupName] = useState('');
  const { presentGroupDialog } = useContext(DialogContext);

  const [metaData, setMetaData] = useState<GroupCampaignListItem[]>();
  const [googleData, setGoogleData] = useState<GroupCampaignListItem[]>();
  const [checkedMetaItems, setCheckedMetaItems] = useState<GroupCampaignListItem[]>([]);
  const [checkedGoogleItems, setCheckedGoogleItems] = useState<GroupCampaignListItem[]>([]);
  const channelState = useSelector<RootReducerState, IChannelState>(state => state.reportManagement.channels);
  const campaignState = useSelector<RootReducerState, ICampaignState>(state => state.reportManagement.campaigns);
  useGetDataWithFilterAndSortingCriteriaCampaigns(campaignState, getCampaignList, campaignState.sortingCriteria);

  useEffect(() => {
    if (channelState.status === SUCCESS) {
      const metaChanelId = channelState.data.find(item => item.displayName === 'Meta');
      const googleAdsChanelId = channelState.data.find(item => item.displayName === 'Google Ads');
      if (metaChanelId) {
          WebService.shared().campaignsToGroup(metaChanelId.id).then(data => {
            setMetaData(data)
          });
      }
      if (googleAdsChanelId) {
        WebService.shared().campaignsToGroup(googleAdsChanelId.id).then(data => {
          setGoogleData(data)
        });
      }
    }
  },[channelState]);

  const handleMultipleMetaCheck = (event: SelectChangeEvent<string[]>) => {
    const selected = event.target.value as string[];
    const newCheckedItems = selected.map(campaignName => {
      return metaData && metaData.find(item => item.campaignName === campaignName);
    }).filter(item => item !== undefined) as GroupCampaignListItem[];
    setCheckedMetaItems(newCheckedItems);
  };

  const handleMultipleGoogleCheck = (event: SelectChangeEvent<string[]>) => {
    const selected = event.target.value as string[];
    const newCheckedItems = selected.map(campaignName => {
      return googleData && googleData.find(item => item.campaignName === campaignName);
    }).filter(item => item !== undefined) as GroupCampaignListItem[];
    setCheckedGoogleItems(newCheckedItems);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleCreateAndSaveGroup = (() => {
    const mergedList = checkedMetaItems.concat(checkedGoogleItems);
    const dataToSend = {
      groupName: gorupName,
      campaigns: mergedList
    }
    if (dataToSend.groupName && dataToSend.campaigns) {
      WebService.shared().groupCampaigns(dataToSend).then(data => {
        setMetaData(data)
      });
    }
    closeDialogue();
    presentGroupDialog({});
  });

  const handleGorupNameChange = useCallback((data) => {
    setGorupName(data)
  },[]);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        closeDialogue();
      }, 200);
    }
  }, [closeDialogue, open]);

  return (
    <Dialog 
      open={open} 
      className="dialog-content"
      PaperProps={{
        style: { minWidth: 400, minHeight: 340, top: '-10%',  transform: 'translateY(-10%)'},
      }} 
    >
      <DialogTitle className='dialog-content-title'>
        <span>Title</span>
      </DialogTitle>
      <DialogContent>
        <div className='dialog-content-data_fields'>
          <TextField
            key={'group-name'}
            label={'Group name'}
            variant="outlined"
            className="dialog-content-data_fields"
            size="small"
            onChange={(e) => handleGorupNameChange(e.target.value)}
            value={gorupName}
            disabled={false}
          />
        </div>
        <div className='dialog-content-data_fields'>
          <div className='dialog-content-data_fields-inputs'>
            <Box>
              <FormControl variant='standard' sx={{mt: 1, minWidth: 500, maxWidth: 500 }}>
                <InputLabel>
                  Meta
                </InputLabel>
                <Select
                  multiple
                  value={checkedMetaItems.map(item => item.campaignName)}
                  onChange={handleMultipleMetaCheck}
                  renderValue={(selected) => (
                    (selected as string[]).map(campaignName => {
                      const selectedItem = checkedMetaItems.find(item => item.campaignName === campaignName);
                      return selectedItem ? selectedItem.campaignName : '';
                    }).join(', ')
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300,
                        maxWidth: 500,
                        overflowX: 'auto',
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {metaData && metaData.map((item) => (
                    <MenuItem key={item.campaignName} value={item.campaignName}>
                      <Checkbox checked={checkedMetaItems.some(checkedItem => checkedItem.campaignName === item.campaignName)} />
                      <ListItemText primary={item.campaignName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className='dialog-content-data_fields-inputs'>
          <Box>
            <FormControl variant="standard" sx={{ mt: 1, minWidth: 500, maxWidth: 500 }}>
              <InputLabel>Google</InputLabel>
              <Select
                multiple
                value={checkedGoogleItems.map((item) => item.id)}
                onChange={handleMultipleGoogleCheck}
                renderValue={(selected) =>
                  (selected as string[]).map((campaignName) => {
                    const selectedItem = checkedGoogleItems.find((item) => item.campaignName === campaignName);
                    return selectedItem ? selectedItem.campaignName : '';
                  }).join(', ')
                }
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      maxWidth: 500,
                      overflowX: 'auto',
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                {googleData &&
                  googleData.map((item) => (
                    <MenuItem key={item.campaignName} value={item.campaignName}>
                      <Checkbox checked={checkedGoogleItems.some((checkedItem) => checkedItem.campaignName === item.campaignName)} />
                      <ListItemText primary={item.campaignName} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          </div>
        </div>
      </DialogContent>
      <DialogActions className='dialog-actions' >
          <Button
            key={"key"}
            onClick={handleCreateAndSaveGroup}
            variant="contained"
            color="primary"
            className="action-button"  // Applying margin with custom class
          >
            {useLocalized(save_and_crate_gorup)}
          </Button>
          <Button onClick={handleClose}>
            {useLocalized(finish)}
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupDialog;
