import {
  createContext,
  useCallback,
  useState,
} from 'react';
import { unionBy } from 'lodash';

import {
  DialogueProps,
  DialogueContext,
  AlertDialogProps,
  ALERT_DIALOGUE,
  HALF_PAGE_DIALOGUE,
  PopUpDialogProps,
  SyncDialogProps,
  POP_UP_DIALOGUE,
  SYNC_DIALOGUE,
  ACTIVATE_DIALOGUE,
  ActivateDialogProps,
  GroupDialogProps,
  GROUP_DIALOGUE,
} from 'CustomHooks/useDialog/types';
import UtilityService from 'Services/UtilityService/UtilityService';

const MAX_HALF_PAGE_DIALOGUES_PRESENTED = 4;

export const DialogContext = createContext<DialogueContext>({
  presentDialogue: (dialogue: DialogueProps) => ({ type: ALERT_DIALOGUE }),
  closeDialogue: () => {},
  dialogues: [],
  setPreventGoingAwayMessage: () => {},
  presentAlertDialog: (alertDialog: AlertDialogProps) => {},
  presentPopUpDialog: (popUpDialog: PopUpDialogProps) => {},
  presentSyncDialog: (syncDialog: SyncDialogProps) => {},
  presentActivateDialog: (activateDialog: ActivateDialogProps) => {},
  presentGroupDialog: (groupDialog: GroupDialogProps) => {},
});

const useDialog = (): [
  DialogueProps[],
  (dialogue: DialogueProps) => DialogueProps,
  () => void,
  (modalId: string, message: string | undefined) => void,
  (alertDialog: AlertDialogProps) => void,
  (popUpDialog: PopUpDialogProps) => void,
  (syncDialog: SyncDialogProps) => void,
  (presentDialog: ActivateDialogProps) => void,
  (groupDialog: GroupDialogProps) => void,
] => {
  const [dialogs, setDialogs] = useState<DialogueProps[]>([]);
  
  const setPreventGoingAwayMessage = useCallback((modalId: string, message: string | undefined) => {
    setDialogs(prevDialogs => {
      const dialog = prevDialogs.find(({ modalBodyId }) => modalBodyId === modalId);
      if (!dialog) {
        return prevDialogs;
      }
      const newDialogues = unionBy([{
        ...dialog,
        preventFromGoingAwayMessage: message,
      }], prevDialogs, 'modalBodyId').sort(({ index: a }, { index: b }) => {
        if (a && b) {
          return a - b;
        }
        return 0;
      });
      return newDialogues;
    });
  }, []);

  const getDialogueWidth = useCallback<(index: number, countOfHalfPageDialogues: number) => string | undefined>((index, countOfHalfPageDialogues) => {
    let width = (100 / MAX_HALF_PAGE_DIALOGUES_PRESENTED) + (countOfHalfPageDialogues - index) * 100 / (MAX_HALF_PAGE_DIALOGUES_PRESENTED * 2);
    width = width > 100 ? 100 : width;
    return `${width}%`;
  }, []);

  // Add a new dialog element to the array of presented dialogs
  const presentDialogue = useCallback((dialogue: DialogueProps): DialogueProps => {
    const dialogueId = UtilityService.getUUID();
    let newDialogues: DialogueProps[] = [];
    setDialogs(prevDialogs => {
      if (dialogue.type === ALERT_DIALOGUE) {
        return [...prevDialogs, {
          ...dialogue,
          index: prevDialogs.length,
          modalBodyId: dialogueId,
        }];
      }

      // Handles sliding in from left animations
      let countOfHalfPageDialogues = 1;
      prevDialogs.forEach(({ type }) => {
        if (type === HALF_PAGE_DIALOGUE) {
          countOfHalfPageDialogues += 1;
        }
      });
      let index = 0;
      newDialogues = [...prevDialogs, dialogue].map((currentDialog) => {
        if (currentDialog.type === ALERT_DIALOGUE) {
          return currentDialog;
        }
        index += 1;
        return {
          ...currentDialog,
          index: index,
          width: dialogue.width || getDialogueWidth(index, countOfHalfPageDialogues),
        };
      });
      newDialogues[newDialogues.length - 1].modalBodyId = dialogueId;
      return newDialogues;
    });

    return newDialogues[newDialogues.length - 1];
  }, [getDialogueWidth]);

  // Removes the last element from the presented dialogs
  const closeDialogue = useCallback(() => {
    setDialogs(prevDialogs => {
      let dialoguesCopy = [...prevDialogs];
      const poppedDialogue = dialoguesCopy.pop();
      if (poppedDialogue?.type === ALERT_DIALOGUE) {
        return dialoguesCopy;
      }

      // Handles sliding out to left animations
      let countOfHalfPageDialogues = 0;
      dialoguesCopy.forEach(({ type }) => {
        if (type === HALF_PAGE_DIALOGUE) {
          countOfHalfPageDialogues += 1;
        }
      });
      let index = 0;
      dialoguesCopy = dialoguesCopy.map((currentDialog) => {
        if (currentDialog.type === ALERT_DIALOGUE) {
          return currentDialog;
        }
        index += 1;
        return {
          ...currentDialog,
          width: getDialogueWidth(index, countOfHalfPageDialogues),
        };
      });
      return dialoguesCopy;
    });
  }, [getDialogueWidth]);

  const presentAlertDialog = useCallback((alertDialog: AlertDialogProps) => {
    const dialogueId = UtilityService.getUUID();
    setDialogs(prevDialogs => [
      ...prevDialogs, {
      index: prevDialogs.length,
      type: ALERT_DIALOGUE,
      modalBodyId: dialogueId,
      alertDialogProps: alertDialog,
    }]);
  }, []);

  const presentPopUpDialog = useCallback((popUpDialog: PopUpDialogProps) => {
    const dialogueIds = UtilityService.getUUID();
    setDialogs(prevDialogs => [
      ...prevDialogs, {
      index: prevDialogs.length,
      type: POP_UP_DIALOGUE,
      modalBodyId: dialogueIds,
      popUpDialogProps: popUpDialog,
    }]);
  }, []);

  const presentSyncDialog = useCallback((syncDialog: SyncDialogProps) => {
    const dialogueIds = UtilityService.getUUID();
    setDialogs(prevDialogs => [
      ...prevDialogs, {
      index: prevDialogs.length,
      type: SYNC_DIALOGUE,
      modalBodyId: dialogueIds,
      syncDialogProps: syncDialog,
    }]);
  }, []);

  const presentActivateDialog = useCallback((activateDialog: ActivateDialogProps) => {
    const dialogueIds = UtilityService.getUUID();
    setDialogs(prevDialogs => [
      ...prevDialogs, {
      index: prevDialogs.length,
      type: ACTIVATE_DIALOGUE,
      modalBodyId: dialogueIds,
      activateDialogProps: activateDialog,
    }]);
  }, []);

  const presentGroupDialog = useCallback((groupDialog: GroupDialogProps) => {
    const dialogueIds = UtilityService.getUUID();
    setDialogs(prevDialogs => [
      ...prevDialogs, {
      index: prevDialogs.length,
      type: GROUP_DIALOGUE,
      modalBodyId: dialogueIds,
      groupDialogProps: groupDialog,
    }]);
  },[]);


  return [dialogs, presentDialogue, closeDialogue, setPreventGoingAwayMessage, presentAlertDialog, presentPopUpDialog, presentSyncDialog, presentActivateDialog, presentGroupDialog];
};

export default useDialog;
